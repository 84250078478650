import React from "react";
import {
  Dialog,
  TextField,
  Grid,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import "../dialog box/styles.css";
export default class NameAndSavePlaylist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name:"",
      playlist:[],
      
    };
  }

  render() {
    //  create playlist screen name and save playlist buttton
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.props.close(false);
          this.setState({
            name: "",
           
          });
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container >
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
               this.props.close(false);
              this.setState({
              name: "",
          });
        }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="loginHeaderContainer">
            <span className="logintext">Name & Save Playlist</span>
          </Grid>
          <Grid item xs={12} className="passwordContainer">
            <TextField
              label="PLAYLIST NAME"
              value={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
              variant='standard'
              className="passwordTextField"
            />
          </Grid>
          <Grid container className="button-cont">
            <Grid item xs={8} className="ok-container">
              <Button 
              variant="outlined" 
              className={this.state.name !== '' ?"ok-button":"disable-button"}
              disabled={this.state.name !== '' ?false:true}
              onClick={async()=>{
                if(this.props.appbarprop===undefined){
                  await this.props.sidenavprop.create_playlist(this.state.name,this.props.sidenavprop.createPlay.showMoodTracks[0].data,this.props.sidenavprop.login.token)
                  this.setState({
                    name:''
                  })
                  this.props.close(false);
                }
                else{
                  await this.props.appbarprop.create_playlist(this.state.name,this.props.appbarprop.createPlay.showMoodTracks[0].data,this.props.appbarprop.login.token)
                  this.setState({
                    name:''
                  })
                  this.props.close(false);
                }
                
              }}
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={4} className="cancel-container">
              <Button
                className="cancel-button"
                onClick={() => {
                  this.props.close(false);
              this.setState({
              name: "",
          });
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
