import {
  SET_LOGIN,
  SET_LOGOUT,
  LOGOUT_FROM_DIFFERENT_DEVICE,
  TIMEOUT,
  SET_RESET_PASSWORD_VALID
} from "../../constants/login/loginActionTypes";
import { SET_LOGIN_SECURITY } from "../../constants/security/securityConst"

const initial_state = {
  isLoggedIn: false,
  email: "",
  isHome: true,
  token: null,
  isLoggedHome: false,
  logoutRedirect: false,
  newDeviceDetected: false,
  type: "",
  timeout:0,
  screenSaver: { "text": "", "image": "", "time":0 },
  resetPasswordValid: null
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_LOGIN:
      return state = {
        ...state,
        isLoggedIn: true,
        email: action.payload.email,
        token: action.payload.token
      }
    case SET_LOGIN_SECURITY:
      return state = {
        ...state,
        isLoggedHome: true,
      }
    case SET_LOGOUT:
      return state = {
        ...state,
        isLoggedIn: false,
        email: "",
        token: "",
        logoutRedirect: true
      }
    case TIMEOUT:
      return state = {
        ...state,
        timeout: (action.payload.timeout * 60 * 60 * 1000) - 900000,
        screenSaver: { "text": action.payload.screensaver.text, "image": action.payload.screensaver.image, "time": action.payload.screensaver.time * 60 * 1000 }
      }
    case LOGOUT_FROM_DIFFERENT_DEVICE:
      return state = { ...state, newDeviceDetected: true, isLoggedIn: false, isHome: true, type: '' };
    case SET_RESET_PASSWORD_VALID:
      return state = { ...state, resetPasswordValid: action.payload };
    default:
      return state;
  }

}