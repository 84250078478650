import React, { Component } from "react";
import {
  Redirect
} from "react-router-dom";

export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {

    var path = this.props.location.pathname.split("/");

    if (localStorage.getItem("juiced-token")) {
      this.props.set_Login(
        localStorage.getItem("email"),
        localStorage.getItem("juiced-token"),
      );
    } else if (path[1] !== "reset-password") {
      this.setState({
        redirect: true
      }, () => {
        this.setState({
          redirect: false
        })
      })
    }

  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to='/' />
      )
    }
    return (
      <div>

      </div>
    );
  }
}
