import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import "../../styles/login.css";
import LoginDialog from "./components/loginDialog";
import RegisterDialog from "./components/registerDialog";
import ForgotPasswordDialog from "./components/forgotPasswordDialog";
import logo from "../../styles/images/login/img_logo.png";
import beingGood from "../../styles/images/login/beingGoodLog.png";
import Juicedlogo from "../../styles/images/login/img_logo1.png";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginDialog: false,
      registetDialog: false,
      passwordDialog: false,
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    localStorage.removeItem("userToken")
    localStorage.removeItem("email")
  }

  //login Dialog
  
  openLoginDialog = () => {
    this.setState({ loginDialog: true });
  }

  closeLoginDialog = (value) => {
    this.setState({
      loginDialog: value,
    });
  }
  islogin = () => {
    this.props.isLoggedIn();
  }
  openRegisterDialogLink = (value) => {
    this.setState({
      registetDialog: value,
      loginDialog: false,
    });
  }
  openForgotPasswordDialogLink = (value) => {
    this.setState({
      passwordDialog: value,
      loginDialog: false,
    });
  }

  //Register Dialog

  closeRegisterDialog = (value) => {
    this.setState({
      registetDialog: value,
    });
  }

  // Reset Dialog

  resetDialog = (value) => {
    this.setState({
      passwordDialog: value,
    });
  }
  render() {
    // login page screen 
    return (
      <Grid container justify="center" className="login-background">
        <Grid item xs={12} style={{ paddingLeft: 7 }}>
          {localStorage.getItem("co_branding_url") === null || localStorage.getItem("co_branding_url") === "" || localStorage.getItem("co_branding_url") === undefined  ?
            <a rel="noopener noreferrer" style={{ display: "table-cell" }} href="https://www.beinggoodmusic.com/" target="_blank">
              <img alt="logo" data-test="logo" style={{ height: 120 }} src={beingGood} />
            </a>
            :
            <a rel="noopener noreferrer" href={localStorage.getItem("co_branding_url")} target="_blank">
              <img alt="logo" data-test="logo" style={{ height: 100 }} src={localStorage.getItem("co_branding_image")} />
            </a>
          }

        </Grid>
        <Grid style={{ position: 'relative', top: -100, textAlign: "center" }} item xs={12} md={9}>
          <img alt="logo" style={{ width: '73%' }} src={Juicedlogo} />
        </Grid>
        <Grid item xs={12} className="login-btn-container">
          <Button
            className="login-button"
            onClick={() => { this.openLoginDialog() }}
          >
            LOGIN
          </Button>
        </Grid>
        {/* <Grid item xs={12}> */}
        {localStorage.getItem("co_branding_url") === null || localStorage.getItem("co_branding_url") === "" || localStorage.getItem("co_branding_url") === undefined  ?<></>:
          <img
          alt="logo"
            style={{
              width: 100,
              height: 100,
              paddingRight: 8,
              position: "absolute",
              right: 0,
              bottom: 0
            }}
            data-test="logo" src={logo}
          />
        }
        {/* </Grid> */}
        {/* login dialog componenet */}
        {this.state.loginDialog === true && (
          <LoginDialog
            open={this.state.loginDialog}
            close={(value) => { this.closeLoginDialog(value) }}
            prop={this.props}
            logIn={() => {
              this.islogin()
            }}
            register={(value) => {
              this.openRegisterDialogLink(value)
            }}
            forgotPassword={(value) => {
              this.openForgotPasswordDialogLink(value)
            }}
          />
        )}
        {/* // register dialog component */}
        <RegisterDialog
          open={this.state.registetDialog}
          prop={this.props}
          close={(value) => {
            this.closeRegisterDialog(value)
          }}
        />
        {/* forgot password component */}
        <ForgotPasswordDialog
          open={this.state.passwordDialog}
          prop={this.props}
          close={(value) => {
            this.resetDialog(value)
          }}
        />

      </Grid>
    );
  }
}

export default Login;
