import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers"; // Gets the State from the reducer(s)
import {persistStore} from 'redux-persist';
import thunk from "redux-thunk";

export const middlewares = [thunk];

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

export const store = createStoreWithMiddleware(reducer)

export const persistor = persistStore(store);