import {SET_ADS_INDEX,SHOW_ADS, SET_MUSIC_INDEX,SET_REDIRECT,SET_LAST_PLAY_ID,SET_PLAY_PAGE,SET_PLAY_PROGRESS_SECONDS,SEEK_TO, SET_SHUFFLE_INCREMENTER,SET_ONE_TIME_PLAY,SET_PLAY_BUTTON,SET_PLAY_DURATION,SET_PLAY_PROGRESS,SET_VOLUME,SET_PLAY_PREV_NEXT_TRACK,SET_REPEAT,SET_SHUFFLE,SET_SHUFFLE_LIST, SET_CURRENT_TRACK_INDEX,SET_SCREEN_SAVER_ON,SET_ADS} from "../../constants/react player/reactPlayerConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { onLogoutFromDifferentDevice } from "../login/loginActions"
import UNIVERSAL from "../../config/config";
import { SET_EDIT_UPDATE } from "../../constants/playlist/playlistConst";
export function set_play_button(payload){
  return{
    type: SET_PLAY_BUTTON,
    payload: payload
  }
}

export function set_ads_index(payload){
  return{
    type: SET_ADS_INDEX,
    payload: payload
  }
}

export function set_edit_update_view(payload){
  return{
    type: SET_EDIT_UPDATE,
    payload: payload
  }
}

export function show_ads(payload){
  return{
    type: SHOW_ADS,
    payload: payload
  }
}
export function set_music_index(payload){
  return{
    type: SET_MUSIC_INDEX,
    payload: payload
  }
}

export function set_play_progress(payload){
  return{
    type: SET_PLAY_PROGRESS,
    payload: payload
  }
}

export function set_screensaver_on(payload){
  return{
    type: SET_SCREEN_SAVER_ON,
    payload: payload
  }
}

export function set_play_page(current,last){
  return{
    type: SET_PLAY_PAGE,
    payload: {current,last}
  }
}

export function set_last_play_id(last){
  return{
    type: SET_LAST_PLAY_ID,
    payload:last
  }
}

export function set_redirect(last){
  return{
    type: SET_REDIRECT,
    payload:last
  }
}

export function seek_to(payload){
  return{
    type: SEEK_TO,
    payload: payload
  }
}

export function set_current_track_index(payload){
  return{
    type: SET_CURRENT_TRACK_INDEX,
    payload: payload
  }
}

export function set_one_time_play(payload){
  return{
    type: SET_ONE_TIME_PLAY,
    payload: payload
  }
}

export function set_shuffle(payload){
  return{
    type: SET_SHUFFLE,
    payload: payload
  }
}

export function set_shuffle_list(payload){
  return{
    type: SET_SHUFFLE_LIST,
    payload: payload
  }
}

export function set_shuffle_incrementer(payload){
  return{
    type: SET_SHUFFLE_INCREMENTER,
    payload: payload
  }
}

export function set_repeat(payload){
  return{
    type: SET_REPEAT,
    payload: payload
  }
}

export function set_play_prev_next_track(payload){
  return{
    type: SET_PLAY_PREV_NEXT_TRACK,
    payload: payload
  }
}

export function set_volume(payload){
  return{
    type: SET_VOLUME,
    payload: payload
  }
}

export function set_play_duration(payload){
  return{
    type: SET_PLAY_DURATION,
    payload: payload
  }
}

export function set_play_seconds(payload){
  return{
    type: SET_PLAY_PROGRESS_SECONDS,
    payload: payload
  }
}

// export function show_mood(moodsDetail,moodTracks){
//   return{
//     type: SHOW_MOOD,
//     payload: {moodsDetail,moodTracks}
//   }
// }

export function set_ads(ads,ads_track_limit){
  return{
    type: SET_ADS,
    payload: {ads:ads,ads_track_limit:ads_track_limit}
  }
}

export function get_ads(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_mp_ads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify(),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_ads(responseJson.result.ads,responseJson.result.ad_track_limit))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function get_current_music_played_count(token,track_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/track_played", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        track_id:track_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}