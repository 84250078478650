import React, { Component } from "react";
import {
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  Icon,
  Hidden,
  IconButton,
} from "@material-ui/core";
import "./style.css";
import beingGood from "../../../styles/images/login/beingGoodLog.png";
class ViewPlayPlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      lastplay: '',
      currentPlay: ''
    };
  }

  componentDidMount() {
    this.props.set_position(2);
    this.props.get_playlist(this.props.login.token);
    if (localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.tracks.length > 0 && this.props.createPlay.showMoodTracks.length === 0) {
      this.props.set_track([])
      this.props.set_play_prev_next_track(0)
    }
    if (this.props.reactPlayer.redirect) {
      this.props.set_redirect(false)
      try {
        var elmnt = document.getElementById(this.props.reactPlayer.lastPlayedId);
        elmnt.scrollIntoView();
      } catch (e) {

      }
    }
  }
  addShuffle = async (index) => {
    var newShuffleArray = [], shuffleLen = this.props.viewPlay.tracks.length - 1, maxShuffleNum = this.props.viewPlay.tracks.length - 1, shuffle;
    while (newShuffleArray.length <= shuffleLen) {
      shuffle = Math.round(Math.random() * maxShuffleNum);
      if (newShuffleArray.indexOf(shuffle) === -1) {
        newShuffleArray.push(shuffle);
      }
    }
    await this.props.set_shuffle_list(newShuffleArray)
    this.props.set_play_prev_next_track(this.props.reactPlayer.shuffleList.indexOf(index))
    this.props.seek_to(true);
  }
  playPauseButton = async (index) => {
    if (localStorage.getItem('playlist') === 'viewplay') {
      if (this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0) {
        if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] !== index) {
          if (this.props.reactPlayer.shuffleList.length !== this.props.viewPlay.tracks.length) {
            this.addShuffle(index)
          } else {
            this.props.set_play_prev_next_track(this.props.reactPlayer.shuffleList.indexOf(index))
            this.props.seek_to(true);
          }
          if (this.props.reactPlayer.playButton === false) {
            this.props.set_play_button(true)
          }
        } else if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === index && this.props.reactPlayer.playButton === true) {
          this.props.set_play_button(false)
        } else if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === index && this.props.reactPlayer.playButton !== true) {
          this.props.set_play_button(true)
        }
      } else {
        if (this.props.reactPlayer.playIndex !== index) {
          if (index < this.props.reactPlayer.tracks.length) {
            this.props.set_play_prev_next_track(index)
            this.props.set_play_progress(0);
            this.props.seek_to(true);
          } else {
            this.props.set_filter_playlist_tracks(this.props.viewPlay.tracks)
            this.props.set_play_prev_next_track(index)
            this.props.set_play_progress(0);
            this.props.seek_to(true);
          }
          if (this.props.reactPlayer.playButton === false) {
            this.props.set_play_button(true)
          }
        } else if (this.props.reactPlayer.playIndex === index && this.props.reactPlayer.playButton === true) {
          this.props.set_play_button(false)
        } else if (this.props.reactPlayer.playIndex === index && this.props.reactPlayer.playButton !== true) {
          this.props.set_play_button(true)
        }
      }
    } else {
      localStorage.setItem("playlist", 'viewplay')
      if (this.props.reactPlayer.shuffle === true) {
        this.props.set_filter_playlist_tracks(this.props.viewPlay.tracks)
        this.props.set_play_prev_next_track(index)
        this.addShuffle(index)
        this.props.set_play_button(true)
      } else {
        this.props.set_filter_playlist_tracks(this.props.viewPlay.tracks)
        this.props.set_play_prev_next_track(index)
        this.props.seek_to(true);
        this.props.set_play_button(true)
      }
    }
    if (this.state.currentPlay !== index && this.props.reactPlayer.show_ads === false) {
      this.props.set_music_index(this.props.reactPlayer.musicIndex + 1)
      this.setState({
        currentPlay: index
      })
    }
    if (this.props.reactPlayer.musicIndex % this.props.reactPlayer.ads_track_limit === 0) {
      this.props.show_ads(true)

    }
  }
  render() {
    return (
      <>
        {/* view play screen  */}
        <Grid container className={this.props.screenSaver.screenSaverOn ? "blur-efffect" : ""}>
          <Grid item xs={12} lg={12}>
            <Card className="create-play-container">
              <Grid container className="mood-container" >
                <Hidden only={["xs", "sm"]}>
                  <Grid item className="mood-text-container" xs={12} md={6}>
                    {this.props.viewPlay.tracks.length > 0 && 'MOOD(S)'}
                  </Grid>

                  <Grid item xs={12} md={6} className="mood-text-container">
                    {this.props.viewPlay.tracks.length > 0 && 'MOOD(S)'}
                  </Grid>
                </Hidden>
                <Hidden only={["xl", "lg", "md"]}>
                  <Grid item xs={12} className="mood-text-mob-container">
                    {this.props.viewPlay.tracks.length > 0 && 'MOOD(S)'}
                  </Grid>
                </Hidden>
              </Grid>
              <Grid container item xs={12}>
                {this.props.viewPlay.tracks.length > 0 &&
                  this.props.viewPlay.tracks.map((data, index) => {
                    if (this.state.lastplay !== index && this.props.reactPlayer.playIndex === index && this.props.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'viewplay') {
                      this.setState({
                        lastplay: index
                      }, function () {
                        this.props.set_play_page('/view_play', '/view_play')
                        this.props.set_last_play_id(data.track._id + data.playlist_id[0])
                      })
                    }
                    return (
                      <Grid key={index} item xs={12} md={6}>
                        <List style={{
                          paddingBottom: 0, backgroundColor: localStorage.getItem('playlist') === 'viewplay' ?
                            this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === index && this.props.reactPlayer.playButton === true ?
                              "#f2f2f2" : "#ffffff"
                              :
                              this.props.reactPlayer.playIndex === index && this.props.reactPlayer.playButton === true ?
                                "#f2f2f2" : "#ffffff"
                            :
                            "#ffffff"
                        }}>
                          <ListItem style={{ padding: 0, paddingLeft: 8 }}>
                            <ListItemText
                              primary={
                                <Grid container>
                                  <Grid
                                    className="play-pause-icon-cont"
                                    item
                                    xs={3}
                                  >
                                    <b
                                      style={{
                                        position: "relative",
                                        top: -20
                                      }}
                                      id={data.track._id + data.playlist_id[0]}>
                                      {index + 1}
                                    </b>

                                    <IconButton
                                      style={{
                                        position: "relative",
                                        top: -20
                                      }}
                                      disableTouchRipple
                                      onClick={() => {
                                        this.playPauseButton(index)
                                      }}
                                    >
                                      {localStorage.getItem('playlist') === 'viewplay' ?
                                        this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === index && this.props.reactPlayer.playButton === true ?
                                          <Icon style={{ color: "#f6842d" }}>
                                            pause
                                          </Icon>
                                          :
                                          <Icon style={{ color: 'black' }}>
                                            play_arrow
                                          </Icon>
                                          :
                                          this.props.reactPlayer.playIndex === index && this.props.reactPlayer.playButton === true ?
                                            <Icon style={{ color: "#f6842d" }}>
                                              pause
                                            </Icon>
                                            :
                                            <Icon style={{ color: 'black' }}>
                                              play_arrow
                                            </Icon>
                                        :
                                        <Icon style={{ color: 'black' }}>
                                          play_arrow
                                        </Icon>
                                      }
                                    </IconButton>
                                    <img
                                      alt="album"
                                      width={55}
                                      height={55}
                                      src={data.track.album_art_image === null ? beingGood : data.track.album_art_image}
                                    />
                                  </Grid>

                                  <Grid item xs={5} md={5}>
                                    <ListItemText
                                      primary={<b>{data.track.title.toUpperCase()}</b>}
                                    />
                                    <ListItemText primary={data.track.artist.toUpperCase()} />
                                  </Grid>
                                  <Grid item xs={4} md={4} style={{ textAlign: "center" }}>
                                    <span
                                      style={{
                                        position: "relative",
                                        top: 20
                                      }}
                                    >{data.rtp.Mood.toUpperCase()}</span>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    );
                  })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ViewPlayPlayList;
