import {
  LOGIN,
  LOGOUT,
  HANDLEDRAWER,
  SET_HOME,
  LOGGEDIN,
  SET_LOGIN,
  SET_LOGOUT,
  LOGOUT_FROM_DIFFERENT_DEVICE,
  TIMEOUT,
  SET_RESET_PASSWORD_VALID
} from "../../constants/login/loginActionTypes";
import { RESET_PLAYER} from "../../constants/playlist/playlistConst";
import UNIVERSAL from "../../config/config.js";
import { set_loader, unset_loader } from "../loader/loader_action";
import {get_ads} from "../react player/reactPlayerAction";
import { set_snack_bar } from '../snackbar/snackbar_action'
export function setLogOut() {
  localStorage.removeItem("juiced-token")
  localStorage.removeItem("email")
  localStorage.removeItem('playlist')
  return {
    type: SET_LOGOUT
  }
}

export function resetPlayer() {
  return {
    type: RESET_PLAYER
  }
}
export function getLogOut(user_token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": user_token,
      },
      body: JSON.stringify({}),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message))
        if (responseJson.status) {
          dispatch(setLogOut())
          dispatch(resetPlayer())
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_home() {
  return {
    type: SET_HOME
  }
}

export function onLogoutFromDifferentDevice(){
  return {
    type: LOGOUT_FROM_DIFFERENT_DEVICE
  };
}

export function setLogin(result, email, user_token, profile_img, name, roles) {
  return {
    type: LOGIN,
    payload: {
      name: name,
      type: result.user_type,
      email: email,
      token: user_token,
      profile_img: profile_img,
      wishlist: result.wishlist_count,
      completed: result.domains_completed,
      roles: roles,
      lastLoggedIn: result.last_logged_on
    }
  };
}

export function handleDrawerToggle(mobileOpen) {
  mobileOpen = !mobileOpen;

  return {
    type: HANDLEDRAWER,
    payload: mobileOpen
  };
}

export function isLoggedIn() {
  return {
    type: LOGGEDIN,
    payload: true
  };
}

export function onLogout(user_token, uuid) {
  return () => {

    return fetch(UNIVERSAL.BASEURL + "v1/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": user_token,
      },
      body: JSON.stringify({
        device_uuid: uuid,
      }),
    }).then((response) => response.json())
      .then(() => {
        localStorage.clear();
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function logout_redirect() {
  return {
    type: LOGOUT
  };
}

export function invalidEmail() {
  return (dispatch) => {
    dispatch(set_snack_bar(true, "Invalid Email Format"))
  }
}

export function set_reset_password_valid(valid) {
  return {
    type: SET_RESET_PASSWORD_VALID,
    payload: valid
  };
}

export function timeout(screensave,timeout) {
  return {
    type:TIMEOUT,
    payload: {screensaver:screensave,timeout:timeout}
  };
}

export function changePassword(password, resetcode){
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/change_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resetcode: resetcode,
        password: password
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message))
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function verifyChangePassword(resetcode){
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/verify_change_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resetcode: resetcode
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_reset_password_valid(responseJson.status));
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function logIn(email, password) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/signin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password

      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message))
        if (responseJson.status) {
          localStorage.setItem("co_branding_image",responseJson.result.co_branding.image)
          localStorage.setItem("co_branding_url",responseJson.result.co_branding.url)
          localStorage.setItem("email", email);
          localStorage.setItem("juiced-token", responseJson.result.user_token);
          dispatch(set_Login(email, responseJson.result.user_token));
          let screenSaver = responseJson.result.screen_saver === undefined ? 0 :responseJson.result.screen_saver
          let timeOut = responseJson.result.time_out === undefined ?{ "text": "", "image": "", "time":0 }: responseJson.result.time_out
          dispatch(get_ads(responseJson.result.user_token));
          dispatch(timeout(screenSaver,timeOut))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_Login(email, userToken) {
  return {
    type: SET_LOGIN,
    payload: {
      email: email,
      token: userToken,
    }
  };
}

export function reset_password(email) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/reset-password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message))
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function register(sponsorcode, email, password) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        sponsor_code: sponsorcode,
        email: email,
        password: password
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
