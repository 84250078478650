import React, { Component } from "react";
import { connect } from "react-redux";
import {set_ads_index,set_music_index,show_ads,set_redirect,set_play_page,set_play_seconds,seek_to,set_current_track_index,set_shuffle_incrementer,set_one_time_play, set_play_button,set_play_duration,set_play_progress,set_volume,set_play_prev_next_track,set_repeat,set_shuffle, set_shuffle_list,get_current_music_played_count,set_edit_update_view} from "../../actions/react player/reactPlayerAction";
import ReactPlayer from "../../components/react player/reactPlayer"
export class ReactPlayerCont extends Component {
  render() {
    return (
      <ReactPlayer {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    reactPlayer:store.reactPlayer,
    viewPlay:store.viewPlay,
    screenSaver:store.screenSaver,
    sideNav:store.sideNav
  };
};

export const mapDispatchToProps = dispatch => {
    return {
      set_shuffle_incrementer: (payload) => {
        dispatch(set_shuffle_incrementer(payload));
      },
      seek_to: (payload) => {
        dispatch(seek_to(payload));
      },
      set_current_track_index: (payload) => {
        dispatch(set_current_track_index(payload));
      },
      set_one_time_play: (payload) => {
        dispatch(set_one_time_play(payload));
      },
      set_play_button: (payload) => {
        dispatch(set_play_button(payload));
      },
      set_shuffle: (payload) => {
        dispatch(set_shuffle(payload));
      },
      set_shuffle_list: (payload) => {
        dispatch(set_shuffle_list(payload));
      },
      set_repeat: (payload) => {
        dispatch(set_repeat(payload));
      },
      set_play_duration: (payload) => {
        dispatch(set_play_duration(payload));
      },
      set_volume: (payload) => {
        dispatch(set_volume(payload));
      },
      set_play_progress: (payload) => {
        dispatch(set_play_progress(payload));
      },
      set_play_prev_next_track:(payload) => {
        dispatch(set_play_prev_next_track(payload));
      },
      set_play_seconds:(payload) => {
        dispatch(set_play_seconds(payload));
      },
      set_play_page:(current,last) => {
        dispatch(set_play_page(current,last));
      },
      set_redirect:(last) => {
        dispatch(set_redirect(last));
      },
      set_music_index:(data) => {
        dispatch(set_music_index(data));
      },
      show_ads:(data) => {
        dispatch(show_ads(data));
      },
      set_ads_index:(data) => {
        dispatch(set_ads_index(data));
      },
      get_current_music_played_count:(token,track_id)=>{
        dispatch(get_current_music_played_count(token,track_id));
      },
      set_edit_update_view:(payload)=>{
        dispatch(set_edit_update_view(payload));
      }
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(ReactPlayerCont);