import { SET_COLLECTION_REMOVE, SET_COLLECTION, SET_CURRENT_PLAYLIST_NAME, SET_VIEW_COLLECTION, SELECTED_COLLECTION, SET_EXPANDED, RESET_PLAYER } from "../../constants/playlist/playlistConst";
const initial_state = {
  collectionPlaylist: [],
  limit: 10,
  starting_after: 0,
  totalData: 0,
  currentDataLength: 0,
  currentPlaylist: '',
  allCollection: [],
  selectedCollection: [],
  collectionRemove: [],
  expanded: ''
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_COLLECTION:
      return (state = {
        ...state,
        collectionPlaylist: action.payload.result,
        starting_after: action.payload.start_after,
        totalData: action.payload.totalData,
        currentDataLength: action.payload.result.length
      });
    case SET_CURRENT_PLAYLIST_NAME:
      return (state = {
        ...state,
        currentPlaylist: action.payload
      });
    case SET_VIEW_COLLECTION:
      return (state = {
        ...state,
        allCollection: action.payload
      });
    case SELECTED_COLLECTION:
      return (state = {
        ...state,
        selectedCollection: action.payload
      });
    case SET_COLLECTION_REMOVE:
      return (state = {
        ...state,
        collectionRemove: action.payload
      });
    case SET_EXPANDED:
      return (state = {
        ...state,
        expanded: action.payload
      });
    case RESET_PLAYER:
      return state = {
        ...state, playlistData: [],
        collectionPlaylist: [],
        limit: 10,
        starting_after: 0,
        totalData: 0,
        currentDataLength: 0,
        currentPlaylist: '',
        allCollection: [],
        selectedCollection: [],
        collectionRemove: [],
        expanded: ''
      };
    default:
      return state;
  }

}