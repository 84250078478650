import React, { Component } from "react";
import {
  Grid,
  Card,
  TextField,
  Button,
  Icon,
  IconButton,
  Dialog,
  Paper,
  List,
  CardContent,
  Radio,
  withStyles,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import "./edit.css";
import Table from "./table"

const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class EditPlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      changePlaylistDialog: false,
      availablePlaylistDialog: false,
      selectedRadio: '',
      playlistName: '',
      selectedPlaylistID: '',
      selectPlayListName: [],
      selectPreviousPlayListName: [],
      showPlaylistData: false,
      deleteDialog: false
    };
  }

  componentDidMount() {
    this.props.set_position(3)
    this.props.get_playlist(this.props.login.token)
    if (localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.tracks.length > 0 && this.props.createPlay.showMoodTracks.length === 0) {
      this.props.set_t([])
      this.props.set_play_prev_next_track(0)
    }
  }

  availablePlaylistDialog = () => {
    this.setState({ availablePlaylistDialog: !this.state.availablePlaylistDialog })
  }

  changePlaylistDialog = () => {
    this.setState({ changePlaylistDialog: !this.state.changePlaylistDialog })
  }

  deleteIconButton = () => {
    this.setState({
      deleteDialog: !this.state.deleteDialog
    })
  }

  arrowUpwardButton = () => {
    var editPlaylist = this.props.edit.playlist;
    var incrementer = 0;
    this.props.edit.playlist.map((e, i) => {
      if (i - incrementer !== 0 && editPlaylist[i].check === true) {
        var element = { ...editPlaylist[i]};
        editPlaylist.splice(i, 1);
        editPlaylist.splice(i - 1, 0, element);
        this.props.arange_tracks_up(editPlaylist);
        incrementer++;
      }
      return null
    })
    let trackInfo = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track
    : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track
    : ''
    this.props.update_playlist(this.props.login.token, this.props.edit.selectedPlaylistID, true, 
      this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1], 
      this.props.edit.playlist,this.props.viewPlay.selectedToPlay, this.props.viewPlay.selectedToPlay,trackInfo._id,trackInfo.play_id,
      this.props.reactPlayer.shuffle,this.props.reactPlayer.shuffleList.length)
  }

  arrowDownwardButton = () => {
    var editPlaylist = this.props.edit.playlist;
    var incrementer = this.props.edit.playlist.length - 2;
    this.props.edit.playlist.map(() => {
      if (incrementer > -1) {
        if (editPlaylist[incrementer].check === true) {
          var element = { ...editPlaylist[incrementer]};
          editPlaylist.splice(incrementer, 1);
          editPlaylist.splice(parseInt(incrementer + 1), 0, element);
          this.props.arange_tracks_up(editPlaylist);
        }
        incrementer--;
      }
      return null
    })
    let trackInfo = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track
    : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track
    : ''
    this.props.update_playlist(this.props.login.token, this.props.edit.selectedPlaylistID, true, 
      this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1], 
      this.props.edit.playlist,this.props.viewPlay.selectedToPlay, this.props.viewPlay.selectedToPlay,trackInfo._id,trackInfo.play_id,
      this.props.reactPlayer.shuffle,this.props.reactPlayer.shuffleList.length)
  }


  FirstIconButton = () => {
    var editPlaylist = this.props.edit.playlist;
    var incrementer = 0;
    this.props.edit.playlist.map((e, i) => {
      if (e.check === true) {
        var element = { ...editPlaylist[i] };
        editPlaylist.splice(i, 1);
        editPlaylist.splice(parseInt(incrementer), 0, element);
        this.props.arange_tracks_up(editPlaylist);
        incrementer++;
      }
    return null
    })
    let trackInfo = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track
    : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track
    : ''
    this.props.update_playlist(this.props.login.token, this.props.edit.selectedPlaylistID, true, 
      this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1], 
      this.props.edit.playlist,this.props.viewPlay.selectedToPlay, this.props.viewPlay.selectedToPlay,trackInfo._id,trackInfo.play_id,
      this.props.reactPlayer.shuffle,this.props.reactPlayer.shuffleList.length)
  }


  LastIconButton = () => {
    var editPlaylist = this.props.edit.playlist;
    var incrementer = this.props.edit.playlist.length - 1;
    var incrementerPosition = 0;
    this.props.edit.playlist.map((e, i) => {
      if (editPlaylist[i - incrementerPosition].check === true) {
        var element = { ...editPlaylist[i - incrementerPosition]};
        editPlaylist.splice(i - incrementerPosition, 1);
        editPlaylist.splice(parseInt(incrementer), 0, element);
        this.props.arange_tracks_up(editPlaylist);
        incrementerPosition++;
        incrementer--;
      }
      return null
    })
    let trackInfo = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track
    : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track
    : ''
    this.props.update_playlist(this.props.login.token, this.props.edit.selectedPlaylistID, true, 
      this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1], 
      this.props.edit.playlist,this.props.viewPlay.selectedToPlay, this.props.viewPlay.selectedToPlay,trackInfo._id,trackInfo.play_id,
      this.props.reactPlayer.shuffle,this.props.reactPlayer.shuffleList.length)
  }

  changePlaylistNameDialog = () => {
    this.props.update_playlist(this.props.login.token, this.props.edit.selectedPlaylistID, true, this.props.edit.playlistName, this.props.edit.playlist,this.props.viewPlay.selectedToPlay)
    this.setState({
      changePlaylistDialog: !this.state.changePlaylistDialog,
      playlistName: ''
    })
  }

  cancelPlaylistNameDialog = () => {
    this.setState({
      changePlaylistDialog: !this.state.changePlaylistDialog,
      playlistName: ''

    })
  }

  deleteTrackDialog = () => {
    var filterData = this.props.edit.playlist.filter(v => v.check !== true)
    let trackInfo = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track
    : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track
    : ''
    this.props.delete_track(this.props.login.token, this.props.edit.selectedPlaylistID, true, this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1], filterData,this.props.viewPlay.selectedToPlay, this.props.viewPlay.selectedToPlay,trackInfo._id,trackInfo.play_id,
      this.props.reactPlayer.shuffle,this.props.reactPlayer.shuffleList.length)
    this.setState({
      deleteDialog: !this.state.deleteDialog
    })
  }

  availablePlaylistCancelButton = () => {
    this.setState({
      availablePlaylistDialog: !this.state.availablePlaylistDialog,
      selectedRadio: '',
      showPlaylistData: this.state.selectPreviousPlayListName.length > 0 ? true : false
    })
    this.props.selectedRadio('')
    this.props.show_playlist_data(this.props.edit.selectPlayListName.length > 0 ? true : false)
    this.props.set_playlist_name_dialog([this.props.edit.selectPreviousPlayListName[this.props.edit.selectPreviousPlayListName.length - 1]], [this.props.edit.selectPreviousPlayListName[this.props.edit.selectPreviousPlayListName.length - 1]])
  }

  availablePlaylistOkButton = () => {
    this.props.get_tracks_from_playlist(this.props.login.token, this.props.edit.selectedRadio)
    this.setState({
      availablePlaylistDialog: !this.state.availablePlaylistDialog,
      selectedRadio: '',
      showPlaylistData: true,
    })
    this.props.show_playlist_data(true)
    this.props.selectedRadio('')
    this.props.set_playlist_name_dialog(this.props.edit.selectPlayListName, [this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1]])
  }

  radioButtonHandler = (data) => {
    this.props.selectedPlaylistID(data.playlist_id)
    this.props.selectedRadio(data.playlist_id)
    this.props.show_playlist_data(false)
    this.props.set_playlist_name_dialog([...this.props.edit.selectPlayListName, data.name], [this.props.edit.selectPreviousPlayListName[this.props.edit.selectPreviousPlayListName.length - 1]])
  }
  render() {
    var classes = this.props.classes
    return (
      <>
        {/* edit screen page */}
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card className="edit-play-container">
              <Grid className="btn-container">
                <Button onClick={() => { this.availablePlaylistDialog() }} fullWidth className="update-btn">
                  SELECT A PLAYLIST TO EDIT
                </Button>
              </Grid>
              <Grid container className="edit-title-fields">
                <Grid item container xs={7}>
                  {this.props.edit.showPlaylistData === true ?
                    <>
                      <Grid style={{ paddingTop: 10 }} container justify='flex-end' item xs={8} md={3}>
                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>{this.props.edit.showPlaylistData && this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1].toUpperCase()}</span>
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <IconButton style={{ marginLeft: '3%' }} onClick={() => { this.changePlaylistDialog() }}>
                          <Icon style={{ fontSize: 18 }}>
                            edit
                          </Icon>
                        </IconButton>
                      </Grid>
                    </>
                    :
                    ''
                  }
                  
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => {
                      this.deleteIconButton()

                    }}
                    disabled={this.props.edit.playlist.length === 0 || this.props.edit.check === false ? true : false}
                    className="btn-up-down">
                    <Icon>delete</Icon>
                  </Button>
                  <Button onClick={() => {
                    this.arrowUpwardButton()
                  }}
                    disabled={this.props.edit.playlist.length === 0 || this.props.edit.check === false ? true : false}
                    className="btn-up-down">
                    <Icon>arrow_upward</Icon>1
                  </Button>
                  <Button
                    disabled={this.props.edit.playlist.length === 0 || this.props.edit.check === false ? true : false}
                    onClick={() => {
                      this.arrowDownwardButton()
                    }}
                    className="btn-up-down">
                    <Icon>arrow_downward</Icon>1
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    disabled={this.props.edit.playlist.length === 0 || this.props.edit.check === false ? true : false}
                    onClick={() => {
                      this.FirstIconButton()
                    }}
                    className="btn-up-down">
                    <Icon>arrow_upward</Icon>
                    FIRST
                  </Button>
                  <Button
                    disabled={this.props.edit.playlist.length === 0 || this.props.edit.check === false ? true : false}
                    onClick={() => {
                      this.LastIconButton()
                    }}
                    className="btn-up-down">
                    <Icon>arrow_downward</Icon>
                    LAST
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <Card>
              <Grid item className="song-playlist-cont">
                {/* edit screen table where data is shown */}
                <Table prop={this.props}
                  playlistName={this.props.edit.selectPlayListName[this.props.edit.selectPlayListName.length - 1]}
                  playlist={this.props.edit.playlist}
                  playlistId={this.props.edit.selectedPlaylistID}
                />
              </Grid>
            </Card>
          </Grid>

        </Grid>
        <Dialog
          open={this.state.changePlaylistDialog}
          onClose={() => {
            this.setState({
              changePlaylistDialog: !this.state.changePlaylistDialog
            })
          }}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container >
            <Grid item xs={12} className="closeButtonContainer">
              <IconButton
                onClick={() => {
                  this.setState({
                    changePlaylistDialog: !this.state.changePlaylistDialog,
                    playlistName: ''
                  })
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12} className="loginHeaderContainer">
              <span className="logintext">CHANGE PLAYLIST NAME</span>
            </Grid>
            <Grid item xs={12} className="passwordContainer">
              <TextField
                label="PLAYLIST NAME"
                value={this.props.edit.playlistName}
                onChange={(e) => {
                  this.props.set_playlist_name(e.target.value)
                }}
                fullWidth
                className="passwordTextField"
              />
            </Grid>
            <Grid container className="button-cont">
              <Grid item xs={8} className="ok-container">
                <Button variant="outlined" className={this.props.edit.track.map(v => v.name).includes(this.props.edit.playlistName) || this.props.edit.playlistName === '' || this.props.edit.playlist.length === 0 ? "disable-button" : "ok-button"}
                  disabled={this.props.edit.track.map(v => v.name).includes(this.props.edit.playlistName) || this.props.edit.playlistName === '' || this.props.edit.playlist.length === 0 ? true : false}
                  onClick={() => {
                    this.changePlaylistNameDialog()
                  }}>
                  OK
                </Button>
              </Grid>
              <Grid item xs={4} className="cancel-container">
                <Button
                  className="cancel-button"
                  onClick={() => {
                    this.cancelPlaylistNameDialog()
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.availablePlaylistDialog}
          onClose={() => {
            this.setState({
              availablePlaylistDialog: !this.state.availablePlaylistDialog
            })
          }}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container>
            <Grid item xs={12} className="closeButtonContainer">
              <IconButton
                onClick={() => {
                  this.setState({
                    availablePlaylistDialog: !this.state.availablePlaylistDialog
                  })
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12} className="available-playlist-cont-text">
              <span className="logintext">AVAILABLE PLAYLISTS</span>
            </Grid>
            <Grid item xs={12} className="spantext">
              <span>
                ONLY ONE PLATLIST CAN BE EDITED AT A TIME.
              </span>
            </Grid>
            <Grid item xs={12} className="passwordContainer">
              <Paper className="paper">
                <List>
                  {this.props.edit.track.map((v,i) => (
                    <Card style={{ marginBottom: 5 }} key={i}>
                      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Radio
                          checked={this.props.edit.selectedRadio === v.playlist_id}
                          onChange={() => {
                            this.radioButtonHandler(v)
                          }}
                          value={v.playlist_id}
                          name="radio-button-demo"
                          size='small'
                          classes={{ root: classes.radio, checked: classes.checked }}
                          inputProps={{ 'aria-label': 'A' }}
                        />
                        {v.name.toUpperCase()}
                      </CardContent>
                    </Card>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid container className="button-cont">
              <Grid item xs={8} className="ok-container">
                <Button variant="outlined" className={this.props.edit.selectedRadio !== '' ? "ok-button" : "disable-button"} disabled={this.props.edit.selectedRadio === '' ? true : false} onClick={() => {
                  this.availablePlaylistOkButton()
                }}>
                  OK
                </Button>
              </Grid>
              <Grid item xs={4} className="cancel-container">
                <Button
                  className="cancel-button"
                  onClick={() => {
                    this.availablePlaylistCancelButton()
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
        <Dialog
          open={this.state.deleteDialog}
          onClose={() => {
            this.setState({
              deleteDialog: !this.state.deleteDialog
            })
          }}
          aria-labelledby="customized-dialog-title"
        >
          <Grid style={{ backgroundColor: "#f6842d" }}>
            <DialogContent>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>ARE YOU SURE YOU WANT TO DELETE THIS TRACK?</span>
            </DialogContent>
            <DialogActions>
              <Button
                className="delete-button"
                autoFocus
                onClick={() => {
                  this.deleteTrackDialog()
                }
                }

                color="primary">
                Confirm
              </Button>
              <Button autoFocus
                className="delete-button"
                onClick={() => {
                  this.setState({
                    deleteDialog: !this.state.deleteDialog
                  })
                }}
                color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditPlayList);
