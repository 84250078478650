import React, { Component } from "react";
import {
  Grid,
  withStyles
} from "@material-ui/core";

import "../style.css";
const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class BodySideNavViewCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }
  render() {
    return (
      <>
        <Grid container className="view-text-cont" >
          <span className="view-text-span-first">
            Take your unique collection of selected{" "}
          </span>
          <span className="view-text-span">
            music tracks and turn them into
          </span>
          <span className="view-text-span">
            personalized mood-driven playlists that
          </span>
          <span className="view-text-span">
            you can further customize. They call it
          </span>
          <span className="view-text-span">
            "Coffee House" we say name{" "}
            <span className="view-text-bold">YOUR</span>
          </span>
          <span className="view-text-span">
            mix of mood music whatever you want.
          </span>
          <br />
          <p className="view-text-para">GET MORE!</p>
        </Grid>
      </>
    );
  }
}


export default withStyles(styles)(BodySideNavViewCollection);
