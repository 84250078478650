import React, { Component } from "react";
import {
  Grid,
  withStyles
} from "@material-ui/core";

import "../style.css";
const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class BodySideNavEditPlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() { }
  render() {

    return (
      <>
        <Grid container className="view-text-cont" >
          <span className="view-text-span">
            You can edit a playlist here by clicking
          </span>
          <span className="edit-text-bold">SELECT A PLAYLIST TO EDIT.</span>
          <span className="view-text-span">
            Remove tracks and rearrange the
          </span>
          <span className="view-text-span">
            order of tracks. Click the pencil icon
          </span>
          <span className="view-text-span">
            to change the name of your playlist.
          </span>
          <span className="view-text-span">
            Changes are saved automatically.
          </span>
        </Grid>
        <Grid container className="edit-quote-text-cont">
          <span className="view-text-span">
            "The best solutions are often simple,
          </span>
          <span style={{ paddingLeft: 60 }} className="view-text-span">
            yet unexpected."
          </span>
          <span className="edit-quote-text-bold">JULIAN CASABLANCAS</span>
        </Grid>
      </>
    );
  }
}


export default withStyles(styles)(BodySideNavEditPlay);
