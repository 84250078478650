import React, { Component } from "react";
import {
  Grid,
  Card,
  Icon,
  IconButton,
} from "@material-ui/core";
import "./component/style.css";
import Table from "./component/table";
import RemoveDialog from "./component/removeDialog";
import AddDialog from "./component/addDialog";

class ViewCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      sideNav: false,
      removeDialog: false,
      addDialog: false
    };
  }

  componentDidMount() {
    this.props.set_position(0);
    this.props.get_collection(this.props.login.token, this.props.viewCollection.limit, this.props.viewCollection.starting_after)
    if (localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.tracks.length > 0 && this.props.createPlay.showMoodTracks.length === 0) {
      this.props.set_track([])
      this.props.set_play_prev_next_track(0)
    }
  }

  handlePagination = (action) => {
    if (action === "fwd") {
      if (this.props.viewCollection.starting_after < this.props.viewCollection.totalData) {
        this.props.get_collection(
          this.props.login.token,
          this.props.viewCollection.limit,
          this.props.viewCollection.starting_after + this.props.viewCollection.limit
        )
      }
    }
    if (action === "back") {
      if (this.props.viewCollection.starting_after > 0) {
        this.props.get_collection(
          this.props.login.token,
          this.props.viewCollection.limit,
          this.props.viewCollection.starting_after - this.props.viewCollection.limit
        )
      }
    }
  }
  openAddDialog = () => {
    this.setState({
      addDialog: !this.state.addDialog,
    });
    this.props.view_collections(this.props.login.token)
  }

  openRemoveDialog = () => {
    this.setState({
      removeDialog: !this.state.removeDialog,
    });
    this.props.get_collection_for_remove(this.props.login.token)
  }
  
  render() {
    return (
      <>
        <Grid container style={{ overflowY: 'hidden' }}>
          <Grid item xs={12} lg={12}>
            <Card className="view-play-container">
              <IconButton
                style={{
                  position: "relative",
                  left: 1
                }}
                className="collection-add-remove"
                onClick={() => {
                  this.openAddDialog()
                }}
              >
                <Icon>add</Icon>
              </IconButton>
              <IconButton
                className="collection-add-remove"
                onClick={() => {
                  this.openRemoveDialog()
                }}
              >
                <Icon>remove</Icon>
              </IconButton>
            </Card>

            <Card className="scroll">
              <Grid item xs={12}>
                <Table prop={this.props} data={this.props.viewCollection.collectionPlaylist} />
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {/* remove collection popup dialog */}
        <RemoveDialog
          open={this.state.removeDialog}
          prop={this.props}
          close={(value) => {
            this.setState({
              removeDialog: value,
            });
          }}
        />
        {/* add collection popup dialog */}
        <AddDialog
          open={this.state.addDialog}
          prop={this.props}
          close={(value) => {
            this.setState({
              addDialog: value,
            });
          }}
        />
      </>
    );
  }
}

export default ViewCollection;
