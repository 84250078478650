export const  SET_PLAY_BUTTON = "SET_PLAY_BUTTON";
export const  SET_PLAY_DURATION= "SET_PLAY_DURATION";
export const  SET_PLAY_PROGRESS = "SET_PLAY_PROGRESS";
export const  SET_SHUFFLE_LIST = "SET_SHUFFLE_LIST";
export const  SET_VOLUME = "SET_VOLUME";
export const  SET_PLAY_PREV_NEXT_TRACK = "SET_PLAY_PREV_NEXT_TRACK";
export const  SET_REPEAT = "SET_REPEAT";
export const  SET_SHUFFLE = "SET_SHUFFLE";
export const  SET_ONE_TIME_PLAY = "SET_ONE_TIME_PLAY";
export const SET_SHUFFLE_INCREMENTER = 'SET_SHUFFLE_INCREMENTER';
export const SET_CURRENT_TRACK_INDEX = 'SET_CURRENT_TRACK_INDEX';
export const SEEK_TO = 'SEEK_TO';
export const SET_PLAY_PROGRESS_SECONDS = 'SET_PLAY_PROGRESS_SECONDS';
export const SET_PLAY_PAGE = 'SET_PLAY_PAGE';
export const SET_LAST_PLAY_ID = 'SET_LAST_PLAY_ID';
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_SCREEN_SAVER_ON ="SET_SCREEN_SAVER_ON"
export const SET_ADS = "SET_ADS"
export const SHOW_ADS = "SHOW_ADS"
export const SET_MUSIC_INDEX = "SET_MUSIC_INDEX"
export const SET_ADS_INDEX = "SET_ADS_INDEX"