import React, { Component } from "react";
import {
  Dialog,
  Grid,
  Button,
  IconButton,
  Icon,
  Paper,
  List,
  Checkbox,
} from "@material-ui/core";
import "./dialogStyles.css";
class AddDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      sideNav: false,
      removeDialog: false,
      name: "bests",
      track: [],
    };
  }
  checkboxHandler = (val) => {
    if (this.props.prop.viewCollection.selectedCollection.length === 0) {
      this.props.prop.selected_collection([val._id])
    } else {
      if (this.props.prop.viewCollection.selectedCollection.includes(val._id)) {
        let selectedID = this.props.prop.viewCollection.selectedCollection
        let removeId = selectedID.indexOf(val._id)
        selectedID.splice(removeId, 1)
        this.props.prop.selected_collection(selectedID)
      } else {
        this.props.prop.selected_collection([...this.props.prop.viewCollection.selectedCollection, val._id])
      }
    }
  }

  okButton = async()=>{
    await this.props.prop.add_collection(
      this.props.prop.login.token,
      this.props.prop.viewCollection.selectedCollection
    )
    await this.props.prop.selected_collection([])
    await this.props.close(false);
  }

  cancelButton = ()=>{
    this.props.close(false);
    this.props.prop.selected_collection([])
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.props.close(false);
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container>
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
                this.props.close(false);
                this.props.prop.selected_collection([])
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="available-playlist-cont-text">
            <span className="logintext">ADD COLLECTION</span>
          </Grid>
          <Grid item xs={12} className="spantext">
            <span>
              SELECT THE COLLECTION YOU WANT TO ADD AND CLICK ON OK.  
            </span>
          </Grid>
          <Grid item xs={12} className="passwordContainer">
            <Paper className="paper">
              <List>
                {this.props.prop.viewCollection.allCollection.length > 0 &&
                  <Grid className="grid-inner">
                    {this.props.prop.viewCollection.allCollection.length > 0 && this.props.prop.viewCollection.allCollection.map((val, i) => {
                      return (
                        <List
                          key={i}
                          className="list"
                          style={{
                            padding: 0,
                            borderStyle: "solid",
                            borderLeft: 0,
                            borderRight: 0,
                            borderTop: 0,
                            borderColor: "gray",
                          }}
                        >
                          <Checkbox
                            checked={this.props.prop.viewCollection.selectedCollection.includes(val._id) ? true : false}
                            onClick={() => {
                              this.checkboxHandler(val)
                            }}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          {val.playlist_name.toUpperCase()}
                        </List>
                      )
                    })}
                  </Grid>
                }
              </List>
            </Paper>
          </Grid>
          <Grid container className="button-cont">
            <Grid item xs={6} sm={8} className="ok-container">
              <Button
                variant="outlined"
                className={ this.props.prop.viewCollection.selectedCollection.length === 0?"disable-button":"ok-button"}
                disabled={ this.props.prop.viewCollection.selectedCollection.length === 0}
                onClick={async()=>{
                  this.okButton()
                }}
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} className="cancel-container">
              <Button
                className="cancel-button"
                onClick={() => {
                 this.cancelButton()
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog >
    );
  }
}

export default AddDialog;
