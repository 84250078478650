import UNIVERSAL from "../../config/config";
import { SET_COLLECTION_REMOVE, SET_COLLECTION, SET_FILTER_PLAYLIST_TRACKS_PLAYER, SET_CURRENT_PLAYLIST_NAME, SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE, SET_VIEW_COLLECTION, SELECTED_COLLECTION, SET_EXPANDED } from "../../constants/playlist/playlistConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { onLogoutFromDifferentDevice } from "../login/loginActions"
import { set_snack_bar } from '../snackbar/snackbar_action'
export function set_collection(payload, limit, start_after, totalData) {
  return {
    type: SET_COLLECTION,
    payload: { result: payload, limit: limit, start_after: start_after, totalData: totalData },
  }
}

export function set_track(payload) {
  var track = payload.map((m) => ({ track: m }))
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_PLAYER,
    payload: track,
  }
}

export function set_expanded(payload) {
  return {
    type: SET_EXPANDED,
    payload: payload,
  }
}

export function set_track_shuffle(payload, index, newShuffle) {
  var track = payload.map((m) => ({ track: m }))
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE,
    payload: { track: track, index: index, newShuffle: newShuffle },
  }
}

export function set_current_playlist(payload) {
  return {
    type: SET_CURRENT_PLAYLIST_NAME,
    payload: payload,
  }
}

export function get_collection(token, limit, start_after,show_snack) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_users_collections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        limit: limit,
        starting_after: start_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          if(show_snack === undefined)
          {
            dispatch(set_snack_bar(responseJson.status, responseJson.message))
          }
          dispatch(set_collection(responseJson.result, limit, start_after, responseJson.total));
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(unset_loader())
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          if(show_snack === undefined)
          {
            dispatch(set_snack_bar(responseJson.status, responseJson.message))
          }
          dispatch(set_view_collection([]))
          dispatch(set_collection([], 0, 0));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_collection_remove(payload) {
  return {
    type: SET_COLLECTION_REMOVE,
    payload: { result: payload },
  }
}
export function get_collection_for_remove(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_users_collections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({}),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_collection(token,0,0, false))
          // dispatch(set_snack_bar(responseJson.status, responseJson.message))
          dispatch(set_collection_remove(responseJson.result));
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(unset_loader())
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
          dispatch(set_collection([]));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_view_collection(payload) {
  return {
    type: SET_VIEW_COLLECTION,
    payload: payload,
  }
}

export function selected_collection(payload) {
  return {
    type: SELECTED_COLLECTION,
    payload: payload,
  }
}
export function view_collections(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_collections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({}),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader())
        if (responseJson.status) {
          dispatch(set_view_collection(responseJson.result));
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
          dispatch(set_view_collection([]));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function add_collection(token, payload) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/add_user_collection", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        collection_id: JSON.stringify(payload)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_collection(token,0,0,false))
          dispatch(set_snack_bar(responseJson.status, "Selected Collection Added"))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(unset_loader())
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function remove_collection(token, payload, limit, start_after) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/remove_collection_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        collection_id: JSON.stringify(payload)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_collection(token, limit, start_after,false))
          dispatch(set_snack_bar(responseJson.status, "Selected Collection Removed"))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(unset_loader())
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
