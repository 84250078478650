import React from "react";
import {
  Dialog,
  TextField,
  Grid,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import "../style.css";
export default class RegisterDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsorCode: "",
      email: "",
      password: "",
      confirmPassword: "",
      emailReg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={this.props.open}
        onClose={() => {
          this.props.close(false);
          this.setState({
            sponsorCode: "",
            email: "",
            password: "",
            confirmPassword: ""
          });
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container>
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
                this.props.close(false);
                this.setState({
                  sponsorCode: "",
                  email: "",
                  password: "",
                  confirmPassword: ""
                });
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="registerHeaderContainer">
            <span className="registertext">Register</span>
          </Grid>
          <Grid item xs={12} className="sponsorContainer">
            <TextField
              label="SPONSOR CODE"
              value={this.state.sponsorCode}
              onChange={(e) => {
                this.setState({ sponsorCode: e.target.value });
              }}
              fullWidth
              data-test="sponsor"
              className="sponsorTextField"
              onKeyPress={async (event) => {
                if (event.key === "Enter") {
                  if (this.state.emailReg.test(this.state.email) === true && this.state.password !== '' && this.state.confirmPassword !== '' && this.state.sponsorCode !== '' && this.state.password === this.state.confirmPassword) {
                    await this.props.prop.register(
                      this.state.sponsorCode,
                      this.state.email,
                      this.state.password
                    );
                    this.setState({
                      sponsorCode: "",
                      email: "",
                      password: "",
                      confirmPassword: ""
                    });
                    this.props.close(false);
                  }
                }
              }
              }
            />
          </Grid>
          <Grid item xs={12} className="emailRegisterContainer">
            <TextField
              label="EMAIL@EXAMPLE.COM"
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              error={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ? true : false}
              label={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ? "EMAIL@EXAMPLE.COM" : "EMAIL"}
              fullWidth
              data-test="email"
              className="emailRegisterTextField"
              onKeyPress={async (event) => {
                if (event.key === "Enter") {
                  if (this.state.emailReg.test(this.state.email) === true && this.state.password !== '' && this.state.confirmPassword !== '' && this.state.sponsorCode !== '' && this.state.password === this.state.confirmPassword) {
                    await this.props.prop.register(
                      this.state.sponsorCode,
                      this.state.email,
                      this.state.password
                    );
                    this.setState({
                      sponsorCode: "",
                      email: "",
                      password: "",
                      confirmPassword: ""
                    });
                    this.props.close(false);
                  }
                }
              }
              }
            />
          </Grid>
          <Grid item xs={12} className="passwordContainer">
            <TextField
              label="PASSWORD"
              type="password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              fullWidth
              data-test="password"
              className="passwordTextField"
              onKeyPress={async (event) => {
                if (event.key === "Enter") {
                  if (this.state.emailReg.test(this.state.email) === true && this.state.password !== '' && this.state.confirmPassword !== '' && this.state.sponsorCode !== '' && this.state.password === this.state.confirmPassword) {
                    await this.props.prop.register(
                      this.state.sponsorCode,
                      this.state.email,
                      this.state.password
                    );
                    this.setState({
                      sponsorCode: "",
                      email: "",
                      password: "",
                      confirmPassword: ""
                    });
                    this.props.close(false);
                  }
                }
              }
              }
            />
          </Grid>
          <Grid item xs={12} className="confirmPasswordContainer">
            <TextField
              label="CONFIRM PASSWORD"
              value={this.state.confirmPassword}
              error={this.state.confirmPassword !== this.state.password ? true : false}
              onChange={(e) => {
                this.setState({ confirmPassword: e.target.value });
              }}
              fullWidth
              type="password"
              data-test="confpassword"
              className="confirmPasswordTextField"
              onKeyPress={async (event) => {
                if (event.key === "Enter") {
                  if (this.state.emailReg.test(this.state.email) === true && this.state.password !== '' && this.state.confirmPassword !== '' && this.state.sponsorCode !== '' && this.state.password === this.state.confirmPassword) {
                    await this.props.prop.register(
                      this.state.sponsorCode,
                      this.state.email,
                      this.state.password
                    );
                    this.setState({
                      sponsorCode: "",
                      email: "",
                      password: "",
                      confirmPassword: ""
                    });
                    this.props.close(false);
                  }
                }
              }
              }
            />
          </Grid>
          <Grid item xs={12} className="enterRegisterButtonContainer">
            <Button
              data-test="enterButton"
              variant="outlined"
              className="enterRegisterButton"
              disabled={
                this.state.sponsorCode !== "" &&
                  this.state.email !== "" &&
                  this.state.password !== "" &&
                  this.state.confirmPassword !== "" &&
                  this.state.confirmPassword === this.state.password
                  ? false : true
              }
              onClick={async () => {
                if (this.state.emailReg.test(this.state.email) === true) {
                  await this.props.prop.register(
                    this.state.sponsorCode,
                    this.state.email,
                    this.state.password
                  );
                  this.setState({
                    sponsorCode: "",
                    email: "",
                    password: "",
                    confirmPassword: ""
                  });
                  this.props.close(false);
                }
                else {
                  this.props.prop.invalidEmail();
                }
              }}
            >
              ENTER
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
