import {SET_EDIT_UPDATE,
  EDIT_UPDATE, EDIT_SET_PLAYLIST,
  SET_PLAYLIST, SET_SELECTED_TO_PLAY_PLAYLIST, SET_PLAYLIST_TRACKS, SET_FILTER_PLAYLIST_TRACKS, SET_FILTER_PLAYLIST_TRACKS_VIEW, RESET_PLAYER, SET_PLAYLIST_TRACKS_AFTER_EDIT, SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR
} from "../../constants/playlist/playlistConst";
const initial_state = {
  playlist: [],
  selectedToPlay: [],
  tracks: [],
  rtp: [],
  editUpdate: false,
  updateView: false
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_PLAYLIST:
      return (state = {
        ...state,
        playlist: action.payload
      });
    case SET_EDIT_UPDATE:
      return (state = {
        ...state,
        updateView: action.payload
      });
    case SET_SELECTED_TO_PLAY_PLAYLIST:
      return (state = {
        ...state,
        selectedToPlay: action.payload
      });
    case SET_PLAYLIST_TRACKS:
      return (state = {
        ...state,
        tracks: [...state.tracks, ...action.payload],
      });
    case SET_PLAYLIST_TRACKS_AFTER_EDIT:
      return (state = {
        ...state,
        tracks: action.payload.tracks,
      });
    case SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR:
      return (state = {
        ...state,
        tracks: action.payload.tracks,
      });
    case SET_FILTER_PLAYLIST_TRACKS:
      return (state = {
        ...state,
        tracks: action.payload,
      });
    case SET_FILTER_PLAYLIST_TRACKS_VIEW:
      if (action.payload.selectPlayLength > 1) {
        return (state = {
          ...state,
          tracks: [...action.payload.recentTrack, ...action.payload.tracks],
        });
      } else if (action.payload.selectPlayLength === 1) {
        return (state = {
          ...state,
          tracks: action.payload.tracks,
        });
      }
      break
    case RESET_PLAYER:
      return state = {
        ...state, playlistData: [],
        playlist: [],
        selectedToPlay: [],
        tracks: [],
        rtp: []
      };
    case EDIT_UPDATE:
      return (state = {
        ...state,
        editUpdate: action.payload
      });
    case EDIT_SET_PLAYLIST:
      return (state = {
        ...state,
        selectedToPlay: action.payload,
        editUpdate: false,
        updateView:true
      });
    default:
      return state;
  }

}