import React, { Component } from "react";
import { connect } from "react-redux";
import ScreenSaver from "../../components/screensaverandtimeout/screenSaver";
import { set_play_button, set_screensaver_on, get_ads } from "../../actions/react player/reactPlayerAction";
export class ScreenSaverCon extends Component {
  render() {
    return (
      <ScreenSaver {...this.props} />
    );
  }
};



export const mapStateToProps = store => {
  return {
    login: store.login,
    reactPlayer: store.reactPlayer,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    },
    set_screensaver_on: (payload) => {
      dispatch(set_screensaver_on(payload));
    },
    get_ads: (token) => {
      dispatch(get_ads(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaverCon);