import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../../components/login/login";

import { 
  setLogin,
  set_home,
  isLoggedIn,
  logIn,
  reset_password,register,
  invalidEmail
  // getGeoInfo
} from "../../actions/login/loginActions";

export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login
  };
};

export const mapDispatchToProps = dispatch => {
    return {
      setLogin: (type, email, user_token, profile_img, name) => {
        dispatch(setLogin(type, email, user_token, profile_img, name));
      },
      set_home:()=>{
        dispatch(set_home())
      },
      isLoggedIn:()=>{
        dispatch(isLoggedIn())
      },
      register: (sponsorcode,email,password) => {
        dispatch(register(sponsorcode,email,password));
      },
      logIn: (email,password) => {
        dispatch(logIn(email,password));
      },
      reset_password: (email) => {
        dispatch(reset_password(email));
      },
      invalidEmail: () => {
        dispatch(invalidEmail());
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Login);