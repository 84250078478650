import React from "react";
import {
  Dialog,
  TextField,
  Grid,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import "../style.css";

export default class LoginDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email:"",
      password:"",
      emailReg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  render() {
    let {open} = this.props
    const {logIn}=this.props.prop
    return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => {
          this.props.close(false);
          this.setState({
            email: "",
            password: ""
          });
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container>
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
                this.props.close(false);
                this.setState({
                  email: "",
                  password: ""
                });
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="loginHeaderContainer">
            <span className="logintext">Login</span>
          </Grid>
          <Grid item xs={12} className="emailContainer">
            <TextField
              value={this.state.email}
              data-test="email"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              fullWidth
              className="emailTextField"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if(this.state.emailReg.test(this.state.email) === true  && this.state.password !== '')
                    {
                    logIn(
                      this.state.email,
                      this.state.password
                    )
                    this.setState({
                      email:'',
                      password:''
                    })
                    }
                }
              }
            }
              error={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ? true:false}
              label={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ?"EMAIL@EXAMPLE.COM":"EMAIL"}
            />
          </Grid>
          <Grid item xs={12} className="passwordContainer">
            <TextField
              label="PASSWORD"
              data-test="password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              fullWidth
              type="password"
              className="passwordTextField"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if(this.state.emailReg.test(this.state.email) === true && this.state.password !== '')
                    {
                    logIn(
                      this.state.email,
                      this.state.password
                    )
                    this.setState({
                      email:'',
                      password:''
                    })
                    }
                }
              }
            }
            />
          </Grid>
          <Grid item xs={12} className="forgotPasswordContainer">
           <Link className="forgotPasswordAnchor" onClick={()=>{this.props.forgotPassword(true)}}> <span className="forgotPasswordText">FORGOT YOUR PASSWORD?</span></Link>
          </Grid>
          <Grid item xs={12} className="enterButtonContainer">
            <Button variant="outlined"  data-test="enterButton" className="enterButton" 
            disabled={
              this.state.email !==""&&
              this.state.password !==""
              ?false:true
            }
             onClick={()=>{
              if(this.state.emailReg.test(this.state.email) === true)
              {
               logIn(
                 this.state.email,
                 this.state.password
               )
               this.setState({
                 email:'',
                 password:''
               })
              }
               }}
               >
              ENTER
            </Button>
          </Grid>
          <Grid item xs={5} className="horizontalLineContainerLeft">
            <hr style={{ borderColor: "black" }} />
          </Grid>
          <Grid item xs={2} className="orText">
            OR
          </Grid>
          <Grid item xs={5} className="horizontalLineContainerRight">
            <hr style={{ borderColor: "black" }} />
          </Grid>
          <Grid item xs={12} className="registerButtonContainer">
            <Button
              id='rb'
              data-test="registerButton"
              className="registerButton"
              onClick={() => {
                this.props.register(true);
              }}
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
