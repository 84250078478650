import UNIVERSAL from "../../config/config";
import {EDIT_SET_PLAYLIST,EDIT_UPDATE,SET_FILTER_PLAYLIST_TRACKS_PLAYER_EDIT, SHOW_PLAYLIST_DATA, CHECK,SELECTED_PLAYLIST_ID,SELECT_RADIO, ARANGE_TRACKS_UP, SET_SELECTED_PLAYLIST, SET_PLAYLIST_EDIT, SET_PLAYLIST_EDIT_GET, SET_PLAYLIST_TRACKS_EDIT, SET_PLAYLIST_NAME, SET_PLAYLIST_NAME_DIALOG,SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR } from "../../constants/playlist/playlistConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { onLogoutFromDifferentDevice } from "../login/loginActions"
import { set_snack_bar } from '../snackbar/snackbar_action'
export function arange_tracks_up(payload) {
  return {
    type: ARANGE_TRACKS_UP,
    payload: payload,
  }
}
export function check(payload) {
  return {
    type: CHECK,
    payload: payload,
  }
}
export function set_selected_playlist(payload) {
  return {
    type: SET_SELECTED_PLAYLIST,
    payload: payload,
  }
}
export function set_playlist(payload) {
  return {
    type: SET_PLAYLIST_EDIT,
    payload: payload,
  }
}
export function set_playlist_api(payload) {
  return {
    type: SET_PLAYLIST_EDIT_GET,
    payload: payload,
  }
}
export function get_playlist(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify(),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_playlist_api(responseJson.result))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(set_snack_bar(responseJson.status, 'No Playlist Found'))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_playlist_tracks(tracks) {
  return {
    type: SET_PLAYLIST_TRACKS_EDIT,
    payload: tracks,
  }
}

export function show_playlist_data(payload) {
  return {
    type: SHOW_PLAYLIST_DATA,
    payload: payload,
  }
}

export function selectedPlaylistID(payload) {
  return {
    type: SELECTED_PLAYLIST_ID,
    payload: payload,
  }
}

export function selectedRadio(payload) {
  return {
    type: SELECT_RADIO,
    payload: payload,
  }
}

export function editUpdate(payload) {
  return {
    type: EDIT_UPDATE,
    payload: payload,
  }
}

export function set_playlist_name(playlistName) {
  return {
    type: SET_PLAYLIST_NAME,
    payload: playlistName,
  }
}

export function set_playlist_name_dialog(playlistName, previous) {
  return {
    type: SET_PLAYLIST_NAME_DIALOG,
    payload: { current: playlistName, previous: previous },
  }
}

export function get_tracks_from_playlist(token, playlistID) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_playlist_tracks(responseJson.result))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(false, responseJson.message))
          dispatch(set_playlist_tracks([]))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function update_playlist(token, playlistID, active, name, tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength) {
  let update = viewPlayTracks.some( data => data.playlist_id === playlistID)
  return (dispatch) => {
    var trackID = tracks.map(v => v.track._id)
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/update_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID,
        active: active,
        name: name,
        tracks: JSON.stringify(trackID)

      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_playlist(token))
          if(update)
          {
            dispatch(editUpdate(true))
          }
          dispatch(edit_get_playlist(token,selectedToPlay,index,play_id,shuffle,shuffleListLength))
          // dispatch(get_tracks_from_playlist(token, playlistID))
          dispatch(set_playlist_name_dialog([name], [name]))
          // dispatch(check(false))
          dispatch(set_playlist_name(''));
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function delete_track(token, playlistID, active, name, tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength) {
  let update = viewPlayTracks.some( data => data.playlist_id === playlistID)
  return (dispatch) => {
    var trackID = tracks.map(v => v.track._id)
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/update_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID,
        active: active,
        name: name,
        tracks: JSON.stringify(trackID)

      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_playlist(token))
          if(update)
          {
            dispatch(editUpdate(true))
          }
          dispatch(edit_get_playlist(token,selectedToPlay,index,play_id,shuffle,shuffleListLength))
          dispatch(get_tracks_from_playlist(token, playlistID))
          dispatch(set_playlist_name_dialog([name], [name]))
          dispatch(check(false))
          dispatch(set_playlist_name(''));
          dispatch(set_snack_bar(responseJson.status, 'Updated Playlist Successfully'))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status, 'Failed to Upate Playlist Successfully'))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_playlist_tracks_after_edit_arr(tracks,index,newShuffleArray) {
  return {
    type: SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR,
    payload: {tracks:tracks,index,newShuffleArray},
  }
}
export function get_tracks_from_playlist_arr(token, playlistID,current_playling_id,current_playlist_id,shuffle,shuffleListLength) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks_arr_after_edit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: JSON.stringify(playlistID),
        current_playling_id:current_playling_id,
        current_playlist_id:current_playlist_id,
        shuffle:shuffle,
        shuffle_list_length:shuffleListLength
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_playlist_tracks_after_edit_arr(responseJson.result,responseJson.index,responseJson.newShuffleArray))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function edit_set_playlist(tracks) {
  return {
    type: EDIT_SET_PLAYLIST,
    payload: tracks,
  }
}
export function edit_get_playlist(token,selectedToPlay,index,play_id,shuffle,shuffleListLength) {
  let playlistID = []
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify(),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          selectedToPlay.some((v,i) =>{
            playlistID = [...playlistID,v.playlist_id]
            responseJson.result.map((val,index)=>{
              if(val.playlist_id === v.playlist_id){
                selectedToPlay[i] = val
              }
              return null
            })
            return null
          }
          )
          dispatch(get_tracks_from_playlist_arr(token,playlistID,index,play_id,shuffle,shuffleListLength))
          // dispatch(edit_set_playlist(selectedToPlay))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        // else {
        //   dispatch(set_playlist([]))
        //   dispatch(set_snack_bar(responseJson.status, 'No Playlist Found'))
        // }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function update_playlist_drag(token, playlistID, active, name, tracks) {
  return (dispatch) => {
    var trackID = tracks.map(v => v.track._id)
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/update_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID,
        active: active,
        name: name,
        tracks: JSON.stringify(trackID)

      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(editUpdate(true))
          dispatch(set_playlist_name_dialog([name], [name]))
          dispatch(set_playlist_name(''));
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(responseJson.status,responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_track(payload) {
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_PLAYER_EDIT,
    payload:payload,
  }
}