import React, { Component } from "react";
import {
  Grid,
  Button,
  Icon,
  IconButton,
  Slider,
} from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import logo from "../../styles/images/login/img_logo.png";
import { withRouter } from 'react-router-dom'
import "./style.css";
import { withStyles } from "@material-ui/core";
import beingGood from "../../styles/images/login/beingGoodLog.png";
const styles = (theme) => ({
  root: {
    // color: "rgba(100,100,100,1)",
    paddingTop: 10

  },
  thumb: {
    height: 0,
    backgroundColor: "black",
    width: 0,
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 0px rgba(84, 199, 97, 0.16)'
    }
  },
  track: {
    backgroundImage: "linear-gradient(.25turn, #000000, #E88941);",
    height: 8,
  },
  rail: {
    height: 8,
    opacity: 1,
    color: "black"
  },
  root1: {
    color: "#f6842d",

  },
  thumb1: {
    height: 0,
    backgroundColor: "black",
    width: 0,
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 0px rgba(84, 199, 97, 0.16)'
    }
  },
  track1: {
    height: 6,
    color: '#f6842d'
  },
  rail1: {
    height: 6,
    opacity: 1,
    color: "black"
  },
  Btn: {
    position: 'relative',
    top: '-12px!important',
    color: 'black!important',
    "& .MuiTouchRipple-root span": {
      color: '#f6842d!important',
      opacity: .3,
    },
  },
  Btns: {
    color: 'black!important',
    position: 'relative',
    top: '-12px!important',
  },
  Btns1: {
    color: '#f6842d!important',
    position: 'relative',
    top: '-12px!important',
  }
});
class ReactPlayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      play: "",
      changepage: false,
      onClickButtonPrev: false,
      onClickButtonNext: false,
      resetCountTime: false,
      statisticsNoted: false,
      selectedToPlay: '',
      playIndex: 0,
      trackId: '',
      trackLength:0
    };
  }

  componentDidMount() {
    this.setState({
      selectedToPlay: this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.file_location
        : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.file_location
        : '',
      playIndex: this.props.reactPlayer.playIndex,
      trackId: this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track._id
        : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track._id
        : '',
        trackLength:this.props.reactPlayer.tracks.length
    })
  }
  ref = player => {
    this.player = player
  }
  componentDidUpdate(prevProps, prevState) {
    let updateData = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.file_location
      : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.file_location
      : ''
    let trackID = this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track._id
      : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track._id
      : ''
    if (prevState.playIndex !== this.props.reactPlayer.playIndex && this.props.reactPlayer.editMode === false) {
      if (this.state.trackId !== trackID) {
        this.setState({
          selectedToPlay: updateData,
          playIndex: this.props.reactPlayer.playIndex,
          trackId: trackID,
          trackLength:this.props.reactPlayer.tracks.length
        })
      } else{
        this.setState({
          playIndex: this.props.reactPlayer.playIndex
        })
      }
    }else if(this.props.reactPlayer.tracks.length !== this.state.trackLength){
      if (this.state.trackId !== trackID) {
        this.setState({
          selectedToPlay: updateData,
          playIndex: this.props.reactPlayer.playIndex,
          trackId: trackID,
          trackLength:this.props.reactPlayer.tracks.length
        })
      } else{
        this.setState({
          playIndex: this.props.reactPlayer.playIndex,
          trackLength:this.props.reactPlayer.tracks.length
        })
      }
    }
  }

  //shuffle logic 
  addShuffle = () => {
    var newShuffleArray = [], shuffleLen = this.props.reactPlayer.tracks.length - 1, maxShuffleNum = this.props.reactPlayer.tracks.length - 1, shuffle;
    while (newShuffleArray.length <= shuffleLen) {
      shuffle = Math.round(Math.random() * maxShuffleNum);
      if (newShuffleArray.indexOf(shuffle) === -1) {
        newShuffleArray.push(shuffle);
      }
    }
    this.props.set_shuffle_list(newShuffleArray)
  }

  //progress function for ads
  onProgressAdsPlayer = (played) => {
    if (this.props.screenSaver.screenSaverOn === false) {
      this.props.set_play_progress(played.played * 100)
      this.props.set_play_seconds(played.playedSeconds)
    }
  }
  //on ended function for ads
  onEndedAdsPlayer = () => {
    this.props.show_ads(false)
    if (this.props.reactPlayer.adsIndex + 1 >= this.props.reactPlayer.ads.length) {
      this.props.set_ads_index(0)
    } else {
      this.props.set_ads_index(this.props.reactPlayer.adsIndex + 1)
    }
  }
  //progress function for music tracks
  onProgressMusicPlayer = (played) => {
    if (played.playedSeconds >= 30 && this.state.statisticsNoted === false) {
      this.props.get_current_music_played_count(this.props.login.token, this.props.reactPlayer.tracks.length > 0 ? this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track._id
        : this.props.reactPlayer.shuffleIncrementer === 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track._id
        : '')
      this.setState({
        statisticsNoted: true
      })
    } else if (parseInt(played.playedSeconds) < 30 && this.state.statisticsNoted === true) {
      this.setState({
        statisticsNoted: false
      })

    }
    if (this.props.screenSaver.screenSaverOn === false) {
      this.props.set_play_progress(played.played * 100)
      this.props.set_play_seconds(played.playedSeconds)
    }
  }
  //on ended function for music tracks
  onEndedMusicPlayer = () => {
    if (this.props.reactPlayer.playIndex < this.props.reactPlayer.tracks.length - 1) {
      this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex + 1)
      this.props.set_current_track_index(this.props.reactPlayer.playIndex + 1)
    }
    else {
      if (this.props.reactPlayer.oneTimePlay === false) {
        this.props.set_play_prev_next_track(0)
        this.props.set_current_track_index(0)
      }
      else {
        this.props.set_current_track_index(this.props.reactPlayer.tracks.length - 1)
        this.props.set_play_button(!this.props.reactPlayer.playButton)
      }
    }
    this.props.set_music_index(this.props.reactPlayer.musicIndex + 1)
    if (this.props.reactPlayer.musicIndex % this.props.reactPlayer.ads_track_limit === 0) {
      this.props.show_ads(true)
    }
  }

  //shuffle logic button function
  shuffleButton = () => {
    if (this.props.reactPlayer.shuffle === false) {
      this.props.set_shuffle(!this.props.reactPlayer.shuffle)
      var newShuffleArray = [], shuffleLen = this.props.reactPlayer.tracks.length - 1, maxShuffleNum = this.props.reactPlayer.tracks.length - 1, shuffle;
      while (newShuffleArray.length <= shuffleLen) {
        shuffle = Math.round(Math.random() * maxShuffleNum);
        if (newShuffleArray.indexOf(shuffle) === -1) {
          newShuffleArray.push(shuffle);
        }
      }
      this.props.set_shuffle_list(newShuffleArray)
    } else {
      this.props.set_play_prev_next_track(this.props.reactPlayer.currentPlayIndex)
      this.props.set_shuffle(!this.props.reactPlayer.shuffle)
      this.props.set_shuffle_incrementer(0)
      this.props.set_shuffle_list([])
    }
  }

  //previous icon button function
  previousButtonPlayer = (e) => {
    this.setState({
      onClickButtonPrev: true
    })
    setTimeout(
      () => this.setState({ onClickButtonPrev: false }),
      500
    );
    this.props.set_music_index(this.props.reactPlayer.musicIndex + 1)
    if (this.props.reactPlayer.musicIndex % this.props.reactPlayer.ads_track_limit === 0) {
      this.props.show_ads(true)
    }
    this.props.reactPlayer.playIndex - 1 === -1 ? this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1) : this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex - 1)
    if (!this.props.reactPlayer.repeat) {
      if (this.props.reactPlayer.shuffle === true) {
        if (this.props.reactPlayer.shuffleList.length !== this.props.reactPlayer.tracks.length) {
          this.addShuffle();
        }
        var inc = this.props.reactPlayer.shuffleIncrementer === 0 ? 1 : 0
        if (this.props.reactPlayer.shuffleIncrementer === 0) {
          this.props.set_shuffle_incrementer(inc)
        }
        if (this.props.reactPlayer.playIndex - 1 === -1) {
          if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 1] === this.props.reactPlayer.playIndex && inc === 1) {
            this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 2])
            this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 2)
          } else {
            this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 1])
            this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1)
          }
        }
        else {
          if (inc === 1) {
            if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex - 1] === this.props.reactPlayer.playIndex) {
              if (this.props.reactPlayer.playIndex - 2 === -1) {
                this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 1])
                this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1)
              } else {
                this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex - 2])
                this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex - 2)
              }
            } else {
              if (this.props.reactPlayer.playIndex - 1 === -1) {
                if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 1] === this.props.reactPlayer.playIndex) {
                  this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 2])
                  this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 2)
                } else {
                  this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.tracks.length - 1])
                  this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1)
                }
              } else {
                this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex - 1])
                this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex - 1)
              }
            }
          } else {
            if (this.props.reactPlayer.playIndex - 1 === -1) {
              this.props.set_current_track_index(this.props.reactPlayer.tracks.length - 1)
              this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1)
            } else {
              this.props.set_current_track_index(this.props.reactPlayer.playIndex - 1)
              this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex - 1)
            }
          }
        }
      } else {
        if (this.props.reactPlayer.playIndex - 1 === -1) {
          this.props.set_play_prev_next_track(this.props.reactPlayer.tracks.length - 1)
        }
        else {
          this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex - 1)
        }
      }
      this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex - 1] === undefined ? this.props.reactPlayer.shuffleList[this.props.reactPlayer.shuffleList.length - 1] : this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex - 1])
    } else {
      this.player.seekTo(parseFloat(0))
      this.props.set_current_track_index(this.props.reactPlayer.playIndex)
    }
    this.player.seekTo(parseFloat(0))
  }
  //forward icon button function
  forwardButtonPlayer = () => {
    this.setState({
      onClickButtonNext: true
    })
    setTimeout(
      () => this.setState({ onClickButtonNext: false }),
      500
    );
    this.props.set_music_index(this.props.reactPlayer.musicIndex + 1)
    if (this.props.reactPlayer.musicIndex % this.props.reactPlayer.ads_track_limit === 0) {
      this.props.show_ads(true)

    }
    if (!this.props.reactPlayer.repeat) {
      if (this.props.reactPlayer.shuffle === true) {
        if (this.props.reactPlayer.shuffleList.length !== this.props.reactPlayer.tracks.length) {
          this.addShuffle();
        }
        var inc = this.props.reactPlayer.shuffleIncrementer === 0 ? 1 : 0
        if (this.props.reactPlayer.shuffleIncrementer === 0) {
          this.props.set_shuffle_incrementer(inc)
        }
        if (this.props.reactPlayer.playIndex + 1 === this.props.reactPlayer.tracks.length) {
          if (this.props.reactPlayer.shuffleList[0] === this.props.reactPlayer.playIndex && inc === 1 && this.props.reactPlayer.tracks.length !== 1) {
            this.props.set_current_track_index(this.props.reactPlayer.shuffleList[1])
            this.props.set_play_prev_next_track(1)
          } else {
            this.props.set_current_track_index(this.props.reactPlayer.shuffleList[0])
            this.props.set_play_prev_next_track(0)
          }
        }
        else {
          if (inc === 1) {
            if (this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex + 1] === this.props.reactPlayer.playIndex) {
              if (this.props.reactPlayer.playIndex + 2 === this.props.reactPlayer.tracks.length) {
                this.props.set_current_track_index(this.props.reactPlayer.shuffleList[1])
                this.props.set_play_prev_next_track(1)
              } else {
                this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex + 2])
                this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex + 2)
              }
            } else {
              this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex + 1])
              this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex + 1)
            }
          } else {
            if (this.props.reactPlayer.playIndex + 1 === this.props.reactPlayer.tracks.length) {
              this.props.set_play_prev_next_track(0)
              this.props.set_current_track_index(this.props.reactPlayer.shuffleList[0])
            } else {
              this.props.set_current_track_index(this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex + 1])
              this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex + 1)
            }
          }
        }
      } else {
        if (this.props.reactPlayer.playIndex + 1 === this.props.reactPlayer.tracks.length) {
          this.props.set_play_prev_next_track(0)
          this.props.set_current_track_index(0)
        }
        else {
          this.props.set_current_track_index(this.props.reactPlayer.playIndex + 1)
          this.props.set_play_prev_next_track(this.props.reactPlayer.playIndex + 1)
        }
      }
    } else {
      this.player.seekTo(parseFloat(0))
      this.props.set_current_track_index(this.props.reactPlayer.playIndex)
    }
    this.player.seekTo(parseFloat(0))
  }

  //repeat logic button function
  repeatLogicButton = () => {
    if (this.props.reactPlayer.repeat === false && this.props.reactPlayer.oneTimePlay === false) {
      this.props.set_repeat(!this.props.reactPlayer.repeat)
    } else if (this.props.reactPlayer.repeat === true && this.props.reactPlayer.oneTimePlay === false) {
      this.props.set_repeat(!this.props.reactPlayer.repeat)
      this.props.set_one_time_play(!this.props.reactPlayer.oneTimePlay)
    } else if (this.props.reactPlayer.repeat === false && this.props.reactPlayer.oneTimePlay === true) {
      this.props.set_one_time_play(!this.props.reactPlayer.oneTimePlay)
    }
  }
  render() {
    if (this.props.reactPlayer.seekTo) {
      try {
        this.player.seekTo(parseFloat(0))
        this.props.seek_to(false)
      } catch (e) {
        this.props.seek_to(false)
      }
    }
    if (this.props.reactPlayer.redirect && this.state.changepage) {
      this.setState({
        changepage: false
      })
      this.props.history.push(this.props.reactPlayer.lastPage);
    }

    let showDuration = "0:00";
    let showPlayedDuration = "0:00";

    if (this.props.reactPlayer.duration && this.props.reactPlayer.duration !== "0") {
      let duration = parseInt(this.props.reactPlayer.duration);
      let minutes = Math.floor(duration / 60);
      let seconds = duration - (minutes * 60);
      if (seconds.toString().length == 1) {
        seconds = "0" + seconds
      }
      showDuration = minutes + ":" + seconds;
    }


    if (this.props.reactPlayer.playedProgressSeconds && this.props.reactPlayer.playedProgressSeconds !== "0") {
      let playedDuration = parseInt(this.props.reactPlayer.playedProgressSeconds);
      let playedMinutes = Math.floor(playedDuration / 60);
      let playedSeconds = playedDuration - (playedMinutes * 60);
      if (playedSeconds.toString().length == 1) {
        playedSeconds = "0" + playedSeconds
      }
      showPlayedDuration = playedMinutes + ":" + playedSeconds;
    }

    const { classes } = this.props;
    return (
      <>
        {/* music player  */}
        <Grid container className={this.props.screenSaver.screenSaverOn ? "blurs-effect" : "no-blurs-effect"}>
          <Grid item xs={12}>
            {this.props.reactPlayer.ads !== undefined && this.props.reactPlayer.ads.length > 0 && this.props.reactPlayer.show_ads === true ?
              <ReactPlayer
                url={this.props.reactPlayer.ads[this.props.reactPlayer.adsIndex].file_location}
                height={0}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}
                ref={this.ref}
                onProgress={(played) => {
                  this.onProgressAdsPlayer(played)
                }}
                onEnded={() => {
                  this.onEndedAdsPlayer()
                }}
                onDuration={(e) => {
                  this.props.set_play_duration(e) 
                }}
                playing={this.props.reactPlayer.playButton}
                volume={this.props.reactPlayer.volume}
              /> :
              <ReactPlayer
                url={this.state.selectedToPlay}
                height={0}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}
                ref={this.ref}
                onProgress={(played) => {
                  this.onProgressMusicPlayer(played)
                }}
                loop={this.props.reactPlayer.repeat}
                onEnded={() => {
                  this.onEndedMusicPlayer()
                }}
                onDuration={(e) => { this.props.set_play_duration(e) }}
                playing={this.props.reactPlayer.playButton}
                volume={this.props.reactPlayer.volume}
              />}
            <Slider
              value={this.props.reactPlayer.playedSeconds}
              onChange={(e, v) => {
                this.player.seekTo(parseFloat(v / 100))
              }}
              classes={{
                rail: classes.rail,
                thumb: classes.thumb,
                track: classes.track,
                active: classes.active,
                root: classes.root
              }}
              aria-labelledby="continuous-slider"
            />
          </Grid>
        </Grid>
        <Grid container className={this.props.screenSaver.screenSaverOn ? "blurs-effect-player" : ""}>
          <Grid item xs={1}>
            <span style={{ display: "inline-block", marginLeft: 10 }}>
              {showPlayedDuration}
            </span>
          </Grid>
          <Grid item style={{ textAlign: "end" }} xs={11}>
            <span style={{ display: "inline-block", marginRight: 10 }}>{showDuration}</span>
          </Grid>
        </Grid>
        <Grid container className={this.props.screenSaver.screenSaverOn ? "blurs-effect-player" : ""}>
          <Grid item xs={1} sm={1}>
            <Grid container>
              <Grid item xs={8} className="shuffle-cont">
                {" "}
                <IconButton
                  onClick={() => {
                    this.shuffleButton()
                  }}
                  className={this.props.screenSaver.screenSaverOn ? "music-icon-button-blur" : "music-icon-button"}>
                  <Icon style={{ color: this.props.reactPlayer.shuffle === false ? '#888' : '#f6842d' }}>shuffle</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} sm={2}>
            <Grid container>
              <Grid className="prev-cont" item xs={3}>
                {this.props.reactPlayer.ads !== undefined && this.props.reactPlayer.ads.length > 0 && this.props.reactPlayer.show_ads === true ?
                  <IconButton disabled={true} className={classes.Btns}>
                    <Icon>skip_previous</Icon>
                  </IconButton> :
                  <IconButton disabled={this.props.reactPlayer.playIndex > -1 ? false : true} onClick={(e) => {
                    this.previousButtonPlayer(e)
                  }}
                    className={this.state.onClickButtonPrev === false ? classes.Btns : classes.Btns1}>
                    <Icon >skip_previous</Icon>
                  </IconButton>}
              </Grid>
              <Grid className="prev-cont" item xs={3}>
                <IconButton onClick={() => { this.props.set_play_button(!this.props.reactPlayer.playButton) }} className="music-icon-button">
                  {this.props.reactPlayer.playButton ?
                    <Icon style={{ color: "#f6842d" }}>pause</Icon> :
                    <Icon>play_arrow</Icon>
                  }
                </IconButton>
              </Grid>
              <Grid className="prev-cont" item xs={3}>
                {this.props.reactPlayer.ads !== undefined && this.props.reactPlayer.ads.length > 0 && this.props.reactPlayer.show_ads === true ?
                  <IconButton disabled={true} className={classes.Btns}>
                    <Icon>skip_next</Icon>
                  </IconButton> :
                  <IconButton disabled={this.props.reactPlayer.playIndex < this.props.reactPlayer.tracks.length ? false : true} onClick={() => {
                    this.forwardButtonPlayer()
                  }}
                    className={this.state.onClickButtonNext === false ? classes.Btns : classes.Btns1}>
                    <Icon>skip_next</Icon>
                  </IconButton>}
              </Grid>
              <Grid className="prev-cont" item xs={3}>
                <IconButton onClick={() => {
                  this.repeatLogicButton()
                }} className="music-icon-button">
                  {!this.props.reactPlayer.repeat && !this.props.reactPlayer.oneTimePlay ?
                    <Icon style={{ color: '#f6842d' }}>repeat</Icon> : this.props.reactPlayer.repeat && !this.props.reactPlayer.oneTimePlay ? <Icon style={{ color: '#f6842d' }}>repeat_one</Icon> : <Icon style={{ color: '#888' }}>repeat</Icon>
                  }
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {this.props.reactPlayer.ads !== undefined && this.props.reactPlayer.ads.length > 0 && this.props.reactPlayer.show_ads === true ?
            <Grid container item xs={6} sm={4}>
              <Grid className="hash-num-cont" item xs={2}>
              </Grid>
              <Grid item className="play-image-cont" xs={3}>
                <img
                  alt="logo"
                  width={55}
                  height={55}
                  src={logo === null ? beingGood : logo}
                />
              </Grid>
              <Grid item className="play-name-cont" xs={7}>
                <Grid item={12}>
                  <b>{this.props.reactPlayer.ads[this.props.reactPlayer.adsIndex].title.toUpperCase()}</b>
                </Grid>
              </Grid>
            </Grid> :
            <Grid container item xs={6} sm={4}>
              <Grid className="hash-num-cont" item xs={2}>
                <b>#{this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] + 1 : this.props.reactPlayer.playIndex + 1}</b>
              </Grid>
              <Grid item className="play-image-cont" xs={3}>
                <img
                  alt="logo"
                  width={55}
                  height={55}
                  src={this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.album_art_image === null ? beingGood : this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.album_art_image : this.props.reactPlayer.tracks.length > 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.album_art_image === null ? beingGood : this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.album_art_image}
                />
              </Grid>
              <Grid item className="play-name-cont" xs={7}>
                <Grid item={12}>
                  <b>{this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.title.toUpperCase() : this.props.reactPlayer.tracks.length > 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.title.toUpperCase()}</b>
                </Grid>
                <Grid item={12}>
                  <span>{this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.tracks[this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex]].track.artist.toUpperCase() : this.props.reactPlayer.tracks.length > 0 && this.props.reactPlayer.tracks[this.props.reactPlayer.playIndex].track.artist.toUpperCase()}</span>
                </Grid>
              </Grid>
            </Grid>}
          <Grid className="locate-cont" item xs={6} sm={2}>
            <Button disabled={this.props.reactPlayer.show_ads} onClick={async () => {
              await this.props.set_redirect(true)
              this.setState({
                changepage: true
              })
            }} variant="outlined" className="locate-btn">
              LOCATE
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Grid container>
              <Grid item className="vol-up" xs={2}>
                {this.props.reactPlayer.volume * 100 > 50 ?
                  <Icon>volume_up</Icon> : this.props.reactPlayer.volume * 100 !== 0 ? <Icon>volume_down</Icon> : <Icon>volume_off</Icon>
                }
              </Grid>
              <Grid item className="vol-mod" xs={8}>
                <Slider
                  value={this.props.reactPlayer.volume * 100}
                  onChange={(e, v) => { this.props.set_volume(v / 100) }}
                  classes={{
                    rail: classes.rail1,
                    thumb: classes.thumb1,
                    track: classes.track1,
                    active: classes.active1,
                    root: classes.root1
                  }}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(ReactPlayers));
