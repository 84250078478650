import React, { Component } from "react";
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button
} from "@material-ui/core";
import beingGood from "../../styles/images/login/beingGoodLog.png";
import "./style.css";
import { Link } from "react-router-dom";
import HeaderSideNav from "./headerSideNav";
import BodySideNav from "./bodySideNav";
import ButtonSideNav from "./sideNavButton";
class SideNav extends Component {
  logoutButton = () => {
    this.props.login !== undefined ?
      this.props.getLogOut(this.props.login.token) :
      this.props.prop.getLogOut(this.props.prop.login.token)
  }
  render() {
    return (
      <>
        <Paper
          className={this.props.screenSaver.screenSaverOn ? "blur-effect" : "no-blur-effect"}
        >
          <Grid container className="logo-container" >
            <Grid item xs={4} md={3} style={{ paddingLeft: '5px', marginBottom: 20 }}>
              <a rel="noopener noreferrer" href="https://www.beinggoodmusic.com/" target="_blank">
                <img
                  alt="logo"
                  style={{ height: 85, marginTop: 5 }}
                  src={beingGood}
                />
              </a>
            </Grid>
            <Grid item className="logo-container-text" xs={8} md={9}>
              <HeaderSideNav position={this.props.position} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button className="button-logout" onClick={() => {
                this.logoutButton()
              }}  >logout</Button>
            </Grid>
          </Grid>
          <Grid
            container
            className={
              this.props.position === 0 || this.props.position === 2
                ? "radio-container"
                : "radio-container-create-edit"
            }
          >
            <Grid
              className={
                this.props.position === 0 || this.props.position === 2
                  ? ""
                  : "radio-button-cont"
              }
              item
              xs={3}
            >
              <Grid item className="radio-cont" xs={12}>
                <List component="nav" aria-label="main mailbox folders">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <ListItem button style={{ padding: 0 }}>
                      <ListItemText
                        primary={
                          <Grid justify="center" container >
                            <span
                              className={
                                this.props.position === 0
                                  ? "radio-button-outer-selected"
                                  : "radio-button-outer-unselected"
                              }
                            >
                            </span>
                          </Grid>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              </Grid>
              <Grid container justify="center" >
                <span
                  style={{ borderLeft: "2px solid white", paddingTop: "100%" }}
                ></span>
              </Grid>
              <Grid item className="radio-cont" xs={12}>
                <List component="nav" aria-label="main mailbox folders">
                  <Link to="create_play" style={{ textDecoration: "none" }}>
                    <ListItem button style={{ padding: 0 }}>
                      <ListItemText
                        primary={
                          <Grid justify="center" container >
                            <span
                              className={
                                this.props.position === 1
                                  ? "radio-button-outer-selected"
                                  : "radio-button-outer-unselected"
                              }
                            ></span>
                          </Grid>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              </Grid>
              <Grid container justify="center" >
                <span
                  style={{ borderLeft: "2px solid white", paddingTop: "100%" }}
                ></span>
              </Grid>
              <Grid item className="radio-cont" xs={12}>
                <List component="nav" aria-label="main mailbox folders">
                  <Link to="view_play" style={{ textDecoration: "none" }}>
                    <ListItem button style={{ padding: 0 }}>
                      <ListItemText
                        primary={
                          <Grid justify="center" container >
                            <span
                              className={
                                this.props.position === 2
                                  ? "radio-button-outer-selected"
                                  : "radio-button-outer-unselected"
                              }
                            ></span>
                          </Grid>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              </Grid>
              <Grid container justify="center" >
                <span
                  style={{ borderLeft: "2px solid white", paddingTop: "100%" }}
                ></span>
              </Grid>
              <Grid item className="radio-cont" xs={12}>
                <List component="nav" aria-label="main mailbox folders">
                  <Link to="edit_play" style={{ textDecoration: "none" }}>
                    <ListItem button style={{ padding: 0 }}>
                      <ListItemText
                        primary={
                          <Grid justify="center" container >
                            <span
                              className={
                                this.props.position === 3
                                  ? "radio-button-outer-selected"
                                  : "radio-button-outer-unselected"
                              }
                            ></span>
                          </Grid>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              </Grid>
            </Grid>
            <Grid
              className={
                this.props.position === 0
                  ? ""
                  : "mood-secondary-filter-container"
              }
              item
              xs={9}
            >

              <BodySideNav
                sidebarprop={this.props}
                {...this.props}
              />
            </Grid>
            <Grid container item xs={12}>
              <ButtonSideNav sideNavData={this.props} position={this.props.position} />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default SideNav;
