import React, { Component } from "react";
import { connect } from "react-redux";
import LoginController from "../../components/router/controller";
import {
  set_Login
} from "../../actions/login/loginActions";
import {
  set_login_home_con
} from "../../actions/security/securityHomeAction";

export class Controller_con extends Component {
  render() {
    return (
      <LoginController {...this.props} />
    );
  }
};



export const mapStateToProps = store => {
  return {
   login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
    return {
      set_Login: (email,token) => {
        dispatch(set_Login(email,token));
      },
      set_login_home_con: () => {
        dispatch(set_login_home_con());
      }
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Controller_con);