import React, { Component } from "react";
import $ from "jquery";
import './style.css';
import { Card, CardContent, Grid, Dialog, Button } from '@material-ui/core'
export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      positionX: 0,
      positionY: 0,
      screenSaver: false,
      openDialog: false,
      resumePlaying: false,
      openDialogAfterPausingSongCompleted: false
    };
  }

  componentDidMount() {
    this.props.get_ads(this.props.login.token)
    try {
      if (this.props.login.screenSaver.time > 0) {
        $(document).mousemove((event) => {
          this.setState({
            positionX: event.pageX,
            positionY: event.pageY,
            screenSaver: false
          })
          this.props.set_screensaver_on(false)
          setTimeout(
            () => {
              if (this.state.positionX === event.pageX && this.state.positionY === event.pageY && this.props.login.isLoggedIn && window.location.pathname === '/view_play') {
                this.props.set_screensaver_on(true)
                this.setState({ screenSaver: true }, () => {
                  // this.animateDiv(); 
                })
              }
            },
            this.props.login.screenSaver.time
          );
        });
      }
    } catch (e) {

    }
    if (this.props.login.timeout > 0) {
      this.showReminderDialog()
    }
  }

  showReminderDialog = () => {
    setTimeout(
      () => {
        if (this.props.login.isLoggedIn && this.props.reactPlayer.playButton === true) {
          this.setState({
            openDialog: true,
            resumePlaying: false
          }, () => {
            this.setPausePlayerAfterDialog()
          })
        } else {
          this.showReminderDialog()
        }
      },
      this.props.login.timeout
    );
  }
  setPausePlayerAfterDialog = () => {
    setTimeout(
      () => {
        if (this.props.login.isLoggedIn && this.state.resumePlaying === false) {
          this.setState({
            openDialog: !this.state.openDialog,
            openDialogAfterPausingSongCompleted: true
          })
          this.props.set_play_button(false)
          this.showReminderDialog()
        } else {
          this.showReminderDialog()
        }
      },
      900000
    );
  }
  // makeNewPosition = () => {

  //   // Get viewport dimensions (remove the dimension of the div)
  //   var h = $(window).height() - 350;
  //   var w = $(window).width() - 350;

  //   var nh = Math.floor(Math.random() * h);
  //   var nw = Math.floor(Math.random() * w);
  //   return [nh, nw];

  // }

  // animateDiv = async () => {
  //   if (this.state.screenSaver) {
  //     var newq = this.makeNewPosition();
  //     var oldq = $('.a').offset();
  //     var speed = this.calcSpeed([oldq.top, oldq.left], newq);
  //     $('.a').animate({ top: newq[0], left: newq[1] }, speed, () => {
  //       this.animateDiv()
  //     });
  //   }
  // }

  // calcSpeed = (prev, next) => {
  //   var x = Math.abs(prev[1] - next[1]);
  //   var y = Math.abs(prev[0] - next[0]);
  //   var greatest = x > y ? x : y;
  //   var speedModifier = 0.05;
  //   var speed = Math.ceil(greatest / speedModifier);
  //   return 1500;
  // }

  continueResumeSong = () => {
    this.setState({
      resumePlaying: !this.state.resumePlaying,
      openDialog: !this.state.openDialog
    })
    this.showReminderDialog()
  }
  render() {
    return (
      <>
        {this.state.screenSaver &&
          <div className="x">
            <Card className='y'>
              <CardContent>
                <Grid container justify="center">
                  <Grid item xs={10} style={{ marginTop: 10 }}>
                    <img alt="screen" style={{ width: '100%' }} src={this.props.login.screenSaver.image} />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={11} style={{ marginTop: 20, textAlign: "center" }}>
                    <span style={{ fontWeight: 'bold', fontSize: 17 }}>{this.props.login.screenSaver.text}</span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>

        }
        {/* INACTIVITY REMINDER DIALOG */}
        <Dialog
          open={this.state.openDialog}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container >
            <Grid item xs={12} className="closeButtonContainer">
            </Grid>
            <Grid item xs={12} className="loginHeaderContainer">
              <span className="logintext">Reminder</span>
            </Grid>
            <Grid item xs={12} className="passwordContainer">
              <span className="passwordResetText">Your Song will be paused after 15 minutes. Press ok to resume playing</span>
            </Grid>
            <Grid container className="button-cont">
              <Grid item xs={10} className="ok-container">
                <Button
                  onClick={this.continueResumeSong}
                  variant="outlined"
                  className="cancel-button"
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>

        {/* SONG PAUSED DIALOG */}
        <Dialog
          open={this.state.openDialogAfterPausingSongCompleted}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container >
            <Grid item xs={12} className="closeButtonContainer">
            </Grid>
            <Grid item xs={12} className="loginHeaderContainer">
              <span className="logintext">Reminder</span>
            </Grid>
            <Grid item xs={12} className="passwordContainer">
              <span className="passwordResetText">Your Song has been paused . Press play button to resume playing</span>
            </Grid>
            <Grid container className="button-cont">
              <Grid item xs={10} className="ok-container">
                <Button
                  onClick={() => { this.setState({ openDialogAfterPausingSongCompleted: false }) }}
                  variant="outlined"
                  className="cancel-button"
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </>
    );
  }
}
