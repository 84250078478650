import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import beingGood from "../../../../styles/images/album/bg-placeholder-logo.svg";
import {
  Grid,
  Icon,
  IconButton,
  ListItem,
  List,
  ListItemAvatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import "./style.css";
import { withStyles } from "@material-ui/core"
import { ListItemText } from "@material-ui/core";
const styles = (theme) => ({
  list: {
    paddingLeft: '10%',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '5%'
    }
  },
});

class Tables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      expanded: "",
      lastplay: '',
      currentPlay: '',
      currentCollection: ''
    };
  }
  addShuffle = async (index, track, playlistIndex) => {
    var newShuffleArray = [], shuffleLen = track.length - 1, maxShuffleNum = track.length - 1, shuffle;
    while (newShuffleArray.length <= shuffleLen) {
      shuffle = Math.round(Math.random() * maxShuffleNum);
      if (newShuffleArray.indexOf(shuffle) === -1) {
        newShuffleArray.push(shuffle);
      }
    }
    var newIndex = newShuffleArray.indexOf(index)
    await this.props.prop.set_track_shuffle(track, newIndex, newShuffleArray)
    await this.props.prop.set_play_button(true)
    await this.props.prop.set_current_playlist(playlistIndex)
    await this.props.prop.seek_to(true);
  }
  componentDidMount() {
    if (this.props.prop.reactPlayer.redirect) {
      this.props.prop.set_redirect(false)
    }
    if (this.props.prop.reactPlayer.redirect) {
      this.props.prop.set_redirect(false)
      try {
        var elmnt = document.getElementById(this.props.prop.reactPlayer.lastPlayedId);
        elmnt.scrollIntoView();
      } catch (e) {

      }
    }
  }
  playPauseButton = async (m, ind, i) => {
    if (localStorage.getItem('playlist') !== 'collection') {
      this.props.prop.set_play_page('/', this.props.prop.reactPlayer.currentPage)
      localStorage.setItem("playlist", 'collection')
      if (this.props.prop.reactPlayer.shuffle === true) {
        this.addShuffle(ind, m.track, m._id)
      } else {
        this.props.prop.set_track(m.track)
        this.props.prop.set_play_button(true)
        this.props.prop.set_play_prev_next_track(ind);
        this.props.prop.set_current_playlist(m._id)
        this.props.prop.seek_to(true)
      }
    } else if (localStorage.getItem('playlist') === 'collection') {
      if (this.props.prop.viewCollection.currentPlaylist !== i && this.props.prop.reactPlayer.tracks.length !== 0) {
        if (this.props.prop.reactPlayer.shuffle === true) {
          this.addShuffle(ind, m.track, m._id)

        } else {
          this.props.prop.set_play_prev_next_track(ind);
          this.props.prop.set_track(m.track)
          if (m._id !== this.props.prop.viewCollection.currentPlaylist || ind !== this.props.prop.reactPlayer.playIndex) {
            this.props.prop.set_play_button(true)
            this.props.prop.seek_to(true)
          } else {
            this.props.prop.set_play_button(!this.props.prop.reactPlayer.playButton)
          }
          this.props.prop.set_current_playlist(m._id)
        }
      } else {
        if (this.props.prop.reactPlayer.tracks.length === 0) {
          this.props.prop.set_play_prev_next_track(ind);
          this.props.prop.set_track(m.track)
          this.props.prop.set_play_button(true)
          this.props.prop.set_current_playlist(m._id)
        } else {
          if (this.props.prop.reactPlayer.shuffle === true) {
            if (this.props.prop.reactPlayer.shuffleList.indexOf(this.props.prop.reactPlayer.playIndex) === ind) {
              if (this.props.prop.reactPlayer.playButton) {
                this.props.prop.set_play_button(false)
              } else {
                this.props.prop.set_play_button(true)
              }
            } else {
              this.props.prop.set_play_button(true)
              this.props.prop.set_play_prev_next_track(this.props.prop.reactPlayer.shuffleList.indexOf(ind));
            }
          } else {
            if (this.props.prop.reactPlayer.playIndex === ind) {
              if (this.props.prop.reactPlayer.playButton) {
                this.props.prop.set_play_button(false)
              } else {
                this.props.prop.set_play_button(true)
              }
            } else {
              this.props.prop.set_play_button(true)
              this.props.prop.set_play_prev_next_track(ind);
            }
          }
        }
      }
    }
    if (this.state.currentPlay !== ind && this.props.prop.reactPlayer.show_ads === false) {
      this.props.prop.set_music_index(this.props.prop.reactPlayer.musicIndex + 1)
      this.setState({
        currentPlay: ind,
      })
    }
    if (this.props.prop.reactPlayer.musicIndex % this.props.prop.reactPlayer.ads_track_limit === 0) {
      this.props.prop.show_ads(true)

    }
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.data.length === 0 ?
          <Grid container justify='center'>
            <Grid item xs={6} style={{ paddingTop: 150 }}>
              <span style={{ fontSize: 25 }}>
                NO COLLECTION FOUND PLEASE IMPORT COLLECTION
              </span>
            </Grid>
          </Grid>
          : 
          this.props.data.map((m, i) => {
            return (
              <Paper key={i}>
                <Accordion
                  expanded={this.props.prop.viewCollection.expanded === i}
                  square={true}
                  onChange={() => {
                    if (this.props.prop.viewCollection.expanded === i) {
                      this.props.prop.set_expanded('')
                    } else {
                      this.props.prop.set_expanded(i)
                    }
                  }}
                >
                  <AccordionSummary
                    style={{ padding: 0, background: '#ededed' }}
                    aria-controls="panel1bh-content"
                  >
                    <Grid container>
                      <Grid item container className={classes.list} xs={11} md={9}>
                        <Grid item xs={5}>
                          <List style={{ padding: 0 }}>
                            <ListItemText primary={
                              <span className='other-info-span'>{m.playlist_name.toUpperCase()}</span>
                            }
                              secondary={
                                <span>
                                  {(m.description !== null && m.description !== '' && m.description !== undefined) &&
                                    (m.description.length > 63 ? m.description.substring(0, 63).toUpperCase() : m.description.toUpperCase())}
                                </span>
                              }
                            />
                          </List>
                        </Grid>
                      </Grid>
                      <Grid item style={{ paddingRight: '2%' }} container justify='flex-end' xs={1} md={3}>
                        <>{
                          this.props.prop.viewCollection.expanded === i ?
                            <span>
                              <span className='arrow-up-button'>
                                <Icon style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>arrow_upward</Icon>
                              </span>
                            </span> :
                            <span>
                              <span className='arrow-up-button'>
                                <Icon style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>arrow_downward</Icon>
                              </span>
                            </span>
                        }</>

                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={7}></TableCell>
                            <TableCell colSpan={2} align="center" className="mood-cell">
                              MOOD(S)
                            </TableCell>
                            <TableCell colSpan={1} align="center" className="mood-cell">
                              RTP
                            </TableCell>
                            <TableCell colSpan={1} align="center" className="mood-cell">
                              DECADE
                            </TableCell>
                            <TableCell colSpan={1} align="center" className="mood-cell">
                              DURATION
                            </TableCell>
                          </TableRow>
                          {m.track.map((mi, ind) => {
                            if (this.state.lastplay !== ind && this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection') {
                              this.setState({
                                lastplay: ind
                              }, function () {
                                this.props.prop.set_play_page('/', '/')
                              })
                              this.props.prop.set_last_play_id(mi._id + ind + i)
                            }
                            let showDuration = "0:00";

                            let duration = parseInt(mi.duration);
                            let minutes = Math.floor(duration / 60);
                            let seconds = duration - (minutes * 60);

                            if (seconds.toString().length == 1) {
                              seconds = "0" + seconds
                            }
                            showDuration = minutes + ":" + seconds;

                            return (
                              <TableRow key={ind + i}>
                                <TableCell className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"} colSpan={2}>
                                  <Grid item xs={12} container>
                                    <Grid item xs={6}>
                                      <span id={mi._id + ind + i} style={{ position: 'relative', top: 23, fontWeight: 700, fontSize: 15 }}>
                                        {ind + 1}
                                      </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <span>
                                        <IconButton
                                          onClick={async () => {
                                            await this.playPauseButton(m, ind, i)
                                          }}
                                          disableTouchRipple
                                        >
                                          {localStorage.getItem('playlist') === 'collection' ?
                                            this.props.prop.reactPlayer.shuffle === true && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.shuffleIncrementer === 1 ? this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.reactPlayer.playButton === true ?
                                              <Icon style={{ color: "#f6842d" }}>
                                                pause
                                              </Icon> :
                                              <Icon style={{ color: 'black' }}>
                                                play_arrow
                                              </Icon>
                                              :
                                              this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true ? <Icon style={{ color: "#f6842d" }}>
                                                pause
                                              </Icon>
                                                :
                                                <Icon style={{ color: 'black' }}>
                                                  play_arrow
                                                </Icon>
                                            :
                                            <Icon style={{ color: 'black' }}>
                                              play_arrow
                                            </Icon>
                                          }
                                        </IconButton>
                                      </span>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell  className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"} colSpan={5}>
                                  <List style={{ display: "inline-block" }}>
                                    <ListItem style={{ padding: 0 }}>
                                      <ListItemAvatar>
                                        <img
                                          alt="album"
                                          src={mi.album_art_image === null ? beingGood : mi.album_art_image}
                                          style={{ width: 50, height: 50 }}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={<b>{mi.title.toUpperCase()}</b>}
                                        secondary={
                                          <span style={{ color: "black" }}>{mi.artist.toUpperCase()}</span>
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                </TableCell>
                                <TableCell
                                  className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"}
                                  align="center"
                                  colSpan={2}
                                >
                                  <span className="table-text">{mi.rtp_details[0].Mood.toUpperCase()}</span>
                                </TableCell>
                                <TableCell
                                   className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"}
                                  align="center"
                                  colSpan={1}
                                >
                                  <span className="table-text">{mi.rtp_details[0].R},{mi.rtp_details[0].T},{mi.rtp_details[0].P}</span>
                                </TableCell>
                                <TableCell
                                  className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"}
                                  align="center"
                                  colSpan={1}
                                >
                                  <span className="table-text">{mi.year === 0 ? "NA" : mi.year}</span>
                                </TableCell>
                                <TableCell
                                  className={this.props.prop.reactPlayer.shuffle === false ? this.props.prop.reactPlayer.playIndex === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist" : this.props.prop.reactPlayer.shuffleList[this.props.prop.reactPlayer.playIndex] === ind && this.props.prop.viewCollection.currentPlaylist === m._id && this.props.prop.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'collection' ?"selected-table-cell-playlist":"table-cell-playlist"}
                                  align="center"
                                  colSpan={1}
                                >
                                  <span className="table-text">{showDuration}</span>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </AccordionDetails>
                </Accordion>
              </Paper>
            )
          })}
      </>
    );
  }
}
export default withStyles(styles)(Tables);