import React, { Component } from "react";
import {
  Grid} from "@material-ui/core";
import "../../styles/login.css";
import ResetPasswordDialog from "./components/resetPasswordDialog";

export default class ResetPassword extends Component {

  render() {
    return (
      <Grid container justify="center" className="login-background">
        <ResetPasswordDialog 
          changePassword={this.props.changePassword}
          path={this.props.location.pathname}
          valid={this.props.login.resetPasswordValid}
          verifyChangePassword={this.props.verifyChangePassword}
        />
      </Grid>
    );
  }
}