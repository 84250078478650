import React, { Component } from "react";
import { connect } from "react-redux";
import Appbar from "../../components/appbar/appbar";
import { getLogOut } from "../../actions/login/loginActions";
import { set_show_mood, set_show_mood_filter, show, set_show_mood_artist,create_playlist,set_reset, on_unmount_set_none_for_mobile_screen} from "../../actions/playlist/createPlaylistAction"
import { get_playlist, set_selected_to_play_playlist, get_tracks_from_playlist,get_tracks_from_playlist_shift, delete_event, set_playlist, delete_event_selected_to_play, set_filter_playlist_tracks, get_tracks_from_playlist_arr_unselected} from "../../actions/playlist/viewMusciPlaylistAction"
import { set_play_prev_next_track, set_play_progress, set_play_button, set_shuffle_list, set_shuffle_incrementer, set_shuffle, seek_to } from "../../actions/react player/reactPlayerAction";
export class AppbarCon extends Component {
  render() {
    return (
      <Appbar {...this.props} />
    );
  }
};



export const mapStateToProps = store => {
  return {
    login: store.login,
    viewPlay: store.viewPlay,
    reactPlayer: store.reactPlayer,
    createPlay: store.createPlay,
    screenSaver:store.screenSaver,
    sideNav:store.sideNav
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_shuffle_incrementer: (payload) => {
      dispatch(set_shuffle_incrementer(payload));
    },
    seek_to: (payload) => {
      dispatch(seek_to(payload));
    },
    set_shuffle: (payload) => {
      dispatch(set_shuffle(payload));
    },
    set_shuffle_list: (payload) => {
      dispatch(set_shuffle_list(payload));
    },
    getLogOut: (token) => {
      dispatch(getLogOut(token));
    },
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    },
    set_play_progress: (payload) => {
      dispatch(set_play_progress(payload));
    },
    set_play_prev_next_track: (payload) => {
      dispatch(set_play_prev_next_track(payload));
    },
    set_filter_playlist_tracks: (payload) => {
      dispatch(set_filter_playlist_tracks(payload));
    },
    set_show_mood: (token, moodsDetail) => {
      dispatch(set_show_mood(token, moodsDetail));
    }, 
    set_show_mood_filter: (token, moodsDetail) => {
      dispatch(set_show_mood_filter(token, moodsDetail));
    },
     set_show_mood_artist: (token, moodsDetail) => {
      dispatch(set_show_mood_artist(token, moodsDetail));
    }, 
    show: (moodsDetail) => {
      dispatch(show(moodsDetail));
    },
    create_playlist: (name, playlist, token) => {
      dispatch(create_playlist(name, playlist, token));
    },
    set_selected_to_play_playlist: (payload) => {
      dispatch(set_selected_to_play_playlist(payload));
    },
    get_tracks_from_playlist: (token, playlistID) => {
      dispatch(get_tracks_from_playlist(token, playlistID));
    },
    get_tracks_from_playlist_shift: (token, playlistID,selectPlayLength,track) => {
      dispatch(get_tracks_from_playlist_shift(token, playlistID,selectPlayLength,track));
    },
    delete_event: (token, playlist_id) => {
      dispatch(delete_event(token, playlist_id));
    },
    delete_event_selected_to_play: (token, playlist_id) => {
      dispatch(delete_event_selected_to_play(token, playlist_id));
    },
    set_playlist: (payload) => {
      dispatch(set_playlist(payload));
    },
    set_reset: (payload) => {
      dispatch(set_reset(payload));
    },
    get_playlist: (token) => {
      dispatch(get_playlist(token));
    },
    get_tracks_from_playlist_arr_unselected:(token, playlistID,playIndex) => {
      dispatch(get_tracks_from_playlist_arr_unselected(token,playlistID,playIndex));
    },
    on_unmount_set_none_for_mobile_screen:(payload) => {
      dispatch(on_unmount_set_none_for_mobile_screen(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppbarCon);