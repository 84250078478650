import React, { Component } from "react";
import { connect } from "react-redux";
import ViewMusicCollection from "../../components/playlist/view collection/viewCollection"
import { set_position } from "../../actions/sideNav/sideNavAction"
import { add_playlist, remove_playlist } from "../../actions/playlist/viewMusciPlaylistAction";
import { get_collection, set_track, set_current_playlist, set_track_shuffle, view_collections, selected_collection, add_collection, remove_collection, get_collection_for_remove, set_expanded } from "../../actions/playlist/viewCollectionAction";
import { show_ads,set_music_index,set_play_prev_next_track, set_play_button, set_play_progress, seek_to, set_shuffle_list, set_play_page, set_redirect, set_last_play_id } from "../../actions/react player/reactPlayerAction"
export class ViewMusicPlaylistCont extends Component {
  render() {
    return (
      <ViewMusicCollection {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    viewCollection: store.viewCollection,
    reactPlayer: store.reactPlayer,
    createPlay: store.createPlay,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_position: (payload) => {
      dispatch(set_position(payload));
    },
    set_current_playlist: (payload) => {
      dispatch(set_current_playlist(payload));
    },
    set_track: (payload) => {
      dispatch(set_track(payload));
    },
    set_track_shuffle: (payload, index, newShuffle) => {
      dispatch(set_track_shuffle(payload, index, newShuffle));
    },
    add_playlist: (name, track, token) => {
      dispatch(add_playlist(name, track, token));
    },
    remove_playlist: (name, token) => {
      dispatch(remove_playlist(name, token));
    },
    get_collection: (token, limit, start_after) => {
      dispatch(get_collection(token, limit, start_after));
    },
    seek_to: (payload) => {
      dispatch(seek_to(payload));
    },
    set_shuffle_list: (payload) => {
      dispatch(set_shuffle_list(payload));
    },
    set_play_prev_next_track: (payload) => {
      dispatch(set_play_prev_next_track(payload));
    },
    set_play_progress: (payload) => {
      dispatch(set_play_progress(payload));
    },
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    },
    view_collections: (payload) => {
      dispatch(view_collections(payload));
    },
    selected_collection: (payload) => {
      dispatch(selected_collection(payload));
    },
    add_collection: (token, payload) => {
      dispatch(add_collection(token, payload));
    },
    remove_collection: (token, payload,limit,start_after) => {
      dispatch(remove_collection(token, payload,limit,start_after));
    },
    get_collection_for_remove: (token) => {
      dispatch(get_collection_for_remove(token));
    }, set_play_page: (current, last) => {
      dispatch(set_play_page(current, last));
    }, set_last_play_id: (last) => {
      dispatch(set_last_play_id(last));
    }, set_redirect: (last) => {
      dispatch(set_redirect(last));
    }, set_expanded: (index) => {
      dispatch(set_expanded(index));
    }, set_music_index: (data) => {
      dispatch(set_music_index(data));
    },
    show_ads: (data) => {
      dispatch(show_ads(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewMusicPlaylistCont);