import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";
import Controller_con from "../../containers/router/controller_cont";
import Login from "../../containers/login/loginCon";
import Snackbar from "../../containers/snackbar/snackbarCont";
import LoaderCon from '../../containers/loader/loader_cont'
import ViewCollectionCont from "../../containers/playlist/viewCollectionCont";
import ViewPlayPlayListCont from "../../containers/playlist/viewPlayPlaylistCont";
import EditPlayListCont from "../../containers/playlist/editPlaylistCont";
import CreatePlayListCont from "../../containers/playlist/createPlaylistCont";
import ReactPlayerCont from "../../containers/react player/reactPlayerCont";
import SideNavCont from "../../containers/sidenav/sidenavCont";
import AppbarCon from "../../containers/appbar/appbarConst";
import ScreenSaverCon from "../../containers/screensaverandtimeout/screensaverContainer";
import ResetPasswordCon from "../../containers/login/resetPasswordCon";

class Routes extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentDidMount() {
  }
  setLogoutRedirect = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("juiced-token");
    return <Redirect to='/' />
  }
  render() {
    // Routing parameters to different screen
    const { login } = this.props;
    let body;

    // home screen parameter
    if (login.isLoggedIn === false) {
      body = (
        <div>
          <main>
            <Route path="/" component={Login} />
            <Route path="/" component={Controller_con} />
            <Route path="/reset-password/:id" component={ResetPasswordCon} />
            <Snackbar />
            <LoaderCon />
          </main>
        </div>
      );
    } else {
      body = (
        <div>
          {window.location.pathname === '/view_play' && <ScreenSaverCon />}

          {/* after logging in screen */}
          <Grid container>
            <Hidden only={["xl", "lg"]} >
              <Grid container>
                <AppbarCon position={this.props.sideNav.position} />
              </Grid>
            </Hidden>
            <Hidden only={["xs", "sm", "md"]}>
              <Grid item xs={3}>
                <SideNavCont position={this.props.sideNav.position} />
              </Grid>
            </Hidden>
            <Grid item xs={12} lg={9} style={{position: "relative", zIndex: 0}}>
              <main>
                <Route path="/view_play" component={ViewPlayPlayListCont} />
                <Route exact path="/" component={ViewCollectionCont} />
                <Route path="/edit_play" component={EditPlayListCont} />
                <Route path="/create_play" component={CreatePlayListCont} />
                {this.props.reactPlayer.tracks.length > 0 && <ReactPlayerCont />}
                <Snackbar />
                <LoaderCon />
              </main>
            </Grid>
          </Grid>

        </div>
      );
    }


    return <Router>
      {/* {(login.isLoggedIn === false && login.logoutRedirect) && this.setLogoutRedirect()} */}
      {(login.isLoggedIn === false && login.isHome === true && login.newDeviceDetected) && this.setLogoutRedirect()}
      {body}
    </Router>;
  }
}

export default Routes;
