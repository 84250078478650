import React, { Component } from "react";
import "./style.css";
class HeaderSideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/* side nav top component*/}
        {this.props.position === 0 &&
          <span className="logo-container-text-span">
            Music Import & View
          </span>
        }
        {this.props.position === 1 &&
          <>
            <span className="logo-container-text-span">
              Create Playlist
            </span>
            <div className="mood-second-cont">
              <p className="mood-second-para">
                MOOD & SECONDARY FILTERS
              </p>
            </div>
          </>
        }
        {this.props.position === 2 &&
          <>
            <span className="logo-container-text-span">
              Playlists
            </span>
            <div className="mood-second-cont">
              <p className="selected-second-para">
                SELECTED TO PLAY
              </p>
              <hr style={{ marginRight: "7%" }} />
            </div>
          </>
        }
        {this.props.position === 3 &&
          <span className="logo-container-text-span">
            Edit Playlist
          </span>
        }
      </>
    );
  }
}

export default HeaderSideNav;
