import {
  ARANGE_TRACKS_UP, SELECTED_PLAYLIST_ID, SELECT_RADIO, SET_SELECTED_PLAYLIST, SET_PLAYLIST_EDIT, SET_PLAYLIST_EDIT_GET, SET_PLAYLIST_TRACKS_EDIT, SET_PLAYLIST_NAME, SET_PLAYLIST_NAME_DIALOG, CHECK, SHOW_PLAYLIST_DATA, RESET_PLAYER
} from "../../constants/playlist/playlistConst";
const initial_state = {
  playlist: [],
  selectedPlaylist: [],
  track: [],
  playlistName: '',
  selectPlayListName: [],
  selectPreviousPlayListName: [],
  check: false,
  showPlaylistData: false,
  selectedPlaylistID: '',
  selectedRadio: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_PLAYLIST_EDIT:
      return (state = {
        ...state,
        playlist: action.payload
      });
    case SELECT_RADIO:
      return (state = {
        ...state,
        selectedRadio: action.payload
      });
    case SELECTED_PLAYLIST_ID:
      return (state = {
        ...state,
        selectedPlaylistID: action.payload
      });
    case SET_PLAYLIST_EDIT_GET:
      return (state = {
        ...state,
        track: action.payload
      });
    case CHECK:
      return (state = {
        ...state,
        check: action.payload
      });
    case SET_PLAYLIST_NAME:
      return (state = {
        ...state,
        playlistName: action.payload
      });
    case SHOW_PLAYLIST_DATA:
      return (state = {
        ...state,
        showPlaylistData: action.payload
      });
    case SET_PLAYLIST_NAME_DIALOG:
      return (state = {
        ...state,
        selectPlayListName: action.payload.current,
        selectPreviousPlayListName: action.payload.previous
      });
    case SET_PLAYLIST_TRACKS_EDIT:
      return (state = {
        ...state,
        playlist: action.payload
      });
    case ARANGE_TRACKS_UP:
      return (state = {
        ...state,
        playlist: action.payload
      });
    case SET_SELECTED_PLAYLIST:
      return (state = {
        ...state,
        selectedPlaylist: action.payload
      });
    case RESET_PLAYER:
      return state = {
        ...state, playlistData: [],
        playlist: [],
        selectedPlaylist: [],
        track: [],
        playlistName: '',
        selectPlayListName: [],
        selectPreviousPlayListName: [],
        check: false,
        showPlaylistData: false,
        selectedPlaylistID: '',
        selectedRadio: '',
      };
    default:
      return state;
  }

}