import { RESET_PLAYER, SET_NONE } from "../../constants/playlist/playlistConst";
import { SET_POSITION } from "../../constants/sideNav/sideNavConst"
const initial_state = {
  position: 0,
  expand: false,
  expanded: "",
  selection: [],
  incrementer: 0,
  selectedToPlay: [],
  deleteDialog: false,
  deletePlaylistID: '',
  setDisabled: false,
  moodFilter: [],
  setPlaylist: false,
  deleteDialogSelected: false
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_POSITION:
      return (state = {
        ...state,
        position: action.payload
      });
    case SET_NONE:
      return (state = {
        ...state,
        expand: action.payload.expand,
        expanded: action.payload.expanded,
        selection: action.payload.selection,
        incrementer: action.payload.incrementer,
        selectedToPlay: action.payload.selectedToPlay,
        deleteDialog: action.payload.deleteDialog,
        deletePlaylistID: action.payload.deletePlaylistID,
        setDisabled: action.payload.setDisabled,
        moodFilter: action.payload.moodFilter,
        setPlaylist: action.payload.setPlaylist,
        deleteDialogSelected: action.payload.deleteDialogSelected
      });
    case RESET_PLAYER:
      return (state = {
        ...state,
        position: 0,
        expand: false,
        expanded: "",
        selection: [],
        incrementer: 0,
        selectedToPlay: [],
        deleteDialog: false,
        deletePlaylistID: '',
        setDisabled: false,
        moodFilter: [],
        setPlaylist: false,
        deleteDialogSelected: false
      });
    default:
      return state;
  }
}