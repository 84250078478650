import React, { Component } from "react";
import {
  Button,
} from "@material-ui/core";
import "./style.css";
import { withStyles } from "@material-ui/core";
import NameAndSavePlaylist from "../dialog box/nameAndSavePlaylistDialog";
const styles = (theme) => ({
  button: {
    background: "#f6842d",
    position:"absolute",
    borderRadius:0,
    bottom:0,
    "&:hover": {
          backgroundColor: "#f6842d"
      },
    [theme.breakpoints.down("md")]: {
      width:250
    },
    [theme.breakpoints.up("md")]: {
      width:400
    }
    ,
    [theme.breakpoints.up("lg")]: {
      width:"25%"
    }
  },
  disable_button: {
    backgroundColor:'#888',
    color:'white',
    borderRadius:0,
    position:"absolute",
    bottom:0,
    "&:hover": {
          backgroundColor: "#f6842d"
      },
    [theme.breakpoints.down("md")]: {
      width:250
    },
    [theme.breakpoints.up("md")]: {
      width:400
    }
    ,
    [theme.breakpoints.up("lg")]: {
      width:"25%"
    }
  }
});
class ButtonSideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog:false,
    
    };
  }

  componentDidMount() {}

  render() {
    
    const { classes} = this.props;
    var appbarData = this.props.sideNavData.prop
    return (
      <>
        {this.props.position === 1 &&
        <Button
        className={appbarData===undefined?this.props.sideNavData.createPlay.showMoodTracks.length > 0 ?classes.button:classes.disable_button:appbarData.createPlay.showMoodTracks.length> 0 ?classes.button:classes.disable_button}
          disabled={appbarData===undefined?this.props.sideNavData.createPlay.showMoodTracks.length > 0 ?false:true:appbarData.createPlay.showMoodTracks.length> 0 ?false:true}
          variant="outlined"
          onClick={()=>{
            this.setState({
              openDialog:true,
            })
          }}
        >
          <b className="clear-premix">NAME & SAVE PLAYLIST</b>
        </Button>
        }
        <NameAndSavePlaylist
          open={this.state.openDialog}
          close={(value) => {
            this.setState({
              openDialog: value,
            });
          }}
          sidenavprop={this.props.sideNavData}
          appbarprop={appbarData}
        />
      </>
    );
  }
}

export default withStyles(styles)(ButtonSideNav);
