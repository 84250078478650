import {
  LOGGEDIN
} from "../../constants/login/loginActionTypes";
import { SET_RTP_MOODS, SHOW, SHOW_MOOD, SHOW_MOOD_FILTER, SHOW_MOOD_ARTIST, CLEAR_SHOW_MOOD, SET_RESET_SELECT_BOX, SET_NONE } from "../../constants/playlist/playlistConst";
import { RESET_PLAYER } from "../../constants/playlist/playlistConst";
const initial_state = {
  isLoggedIn: false,
  showMood: [],
  showMoodTracks: [],
  showMoodFilter: [],
  moodsDetail: [],
  track: [],
  trackCopy: [],
  reset: false,
  none1: "None",
  none2: "None",
  none3: "None",
  none4: "None"
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case LOGGEDIN:
      return (state = {
        ...state,
        isLoggedIn: action.payload
      });
    case SET_NONE:
      return (state = {
        ...state,
        none1: action.payload.none1,
        none2: action.payload.none2,
        none3: action.payload.none3,
        none4: action.payload.none4
      });
    case RESET_PLAYER:
      return state = {
        ...state, showMood: [],
        showMoodTracks: [],
        track: []
      };
    case SET_RTP_MOODS:
      return (state = {
        ...state,
        moodsDetail: action.payload
      })
    case SHOW_MOOD:
      return (state = {
        ...state,
        showMood: action.payload.moodsDetail,
        showMoodTracks: action.payload.moodTracks !== undefined ? action.payload.moodTracks : [],
      })
    case CLEAR_SHOW_MOOD:
      return (state = {
        ...state,
        showMood: [],
        showMoodTracks: [],
        track: []
      })
    case SHOW_MOOD_FILTER:
      return (state = {
        ...state,
        showMood: action.payload.moodsDetail,
        showMoodTracks: action.payload.moodTracks !== undefined ? action.payload.moodTracks : []
      })
    case SHOW_MOOD_ARTIST:
      return (state = {
        ...state,
        track: action.payload === undefined ? [...state.track, []] : action.payload,
        trackCopy: action.payload === undefined ? [...state.track, []] : action.payload,
      })
    case SHOW:
      return (state = {
        ...state,
        showMood: action.payload.moodsDetail,
      })
    case SET_RESET_SELECT_BOX:
      return (state = {
        ...state,
        reset: action.payload,
      })
    default:
      return state;
  }

}