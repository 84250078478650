import React from "react";
import {
  Dialog,
  TextField,
  Grid,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";

export default class ForgotPasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailReg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  render() {
    const { reset_password } = this.props.prop
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={this.props.open}
        onClose={() => {
          this.props.close(false);
          this.setState({
            email: ""
          });
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container>
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
                this.props.close(false);
                this.setState({
                  email: ""
                });
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="passwordResetHeaderContainer">
            <span className="passwordResetHeader">Password Reset</span>
          </Grid>
          <Grid item xs={12} className="passwordResetContainer">
            <p className="passwordResetText">Enter your email address that you used to register. You</p>
            <p className="passwordResetText">  will receive an email with a link to reset your password.</p>
          </Grid>
          <Grid item xs={12} className="emailResetContainer">
            <TextField
              label="EMAIL@EXAMPLE.COM"
              data-test="email"
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              fullWidth
              error={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ? true : false}
              label={this.state.emailReg.test(this.state.email) === false && this.state.email !== '' ? "EMAIL@EXAMPLE.COM" : "EMAIL"}
              className="emailResetTextField"
            />
          </Grid>
          <Grid item xs={12} className="sendButtonContainer">
            <Button variant="outlined" data-test="sendButton" className="sendButton"
              disabled={
                this.state.email !== ""
                  ? false : true
              }
              onClick={() => {
                if (this.state.emailReg.test(this.state.email) === true) {
                  reset_password(
                    this.state.email
                  );
                  this.setState({
                    email: ""
                  })
                }
                else {
                  this.props.prop.invalidEmail();
                }
              }}>
              SEND
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
