import { combineReducers } from "redux";
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import login from './login/login-reducer';
import snackbar from './snackbar/snackbar_reducer';
import sideNav from './sideNav/sideNavReducer'
import createPlay from './playlist/createPlaylistReducer'
import viewPlay from './playlist/viewPlaylistReducer'
import loader from './loader/loader_reducer'
import SecurityReducer from './security/securityReducer'
import editPlay from './playlist/editPlaylistReducer'
import reactPlayer from './reactPlayer/reactPlayerReducer'
import viewCollection from './playlist/viewCollection'
import screenSaver from './screenSaver/screenSaverReducer'
const persistConfig ={
  key:'root',
  storage,
  whitelist:[
    'viewCollection',
    'reactPlayer',
    // 'editPlay',
    'sideNav',
    'login',
    'snackbar',
    'loader',
    'viewPlay'
  ]
}
const rootReducer =combineReducers({
  screenSaver,
  viewCollection,
  reactPlayer,
  editPlay,
  sideNav,
  login,
  snackbar,
  createPlay,
  loader,
  viewPlay,
  SecurityReducer
});
export default persistReducer(persistConfig,rootReducer)