import React, { Component } from "react";
import {
  Grid,
  Icon,
  Card,
  IconButton,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  withStyles
} from "@material-ui/core";
import Star from "../../router/Images/grade-24px.svg";
import beingGood from "../../../styles/images/login/beingGoodLog.png";
import "../style.css";
const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class BodySideNavViewPlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      expanded: "",
      none1: "None",
      none2: "None",
      none3: "None",
      none4: "None",
      selection: [],
      incrementer: 0,
      selectedToPlay: [],
      deleteDialog: false,
      deletePlaylistID: '',
      setDisabled: false,
      moodFilter: [],
      setPlaylist: false,
      deleteDialogSelected: false
    };
  }

  componentDidMount() { }

  //mobile screen funtionality
  selectedToPlayStarButtonMob = (index, appbarData) => {
    var selectedPlaylist = appbarData.viewPlay.selectedToPlay
    var allPlaylist = this.props.data
    var filterSelectedPlaylist = selectedPlaylist.splice(index, 1);
    let play = allPlaylist.filter(val => val.playlist_id === filterSelectedPlaylist[0].playlist_id)
    if (play.length === 0) {
      allPlaylist.push(filterSelectedPlaylist[0])
    }
    appbarData.set_play_prev_next_track(0);
    appbarData.seek_to(true);
    appbarData.set_play_prev_next_track(0);
    appbarData.set_play_progress(0);
    if (appbarData.viewPlay.selectedToPlay.length === 0) {
      appbarData.set_shuffle(false)
      appbarData.set_shuffle_incrementer(0)
      appbarData.set_shuffle_list([])
      appbarData.set_filter_playlist_tracks([])
      appbarData.set_selected_to_play_playlist(selectedPlaylist)
      appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
    } else {
      appbarData.set_playlist(allPlaylist)
      appbarData.set_selected_to_play_playlist(selectedPlaylist)
      appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
    }
    appbarData.viewPlay.tracks.map((d, index) => {
      if (d.playlist_id === filterSelectedPlaylist[0].playlist_id) {
        appbarData.viewPlay.tracks.splice(index, filterSelectedPlaylist[0].tracks)
        if (appbarData.reactPlayer.shuffle === true) {
          var newShuffleArrays = appbarData.reactPlayer.shuffleList.filter(m => m < appbarData.viewPlay.tracks.length)
          var newShuffleArray = [], shuffleLen = newShuffleArrays.length - 1, maxShuffleNum = newShuffleArrays.length - 1, shuffle;
          while (newShuffleArray.length <= shuffleLen) {
            shuffle = Math.round(Math.random() * maxShuffleNum);
            if (newShuffleArray.indexOf(shuffle) === -1) {
              newShuffleArray.push(shuffle);
            }
          }
          var newIndex = newShuffleArray.indexOf(0)
          appbarData.set_shuffle_list(newShuffleArray)
          appbarData.set_play_prev_next_track(newIndex);
          appbarData.seek_to(true);
        }
      }
      if (appbarData.viewPlay.selectedToPlay.length === 0) {
        appbarData.set_shuffle(false)
        appbarData.set_play_prev_next_track(0)
      }
      return null
    })
    //this.props.appbarData.get_tracks_from_playlist(this.props.appbarData.login.token, val.playlist_id)
  }

  allPlaylistStarButtonMob = (index, appbarData, val) => {
    if (localStorage.getItem('playlist') !== 'viewplay') {
      localStorage.setItem("playlist", 'viewplay')
      if (appbarData !== undefined) {
        if (appbarData.viewPlay.selectedToPlay.includes(val) === false) {
          var selectedPlaylist = appbarData.viewPlay.selectedToPlay
          var allPlaylist = this.props.data
          var filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          appbarData.set_playlist(allPlaylist)
          if (appbarData.viewPlay.selectedToPlay.length === 1) {
            appbarData.set_play_button(true)
          }
          appbarData.set_play_prev_next_track(0);
          appbarData.set_selected_to_play_playlist(selectedPlaylist)
          // appbarData.get_tracks_from_playlist_shift(this.props.sidebarprop.login.token, val.playlist_id,
          //   this.props.sidebarprop.viewPlay.selectedToPlay.length, this.props.sidebarprop.viewPlay.tracks)
          appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
        }
      }
      else {
        if (this.props.sidebarprop.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          this.props.sidebarprop.set_playlist(allPlaylist)
          if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 1) {
            this.props.sidebarprop.set_play_button(true)
          }
          this.props.sidebarprop.set_play_prev_next_track(0);
          this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
          this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
          // this.props.sidebarprop.get_tracks_from_playlist_shift(this.props.sidebarprop.login.token, val.playlist_id,
          //   this.props.sidebarprop.viewPlay.selectedToPlay.length, this.props.sidebarprop.viewPlay.tracks)
        }
      }
    } else {
      if (appbarData !== undefined) {
        if (appbarData.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = appbarData.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          appbarData.set_playlist(allPlaylist)
          if (appbarData.viewPlay.selectedToPlay.length === 1) {
            appbarData.set_play_button(true)
          }
          appbarData.set_selected_to_play_playlist(selectedPlaylist)
          // appbarData.get_tracks_from_playlist(appbarData.login.token, val.playlist_id)
          appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
        }
      }
      else {
        if (this.props.sidebarprop.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          this.props.sidebarprop.set_playlist(allPlaylist)
          if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 1) {
            this.props.sidebarprop.set_play_button(true)
          }
          this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
          // this.props.sidebarprop.get_tracks_from_playlist(this.props.sidebarprop.login.token, val.playlist_id)
          this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
        }
      }

    }
  }

  //laptop screen funtionality

  selectedToPlayStarButton = (index) => {
    let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
    let allPlaylist = this.props.data
    let filterSelectedPlaylist = selectedPlaylist.splice(index, 1);
    let play = allPlaylist.filter(val => val.playlist_id === filterSelectedPlaylist[0].playlist_id)
    if (play.length === 0) {
      allPlaylist.push(filterSelectedPlaylist[0])
    }
    // this.props.sidebarprop.set_play_progress(0);
    // this.props.sidebarprop.seek_to(true);
    // this.props.sidebarprop.set_play_prev_next_track(0);
    if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 0) {
      this.props.sidebarprop.set_shuffle(false)
      this.props.sidebarprop.set_shuffle_incrementer(0)
      this.props.sidebarprop.set_shuffle_list([])
      this.props.sidebarprop.set_filter_playlist_tracks([])
      this.props.sidebarprop.seek_to(false);
      this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
      this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
    } else {
      this.props.sidebarprop.set_playlist(allPlaylist)
      this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
      this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
    }
    this.props.sidebarprop.viewPlay.tracks.map((d, index) => {
      if (d.playlist_id === filterSelectedPlaylist[0].playlist_id) {
        this.props.sidebarprop.viewPlay.tracks.splice(index, filterSelectedPlaylist[0].tracks)
        if (this.props.sidebarprop.reactPlayer.shuffle === true) {
          var newShuffleArrays = this.props.sidebarprop.reactPlayer.shuffleList.filter(m => m < this.props.sidebarprop.viewPlay.tracks.length)
          var newShuffleArray = [], shuffleLen = newShuffleArrays.length - 1, maxShuffleNum = newShuffleArrays.length - 1, shuffle;
          while (newShuffleArray.length <= shuffleLen) {
            shuffle = Math.round(Math.random() * maxShuffleNum);
            if (newShuffleArray.indexOf(shuffle) === -1) {
              newShuffleArray.push(shuffle);
            }
          }
          var newIndex = newShuffleArray.indexOf(0)
          this.props.sidebarprop.set_shuffle_list(newShuffleArray)
          this.props.sidebarprop.set_play_prev_next_track(newIndex);
          this.props.sidebarprop.seek_to(true);
        }
      }
      return null
    })
    if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 0) {
      this.props.sidebarprop.set_shuffle(false);
      this.props.sidebarprop.set_play_prev_next_track(0)
    }
  }

  allPlaylistStarButton = (index, appbarData, val) => {
    if (localStorage.getItem('playlist') !== 'viewplay') {
      localStorage.setItem("playlist", 'viewplay')
      if (appbarData !== undefined) {
        if (appbarData.viewPlay.selectedToPlay.includes(val) === false) {
          var selectedPlaylist = appbarData.viewPlay.selectedToPlay
          var allPlaylist = this.props.data
          var filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          //appbarData.set_playlist(allPlaylist)
          appbarData.get_playlist(appbarData.login.token);
          if (appbarData.viewPlay.selectedToPlay.length === 1) {
            appbarData.set_play_button(true)
          }
          appbarData.set_play_prev_next_track(0);
          appbarData.set_selected_to_play_playlist(selectedPlaylist)
          // appbarData.get_tracks_from_playlist_shift(appbarData.login.token, val.playlist_id,
          //   this.props.sidebarprop.viewPlay.selectedToPlay.length, this.props.sidebarprop.viewPlay.tracks)
          appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
        }
      }
      else {
        if (this.props.sidebarprop.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          // this.props.sidebarprop.set_playlist(allPlaylist)
          this.props.sidebarprop.get_playlist(this.props.sidebarprop.login.token);
          if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 1) {
            this.props.sidebarprop.set_play_button(true)
          }
          this.props.sidebarprop.set_play_prev_next_track(0);
          this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
          // this.props.sidebarprop.get_tracks_from_playlist_shift(this.props.sidebarprop.login.token, val.playlist_id,
          //   this.props.sidebarprop.viewPlay.selectedToPlay.length, this.props.sidebarprop.viewPlay.tracks)
          this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
        }
      }
    } else {
      if (appbarData !== undefined) {
        if (appbarData.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = appbarData.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          //appbarData.set_playlist(allPlaylist)
          appbarData.get_playlist(appbarData.login.token);
          if (appbarData.viewPlay.selectedToPlay.length === 1) {
            appbarData.set_play_button(true)
          }
          appbarData.set_selected_to_play_playlist(selectedPlaylist)
          // appbarData.get_tracks_from_playlist(appbarData.login.token, val.playlist_id)
          appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist, appbarData.reactPlayer.playIndex)
        }
      }
      else {
        if (this.props.sidebarprop.viewPlay.selectedToPlay.includes(val) === false) {
          let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
          let allPlaylist = this.props.data
          let filteredAllPlaylist = allPlaylist.splice(index, 1);
          selectedPlaylist.push(filteredAllPlaylist[0]);
          this.props.sidebarprop.get_playlist(this.props.sidebarprop.login.token);
          // this.props.sidebarprop.set_playlist(allPlaylist)
          if (this.props.sidebarprop.viewPlay.selectedToPlay.length === 1) {
            this.props.sidebarprop.set_play_button(true)
          }
          this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
          // this.props.sidebarprop.get_tracks_from_playlist(this.props.sidebarprop.login.token, val.playlist_id)
          this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
        }
      }

    }
  }
  confirmDeletePlaylist = (appbarData) => {
    var allPlaylists = this.props.data
    var filterAllPlaylist = allPlaylists.filter(v => (v.playlist_id !== this.state.deletePlaylistID))
    if (filterAllPlaylist.length === 0) {
      allPlaylists.push([])
    }
    if (appbarData !== undefined) {
      appbarData.delete_event_selected_to_play(
        appbarData.login.token,
        this.state.deletePlaylistID
      );
      var selectedPlaylist = appbarData.viewPlay.selectedToPlay
      var selectedPlaylistTracks = appbarData.viewPlay.tracks
      var filterPlaylist = selectedPlaylist.filter(v => (v.playlist_id !== this.state.deletePlaylistID))
      var filterTracks = selectedPlaylistTracks.filter(v => (v.playlist_id !== this.state.deletePlaylistID))
      selectedPlaylistTracks = filterTracks
      selectedPlaylist = filterPlaylist
      if (appbarData.reactPlayer.shuffle === true) {
        var newShuffleArrays = appbarData.reactPlayer.shuffleList.filter(m => m < appbarData.viewPlay.tracks.length)
        var newShuffleArray = [], shuffleLen = newShuffleArrays.length - 1, maxShuffleNum = newShuffleArrays.length - 1, shuffle;
        while (newShuffleArray.length <= shuffleLen) {
          shuffle = Math.round(Math.random() * maxShuffleNum);
          if (newShuffleArray.indexOf(shuffle) === -1) {
            newShuffleArray.push(shuffle);
          }
        }
        var newIndex = newShuffleArray.indexOf(0)
        appbarData.set_playlist(filterAllPlaylist)
        appbarData.set_shuffle_list(newShuffleArray)
        appbarData.set_play_prev_next_track(newIndex);
        appbarData.seek_to(true);
      } else {
        appbarData.set_play_prev_next_track(0);
      }
      appbarData.set_filter_playlist_tracks(selectedPlaylistTracks)
      appbarData.set_selected_to_play_playlist(selectedPlaylist)
      appbarData.get_tracks_from_playlist_arr_unselected(appbarData.login.token, selectedPlaylist)
      this.setState({
        deleteDialogSelected: !this.state.deleteDialogSelected,
        deletePlaylistID: ''
      })
    }
    else {
      this.props.sidebarprop.delete_event_selected_to_play(
        this.props.sidebarprop.login.token,
        this.state.deletePlaylistID
      );
      let selectedPlaylist = this.props.sidebarprop.viewPlay.selectedToPlay
      let selectedPlaylistTracks = this.props.sidebarprop.viewPlay.tracks
      let filterPlaylist = selectedPlaylist.filter(v => (v.playlist_id !== this.state.deletePlaylistID))
      let filterTracks = selectedPlaylistTracks.filter(v => (v.playlist_id !== this.state.deletePlaylistID))
      selectedPlaylistTracks = filterTracks
      selectedPlaylist = filterPlaylist
      if (this.props.sidebarprop.reactPlayer.shuffle === true) {
        let newShuffleArrays = this.props.sidebarprop.reactPlayer.shuffleList.filter(m => m < this.props.sidebarprop.viewPlay.tracks.length)
        let newShuffleArray = [], shuffleLen = newShuffleArrays.length - 1, maxShuffleNum = newShuffleArrays.length - 1, shuffle;
        while (newShuffleArray.length <= shuffleLen) {
          shuffle = Math.round(Math.random() * maxShuffleNum);
          if (newShuffleArray.indexOf(shuffle) === -1) {
            newShuffleArray.push(shuffle);
          }
        }
        let newIndex = newShuffleArray.indexOf(0)
        if (selectedPlaylist.length === 0) {
          this.props.sidebarprop.set_shuffle(false)
          this.props.sidebarprop.set_shuffle_list([])
          this.props.sidebarprop.set_play_prev_next_track(0);
        } else {
          this.props.sidebarprop.set_shuffle_list(newShuffleArray)
          this.props.sidebarprop.set_play_prev_next_track(newIndex);
        }
        this.props.sidebarprop.seek_to(true);
      } else {
        this.props.sidebarprop.set_play_prev_next_track(0);
      }
      this.props.sidebarprop.set_playlist(filterAllPlaylist)
      this.props.sidebarprop.set_filter_playlist_tracks(selectedPlaylistTracks)
      this.props.sidebarprop.set_selected_to_play_playlist(selectedPlaylist)
      this.props.sidebarprop.get_tracks_from_playlist_arr_unselected(this.props.sidebarprop.login.token, selectedPlaylist, this.props.sidebarprop.reactPlayer.playIndex)
      this.setState({
        deleteDialogSelected: !this.state.deleteDialogSelected,
        deletePlaylistID: ''
      })
    }
  }

  deletePlaylistDialog = (appbarData) => {
    if (appbarData !== undefined) {
      appbarData.delete_event(
        appbarData.login.token,
        this.state.deletePlaylistID
      );
      this.setState({
        deleteDialog: !this.state.deleteDialog,
        deletePlaylistID: ''
      })
    }
    else {
      this.props.sidebarprop.delete_event(
        this.props.sidebarprop.login.token,
        this.state.deletePlaylistID
      );
      this.setState({
        deleteDialog: !this.state.deleteDialog,
        deletePlaylistID: ''
      })
    }
  }
  render() {
    // side nav option in big screens
    var appbarData = this.props.sidebarprop.prop;
    if (appbarData === undefined) {
      if (this.props.sidebarprop.createPlay.reset) {
        this.setState({
          none1: "None",
          none2: "None",
          none3: "None",
          none4: "None",
          expand: false,
          expanded: 0,
          selection: []
        })
        this.props.sidebarprop.set_reset(false)
      }
    } else {
      if (appbarData.createPlay.reset) {
        this.setState({
          none1: "None",
          none2: "None",
          none3: "None",
          none4: "None",
          expand: false,
          expanded: 0,
          selection: []
        })
        appbarData.set_reset(false)
      }
    }
    return (
      <>
        <Grid container  >
          <Grid
            container
            justify="space-between"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              fontSize: 13,
              paddingLeft: '5%',
              height: 220
            }}
            item
            xs={12}
            spacing={2}
          >
            {appbarData !== undefined ? appbarData.viewPlay.selectedToPlay.map((val, index) => {

              return (
                <Grid item className="card-cont" xs={12} md={6}>
                  <span>{val.tracks} TRACKS:</span>
                  <Card className="play-text-cont">
                    <div className="play-text-cont">
                      <span className="card-text">{val.name}</span>
                    </div>
                    <Grid item xs={12} container>
                      <Grid xs={6} item >
                        <img
                          alt="track"
                          src={val.trackImage[0]}
                          style={{ width: '95%', height: 50 }}
                        />
                      </Grid>
                      <Grid xs={6} item >
                        <img
                          alt="track"
                          src={val.trackImage[1]}
                          style={{ width: '95%', height: 50 }}
                        />
                      </Grid>
                      <Grid xs={6} item >
                        <img
                          alt="track"
                          src={val.trackImage[2]}
                          style={{ width: '95%', height: 50 }}
                        />
                      </Grid>
                      <Grid xs={6} item >
                        <img
                          alt="track"
                          src={val.trackImage[3]}
                          style={{ width: '95%', height: 50 }}
                        />
                      </Grid>
                    </Grid>
                    <span className="card-text">
                      {/* 1 HOUR 32 MINUTES{" "} */}
                      <IconButton onClick={() => {
                        this.selectedToPlayStarButtonMob(index, appbarData)

                      }} style={{ padding: 0 }}>
                        <Icon style={{ fontSize: 12, color: "#ffd700" }}>
                          star_rate
                        </Icon>
                      </IconButton>
                      <IconButton style={{ padding: 0 }}
                        onClick={() => {
                          this.setState({ deleteDialogSelected: true, deletePlaylistID: val.playlist_id });
                        }}
                      >
                        <Icon style={{ fontSize: 12 }}>delete</Icon>
                      </IconButton>
                    </span>

                  </Card>
                </Grid>
              );
            }) :

              this.props.sidebarprop.viewPlay.selectedToPlay.map((val, index) => {

                return (
                  <Grid item className="card-cont" xs={12} md={6} key={index}>
                    <span>{val.tracks} TRACKS:</span>
                    <Card className="play-text-cont">
                      <div className="play-text-cont">
                        <span className="card-text">{val.name}</span>
                      </div>
                      <Grid item xs={12} container>
                        <Grid xs={6} item >
                          <img
                            alt="track"
                            src={val.trackImage[0] === null ? beingGood : val.trackImage[0]}
                            style={{ width: '95%', height: 50 }}
                          />
                        </Grid>
                        <Grid xs={6} item >
                          <img
                            alt="track"
                            src={val.trackImage[1] === null ? beingGood : val.trackImage[1]}
                            style={{ width: '95%', height: 50 }}
                          />
                        </Grid>
                        <Grid xs={6} item >
                          <img
                            alt="track"
                            src={val.trackImage[2] === null ? beingGood : val.trackImage[2]}
                            style={{ width: '95%', height: 50 }}
                          />
                        </Grid>
                        <Grid xs={6} item >
                          <img
                            alt="track"
                            src={val.trackImage[3] === null ? beingGood : val.trackImage[3]}
                            style={{ width: '95%', height: 50 }}
                          />
                        </Grid>
                      </Grid>
                      <span className="card-text">
                        {/* 1 HOUR 32 MINUTES{" "} */}
                        <IconButton
                          onClick={() => {
                            this.selectedToPlayStarButton(index)
                          }
                          } style={{ padding: 5 }}>
                          <Icon style={{ fontSize: 14, color: "#ffd700" }}>
                            star
                          </Icon>
                        </IconButton>
                        <IconButton style={{ padding: 5 }}
                          onClick={() => {
                            this.setState({ deleteDialogSelected: true, deletePlaylistID: val.playlist_id });
                          }}
                        >
                          <Icon style={{ fontSize: 14 }}>delete</Icon>
                        </IconButton>
                      </span>
                    </Card>
                  </Grid>
                );
              }
              )}
          </Grid>
          <Grid item xs={12} className="all-play-cont">
            <p className="selected-second-para">ALL PLAYLISTS</p>
            <hr style={{ marginRight: "7%" }} />
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              fontSize: 13,
              paddingLeft: '5%',
              height: 220
            }}
            xs={12}
            item
            spacing={2}
          >
            {
              this.props.data.length > 0 && this.props.data.map((val, index) => {
                if ((appbarData === undefined && this.props.sidebarprop.viewPlay.selectedToPlay.length > 0 && this.props.sidebarprop.viewPlay.selectedToPlay.map(v => v.playlist_id).includes(val.playlist_id) === false)
                  || (appbarData !== undefined && appbarData.viewPlay.selectedToPlay.length > 0 && appbarData.viewPlay.selectedToPlay.map(v => v.playlist_id).includes(val.playlist_id) === false)
                )
                  return (
                    <Grid item className="card-cont" xs={12} md={6} key={index}>
                      <span>{val.tracks === undefined ? '': val.tracks.length} TRACKS:</span>
                      <Card className="play-text-cont">
                        <div className="play-text-cont">
                          <span className="card-text">{val.name}</span>
                        </div>
                        <Grid item xs={12} container>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.length === 0 ? '':val.trackImage[0] === null ? beingGood : val.trackImage[0]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.length === 0 ? '': val.trackImage[1] === null ? beingGood : val.trackImage[1]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.length === 0 ? '': val.trackImage[2] === null ? beingGood : val.trackImage[2]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.length === 0 ? '': val.trackImage[3] === null ? beingGood : val.trackImage[3]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <span className="card-text">
                          {/* 1 HOUR 32 MINUTES{" "} */}
                          <IconButton
                            onClick={async () => {
                              this.allPlaylistStarButtonMob(index, appbarData, val)
                            }}
                            style={{ padding: 5 }}
                          >
                            {/* <img src={Star} className="svg-img" /> */}
                            <Icon style={{ fontSize: 14, color: "#ffd700" }}>
                              star_border
                            </Icon>
                          </IconButton>
                          <IconButton style={{ padding: 5 }}
                            onClick={() => {
                              this.setState({ deleteDialog: true, deletePlaylistID: val.playlist_id });
                            }}
                          >
                            <Icon style={{ fontSize: 14 }}>delete</Icon>
                          </IconButton>
                        </span>
                      </Card>
                    </Grid>
                  );
                else if ((this.props.sidebarprop.viewPlay.selectedToPlay.length === 0 && appbarData === undefined) ||
                  (appbarData !== undefined && appbarData.viewPlay.selectedToPlay.length === 0)
                ) {

                  return (
                    <Grid item className="card-cont" xs={12} md={6} key={index}>
                      <span>{val.tracks.length !== undefined && val.tracks.length} TRACKS:</span>
                      <Card className="play-text-cont">
                        <div className="play-text-cont">
                          <span className="card-text">{val.name}</span>
                        </div>
                        <Grid item xs={12} container>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.trackImage[0] === null ? beingGood : val.trackImage[0]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.trackImage[1] === null ? beingGood : val.trackImage[1]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.trackImage[2] === null ? beingGood : val.trackImage[2]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                          <Grid xs={6} item >
                            <img
                              alt="track"
                              src={val.trackImage[3] === null ? beingGood : val.trackImage[3]}
                              style={{ width: '95%', height: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <span className="card-text">
                          {/* 1 HOUR 32 MINUTES{" "} */}
                          <IconButton
                            onClick={async () => {
                              this.allPlaylistStarButton(index, appbarData, val)
                            }} style={{ padding: 5 }}>
                            <img src={Star} alt="star" className="svg-img" />


                          </IconButton>
                          <IconButton style={{ padding: 5 }}
                            onClick={() => {
                              this.setState({ deleteDialog: true, deletePlaylistID: val.playlist_id });
                            }}
                          >
                            <Icon style={{ fontSize: 14 }}>delete</Icon>
                          </IconButton>
                        </span>
                      </Card>
                    </Grid>
                  );
                }
                return null
              })}
          </Grid>
        </Grid>
        {/* view playlist delete playlist popup dialog */}
        <Dialog
          open={this.state.deleteDialogSelected}
          onClose={() => {
            this.setState({
              deleteModal: !this.state.deleteDialogSelected
            })
          }}
          aria-labelledby="customized-dialog-title"
        >
          <Grid style={{ backgroundColor: "#f6842d" }}>
            <DialogContent>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>Are you sure you want to delete this playlist?</span>
            </DialogContent>
            <DialogActions>
              <Button
                className="delete-button"
                autoFocus
                onClick={() => {
                  this.confirmDeletePlaylist(appbarData)
                }
                }

                color="primary">
                Confirm
              </Button>
              <Button autoFocus
                className="delete-button"
                onClick={() => {
                  this.setState({
                    deleteDialogSelected: !this.state.deleteDialogSelected,
                    deletePlaylistID: ''
                  })
                }}
                color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
        <Dialog
          open={this.state.deleteDialog}
          onClose={() => {
            this.setState({
              deleteModal: !this.state.deleteDialog
            })
          }}
          aria-labelledby="customized-dialog-title"
        >
          <Grid style={{ backgroundColor: "#f6842d" }}>
            <DialogContent>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>Are you sure you want to delete this playlist?</span>
            </DialogContent>
            <DialogActions>
              <Button
                className="delete-button"
                autoFocus
                onClick={() => {
                  this.deletePlaylistDialog(appbarData)
                }
                }

                color="primary">
                Confirm
              </Button>
              <Button autoFocus
                className="delete-button"
                onClick={() => {
                  this.setState({
                    deleteDialog: !this.state.deleteDialog,
                    deletePlaylistID: ''
                  })
                }}
                color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </>
    );
  }
}


export default withStyles(styles)(BodySideNavViewPlay);
