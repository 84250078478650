import React, { Component } from "react";
import {
  Dialog,
  Grid,
  Button,
  IconButton,
  Icon,
  Paper,
  List,
  Checkbox,
} from "@material-ui/core";
import "./dialogStyles.css";
class RemoveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      sideNav: false,
      removeDialog: false,
      name: "",
    };
  }
  checkBoxHandeler = (val) => {
    if (this.props.prop.viewCollection.selectedCollection.includes(val._id)) {
      let selectedID = this.props.prop.viewCollection.selectedCollection
      let removeId = selectedID.indexOf(val._id)
      selectedID.splice(removeId, 1)
      this.props.prop.selected_collection(selectedID)
    } else {
      this.props.prop.selected_collection([...this.props.prop.viewCollection.selectedCollection, val._id])
    }
  }
  confirmButton = async(val) => {
    await this.props.prop.remove_collection(
      this.props.prop.login.token,
      this.props.prop.viewCollection.selectedCollection,
      this.props.prop.viewCollection.limit,
      this.props.prop.viewCollection.starting_after
    )
    await this.props.prop.set_track([])
    await this.props.prop.set_play_prev_next_track(0)
    await this.props.prop.selected_collection([])
    await this.props.close(false);
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.props.close(false);
        }}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container>
          <Grid item xs={12} className="closeButtonContainer">
            <IconButton
              onClick={() => {
                this.props.prop.selected_collection([])
                this.props.close(false);
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="available-playlist-cont-text">
            <span className="logintext">REMOVE COLLECTION</span>
          </Grid>
          <Grid item xs={12} className="spantext">
            <span>
              SELECT THE COLLECTION YOU WANT TO REMOVE AND THEN CLICK ON CONFIRM.  
            </span>
          </Grid>
          <Grid item xs={12} className="passwordContainer">
            <Paper className="paper">
              {this.props.prop.viewCollection.collectionPlaylist.length > 0 &&
                <Grid className="grid-inner">
                  {this.props.prop.viewCollection.collectionPlaylist.length > 0 && this.props.prop.viewCollection.collectionPlaylist.map((val, i) => {
                    return (
                      <List
                        key={i}
                        className="list"
                        style={{
                          padding: 0,
                          borderStyle: "solid",
                          borderLeft: 0,
                          borderRight: 0,
                          borderTop: 0,
                          borderColor: "gray",
                        }}
                      >
                        <Checkbox
                          checked={this.props.prop.viewCollection.selectedCollection.includes(val._id) ? true : false}
                          onClick={() => {
                            this.checkBoxHandeler(val)
                          }}
                          color="default"
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                        {val.playlist_name.toUpperCase()}
                      </List>
                    )
                  })}
                </Grid>
              }
            </Paper>
          </Grid>
          <Grid container className="button-cont">
            <Grid item xs={6} sm={8} className="ok-container">
              <Button 
                variant="outlined" 
                className={this.props.prop.viewCollection.selectedCollection.length === 0?"disable-button":"ok-button"}
                disabled={ this.props.prop.viewCollection.selectedCollection.length === 0}
                onClick={async () => {
                  this.confirmButton()
                }}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} className="cancel-container">
              <Button
                className="cancel-button"
                onClick={() => {
                  this.props.prop.selected_collection([])
                  this.props.close(false);
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default RemoveDialog;
