import React from "react";
import {
  Dialog,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default class ResetPasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirm_password: "",
      resetcode: ""
    };
  }

  componentDidMount() {
    var path = this.props.path.split("/");
    this.setState({
      resetcode: path[2]
    }, () => {
      this.props.verifyChangePassword(this.state.resetcode);
    })
  }

  render() {
    if (this.props.valid === null) {
      return (
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={true}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container>
            <CircularProgress style={{ color: "white" }} />
          </Grid>
        </Dialog>
      )
    } else if (this.props.valid === false) {
      return (
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={true}
        >
          <Grid style={{ backgroundColor: "#f6842d" }} container>
            <Grid item xs={12} className="passwordResetHeaderContainer" style={{ marginTop: 20 }}>
              <Typography variant="h5">
                Your reset password link has expired. Please start your reset process again.
            </Typography>
            </Grid>
            <Grid item xs={12} className="sendButtonContainer">
              <Link to="/">
                <Button variant="outlined" data-test="sendButton" className="sendButton">
                  GO TO LOGIN
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Dialog>
      )
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={true}
      >
        <Grid style={{ backgroundColor: "#f6842d" }} container justify="center">

          <Grid item xs={12} className="passwordResetHeaderContainer" style={{ marginTop: 20 }}>
            <Typography variant="h5">
              Please enter your new password
            </Typography>
          </Grid>

          <Grid item xs={12} className="emailResetContainer">
            <TextField
              label="Password"
              type="password"
              data-test="email"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              fullWidth
              className="emailResetTextField"
            />
          </Grid>
          <Grid item xs={12} className="emailResetContainer">
            <TextField
              label="Confirm Password"
              type="password"
              data-test="email"
              value={this.state.confirm_password}
              onChange={(e) => {
                this.setState({ confirm_password: e.target.value });
              }}
              fullWidth
              className="emailResetTextField"
            />
          </Grid>

          <Grid item xs={7}>
            {(this.state.password !== "" && this.state.password !== this.state.confirm_password) &&
              <Typography variant="subtitle1" style={{textAlign: "center"}}>
                password and confirm password do not match.
              </Typography>
            }
          </Grid>

          <Grid item xs={12} className="sendButtonContainer">
            <Button variant="outlined" data-test="sendButton" className="sendButton"
              disabled={(this.state.password === "" || this.state.confirm_password === "") ? true : false}
              onClick={() => {
                this.props.changePassword(this.state.password, this.state.resetcode);
                this.setState({
                  password: "",
                  confirm_password: ""
                })
              }}>
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
