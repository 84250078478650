export const SET_PLAYLIST = "SET_PLAYLIST";
export const SET_PLAYLIST_EDIT_GET = "SET_PLAYLIST_EDIT_GET";
export const SET_RTP_MOODS="SET_RTP_MOODS";
export const ARANGE_TRACKS_UP = "ARANGE_TRACKS_UP";
export const SET_SELECTED_PLAYLIST = "SET_SELECTED_PLAYLIST";
export const SET_PLAYLIST_EDIT = "SET_PLAYLIST_EDIT";
export const SET_SELECTED_TO_PLAY_PLAYLIST = "SET_SELECTED_TO_PLAY_PLAYLIST";
export const SET_PLAYLIST_TRACKS = "SET_PLAYLIST_TRACKS";
export const SHOW_PLAYLIST_DATA = "SHOW_PLAYLIST_DATA";
export const SET_PLAYLIST_TRACKS_EDIT = "SET_PLAYLIST_TRACKS_EDIT";
export const SET_PLAYLIST_NAME = "SET_PLAYLIST_NAME";
export const SET_PLAYLIST_NAME_DIALOG = "SET_PLAYLIST_NAME_DIALOG";
export const CHECK = "CHECK";
export const SHOW_MOOD = "SHOW_MOOD";
export const CLEAR_SHOW_MOOD = "CLEAR_SHOW_MOOD";
export const SHOW_MOOD_FILTER = "SHOW_MOOD_FILTER"
export const SHOW = "SHOW"
export const  SET_CURRENT_PLAYLIST_NAME = " SET_CURRENT_PLAYLIST_NAME"
export const SHOW_MOOD_ARTIST = "SHOW_MOOD_ARTIST"
export const SELECT_RADIO = "SELECT_RADIO"
export const SELECTED_PLAYLIST_ID = "SELECTED_PLAYLIST_ID"
export const SET_FILTER_PLAYLIST_TRACKS = "SET_FILTER_PLAYLIST_TRACKS"
export const SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE = "SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE"
export const SET_FILTER_PLAYLIST_TRACKS_PLAYER = "SET_FILTER_PLAYLIST_TRACKS_PLAYER"
export const SET_COLLECTION = "SET_COLLECTION"
export const SET_COLLECTION_REMOVE = "SET_COLLECTION_REMOVE"
export const SET_FILTER_CREATE_TRACKS_PLAYER = "SET_FILTER_CREATE_TRACKS_PLAYER"
export const SET_FILTER_PLAYLIST_TRACKS_VIEW= "SET_FILTER_PLAYLIST_TRACKS_VIEW"
export const SET_FILTER_PLAYLIST_TRACKS_PLAYER_EDIT= "SET_FILTER_PLAYLIST_TRACKS_PLAYER_EDIT"
export const SET_RESET_SELECT_BOX= "SET_RESET_SELECT_BOX"
export const SET_VIEW_COLLECTION= "SET_VIEW_COLLECTION"
export const SELECTED_COLLECTION = "SELECTED_COLLECTION"
export const SET_EXPANDED = "SET_EXPANDED"
export const RESET_PLAYER = "RESET_PLAYER"
export const EDIT_UPDATE = "EDIT_UPDATE"
export  const EDIT_SET_PLAYLIST = "EDIT_SET_PLAYLIST"
export const SET_PLAYLIST_TRACKS_AFTER_EDIT = "SET_PLAYLIST_TRACKS_AFTER_EDIT"
export const SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR = "SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR"
export const SET_EDIT_UPDATE = "SET_EDIT_UPDATE"
export const SET_NONE= "SET_NONE"
export const SET_DEFAULT_NUMBER = "SET_DEFAULT_NUMBER"