import React, { Component } from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Checkbox,
  Box,
  Typography,
  TextField,
  MenuItem, withStyles
} from "@material-ui/core";
import "../style.css";

const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class BodySideNavCreatePlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //old
      expand: false,
      expanded: "",
      selection: [],
      //new
      expand: false,
      expanded: 0,
      allMoods: [],
      expandAccordion: null,
      moodDropdowns: [0, 1, 2, 3],
      dropdownSelection: [], //shows the selection of moods
      secondaryFilterData: [],
      secondaryFilter: [], //data edited by the user
      refetchArtist: false
    };
  }

  handleChange = (panel) => {
    if (this.state.expanded !== panel) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: "" });
    }
  }

  componentDidMount() {
    this.setState({
      secondaryFilterData: [],
      secondaryFilter: []
    })
  }

  componentDidUpdate() {
    //POPULATE THE MOOD DROPDOWN DATA IF IT CHANGES
    if (this.state.allMoods.length === 0 && this.props.createPlay.moodsDetail.length > 0) {
      this.setState({
        allMoods: this.props.createPlay.moodsDetail
      })
    }

    var secondaryDataMaster = [], secondaryFilter = [];
    //POPULATE THE SECONDARY MASTER DATA WHEN THE DATA IS RECEIVED


    var track = this.props.createPlay.track;
    // console.log("1", this.state.secondaryFilter);
    // console.log("2", track);
    if (this.state.dropdownSelection.length > 0) {

      // console.log(this.state.secondaryFilterData);
      console.log(this.state.secondaryFilter);
      if(this.state.secondaryFilterData.length !== track.length){
        console.log("check")
        track.map((track, index) => {
          secondaryDataMaster.push({
            artist: track.artist,
            decade: track.decades
          });
  
          this.setState({
            secondaryFilterData: secondaryDataMaster
          })
        })
      }
      else if(this.state.refetchArtist){
        track.map((t, index)=>{
          if(t.artist.length !== this.state.secondaryFilter[index].artist.length || t.decades.length !== this.state.secondaryFilter[index].decade.length){
            console.log(this.state.refetchArtist);
            this.state.secondaryFilterData[index].artist = t.artist;
            this.state.secondaryFilterData[index].decade = t.decades;
            this.state.secondaryFilter[index] = {
              mood: this.state.dropdownSelection[index].mood,
              artist: t.artist,
              decade: t.decades,
              checkedDecades: t.decades.map(() => (true)),
              checkedArtist: t.artist.map(() => (true)),
              remove: []
            }
            this.setState({
              secondaryFilterData: this.state.secondaryFilterData,
              secondaryFilter: this.state.secondaryFilter              
            }, ()=>{
              if(index === track.length-1){
                this.setState({
                  refetchArtist: false
                })
                this.props.set_show_mood(this.props.login.token, this.state.secondaryFilter);
              }
            })
          }
        })
      }
      

      this.state.dropdownSelection.map((dropdown, index)=>{
        if(dropdown.mood === "None"){
          this.state.dropdownSelection.splice(index, 1);
          this.state.secondaryFilterData.splice(index,1);
          this.setState({
            dropdownSelection: this.state.dropdownSelection,
            secondaryFilterData: this.state.secondaryFilterData
          },()=>{
            this.props.set_show_mood(this.props.login.token, []);
          })
        }
      })

    }

    if(this.state.dropdownSelection.length === 0 && this.state.secondaryFilterData.length > 0){
      this.setState({
        secondaryFilterData: [],
        secondaryFilter: []
      },()=>{
        this.props.set_show_mood(this.props.login.token, []);
      })
    }

    var track2 = this.props.createPlay.track;
    // WHEN THE USER SELECTS FROM THE FIRST MENU AND THE RESPONSE IS RECEIVED BY THE SECONDARY FILTER IS NOT UPDATED
    if (this.state.dropdownSelection.length > 0 && ((this.state.secondaryFilter.length === (track2.length - 1)) || (this.state.secondaryFilter.length === 0 && track2.length > 0))) {
      var index = track2.length - 1;
      this.state.secondaryFilter.push({
        mood: this.state.dropdownSelection[index].mood,
        artist: track2[index].artist,
        decade: track2[index].decades,
        checkedDecades: track2[index].decades.map(() => (true)),
        checkedArtist: track2[index].artist.map(() => (true)),
        remove: []
      })

      this.setState({
        secondaryFilter: this.state.secondaryFilter
      }, () => {
        console.log("get data")
        this.props.set_show_mood(this.props.login.token, this.state.secondaryFilter);
      })
    }

  }

  selectMood = (e, appbarData, index) => {
    var selection = this.state.dropdownSelection;
    if (selection[index]) {
      selection[index].mood = e.target.value;
    } else {
      selection.push({
        mood: e.target.value
      })
    }
    this.setState({
      dropdownSelection: selection,
      refetchArtist: true
    }, () => {
      this.props.set_show_mood_artist(this.props.login.token, this.state.dropdownSelection);
    })
  }

  toggleDecade = (year, index) => {
    var secondary = [...this.state.secondaryFilter];
    var i = secondary[index].decade.indexOf(year);

    secondary[index].checkedDecades[i] = !secondary[index].checkedDecades[i];

    this.setState({
      secondaryFilter: secondary
    }, () => {
      this.props.set_show_mood_filter(this.props.login.token, this.state.secondaryFilter);
    })
  }

  toggleArtist = (artist, index) => {
    var secondary = [...this.state.secondaryFilter];
    var i = secondary[index].artist.indexOf(artist);

    secondary[index].checkedArtist[i] = !secondary[index].checkedArtist[i];

    this.setState({
      secondaryFilter: secondary
    }, () => {
      this.props.set_show_mood_filter(this.props.login.token, this.state.secondaryFilter);
    })
  }
  render() {
    // side nav option in big screens
    var appbarData = this.props.prop;
    return (
      <Grid container className="view-text-cont" >
        <Grid item xs={12} md={12} className="view-text-cont-create-play">
          {/* MOOD SELECTION DROPDOWNS */}
          {this.state.moodDropdowns.map((dropdown, index) => {
            return (
              <TextField
                key={index}
                select
                style={{ background: 'white', width: '90%' }}
                value={this.state.dropdownSelection[index] ? this.state.dropdownSelection[index].mood : "None"}
                onChange={async (e) => {
                  this.selectMood(e, appbarData, index)
                }}
                SelectProps={{
                  native: false,
                }}
                variant="outlined"
              >
                <MenuItem value={'None'}>None</MenuItem>
                {this.state.allMoods.map((m, index) => {
                  return (
                    <MenuItem key={index} value={m}>
                      {m}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          })}

          {/* SECONDARY FILTERS */}
          {this.state.secondaryFilterData.map((m, index) => {
            return (
              <>
                <Accordion
                  expanded={this.state.expanded === index}
                  key={index}
                  className="accordian-mood"
                  square={true}
                  onChange={() => {
                    this.handleChange(index)
                  }}
                >
                  <AccordionSummary
                    style={{ backgroundColor: '#f6842d' }}
                    expandIcon={
                      <Icon className='accor-button'>arrow_downward</Icon>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid container>
                      <Grid container>
                        <span style={{ fontWeight: 600, padding: 0 }}>{this.state.dropdownSelection[index] ? this.state.dropdownSelection[index].mood : 'No Mood Filter Selected'}</span>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {m.artist.length > 0 ?

                      <Grid container direction="row">
                        <Grid item xs={12} md={12}>
                          <Typography style={{ fontWeight: 600, fontSize: 12 }}>
                            DECADES
                          </Typography>
                          <Grid container direction="row">
                            {m.decade.map((s, i) => {
                              return (
                                <Grid item xs={12} md={6} key={i}>
                                  <Box border={1} borderColor='#E8E8E8'>
                                    <Checkbox
                                      checked={this.state.secondaryFilter.length > 0 ? this.state.secondaryFilter[index].checkedDecades[i] : false}
                                      size="small"
                                      classes={{ root: this.props.classes.radio, checked: this.props.classes.checked }}
                                      onChange={() => {
                                        this.toggleDecade(s, index);
                                      }}
                                    />

                                    {s === 0 ? "Unknown": s+"s"}
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12 }}>
                            ARTISTS
                          </Typography>
                          <Grid container direction="row">
                            {m.artist.map((mi, i) => {
                              return (
                                <Grid item xs={12} key={i}>
                                  <Box border={1} borderColor='#E8E8E8'>
                                    <Checkbox
                                      classes={{ root: this.props.classes.radio, checked: this.props.classes.checked }}
                                      checked={this.state.secondaryFilter.length > 0 ? this.state.secondaryFilter[index].checkedArtist[i] : false}
                                      size="small"
                                      onClick={() => {
                                        this.toggleArtist(mi, index);
                                      }}
                                    />
                                    {mi}
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                      :
                      <span>This mood does not contain any tracks. Please select another mood.</span>
                    }
                  </AccordionDetails>
                </Accordion>
              </>
            )
          })}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BodySideNavCreatePlaylist);