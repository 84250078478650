import React, { Component } from "react";
import {
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  Icon,
  Hidden,
  IconButton
} from "@material-ui/core";
import beingGood from "../../../styles/images/album/bg-placeholder-logo.svg";
import "./style.css";
class CreatePlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavButton: true,
      trackDataLength: 0,
      lastplay: '',
      currentPlay: ''
    };
  }

  componentDidMount() {
    this.props.set_position(1)
    this.props.get_moods(this.props.login.token)
    if (localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.tracks.length > 0 && this.props.createPlay.showMoodTracks.length === 0) {
      this.props.set_track([])
      this.props.set_play_prev_next_track(0)
      this.props.set_play_button(false)
    }
    if (localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.tracks.length === 0) {
      this.props.set_play_button(false)
    }
    if (this.props.reactPlayer.redirect) {
      this.props.set_redirect(false)
      try {
        var elmnt = document.getElementById(this.props.reactPlayer.lastPlayedId);
        elmnt.scrollIntoView();
      } catch (e) {

      }
    }
  }

  componentWillUnmount() {
    this.props.show_mood([], [])
  }

  addShuffle = async (index, track) => {
    var newShuffleArray = [], shuffleLen = track.length - 1, maxShuffleNum = track.length - 1, shuffle;
    while (newShuffleArray.length <= shuffleLen) {
      shuffle = Math.round(Math.random() * maxShuffleNum);
      if (newShuffleArray.indexOf(shuffle) === -1) {
        newShuffleArray.push(shuffle);
      }
    }
    var newIndex = newShuffleArray.indexOf(index)
    await this.props.set_track_shuffle(track, newIndex, newShuffleArray)
    await this.props.set_play_button(true)
    await this.props.seek_to(true);
  }

  // play pause button login onclick
  playPauseButton = (i, moodTracks) => {
    if (localStorage.getItem('playlist') === 'createplay') {
      if (this.props.reactPlayer.playIndex === i && this.props.reactPlayer.shuffle === false) {
        if (this.props.reactPlayer.playButton === false) {
          this.props.set_track(moodTracks.data)
          this.props.set_play_button(true)
        } else {
          this.props.set_track(moodTracks.data)
          this.props.set_play_button(false)
        }
      }
      else if (this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === i) {
        if (this.props.reactPlayer.playButton === false) {
          this.props.set_play_button(true)
        } else {
          this.props.set_play_button(false)
        }
      }
      else {
        if (this.props.reactPlayer.shuffle === true) {
          this.props.set_track(moodTracks.data)
          this.addShuffle(i, moodTracks.data)
        } else {
          this.props.set_track(moodTracks.data)
          this.props.set_play_button(true)
          this.props.set_play_prev_next_track(i);
        }
      }

    } else {
      localStorage.setItem("playlist", 'createplay')
      if (this.props.reactPlayer.shuffle === true) {
        this.props.set_track(moodTracks.data)
        this.addShuffle(i, moodTracks.data)
      } else {
        this.props.set_track(moodTracks.data)
        this.props.set_play_button(true)
        this.props.set_play_prev_next_track(i);
        this.props.seek_to(true);
      }
    }
    if (this.state.currentPlay !== i && this.props.reactPlayer.show_ads === false) {
      this.props.set_music_index(this.props.reactPlayer.musicIndex + 1)
      this.setState({
        currentPlay: i
      })
    }
    if (this.props.reactPlayer.musicIndex % this.props.reactPlayer.ads_track_limit === 0) {
      this.props.show_ads(true)

    }
  }
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card className="create-play-container">
              <Grid container className="mood-container" >

                <Hidden mdDown>
                  <Grid item className="mood-text-container" xs={12} md={6}>
                    {this.props.createPlay.showMoodTracks.length > 0 && 'MOOD(S)'}
                  </Grid>
                </Hidden>

                <Grid item xs={12} md={6} className="mood-text-container">
                  {this.props.createPlay.showMoodTracks.length > 0 && 'MOOD(S)'}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {/* create play fetched data according to mood */}
                {this.props.createPlay.showMoodTracks.map((moodTracks) => {
                  return moodTracks.data.map((track, i) => {
                    if (this.state.lastplay !== i && this.props.reactPlayer.playIndex === i && this.props.reactPlayer.playButton === true && localStorage.getItem('playlist') === 'createplay') {
                      this.setState({
                        lastplay: i
                      }, function () {
                        this.props.set_play_page('/create_play', '/create_play')
                      })
                      this.props.set_last_play_id(i + 1)
                    }
                    return (
                      <Grid item xs={12} md={6} key={i}>
                        <List style={{ paddingBottom: 0 }}>

                          <ListItem style={{ padding: 0, paddingLeft: 8, paddingTop: 8, backgroundColor: localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.shuffle === false ? this.props.reactPlayer.playIndex === i && this.props.reactPlayer.playButton === true ? "#f2f2f2" : "#ffffff" : localStorage.getItem('playlist') === 'createplay' && this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === i && this.props.reactPlayer.playButton === true ? "#f2f2f2" : "#ffffff" }}>
                            <ListItemText
                              primary={
                                <Grid container>
                                  <Grid
                                    className="play-pause-icon-cont"
                                    item
                                    xs={3}
                                  >
                                    <b
                                      style={{
                                        position: "relative",
                                        top: -20
                                      }}
                                      id={i + 1} 
                                      data-test="num"
                                      >
                                      {i + 1}
                                    </b>
                                    <IconButton
                                      style={{
                                        position: "relative",
                                        top: -20
                                      }}
                                      disableTouchRipple
                                      onClick={() => {
                                        this.playPauseButton(i, moodTracks)
                                      }}>
                                      {localStorage.getItem('playlist') === 'createplay' ?
                                        this.props.reactPlayer.shuffle === true && this.props.reactPlayer.shuffleIncrementer > 0 ? this.props.reactPlayer.shuffleList[this.props.reactPlayer.playIndex] === i && this.props.reactPlayer.playButton === true ?
                                          <Icon style={{ color: "#f6842d" }}>
                                            pause
                                          </Icon>
                                          :
                                          <Icon style={{ color: 'black' }}>
                                            play_arrow
                                          </Icon>
                                          :
                                          this.props.reactPlayer.playIndex === i && this.props.reactPlayer.playButton === true ?
                                            <Icon style={{ color: "#f6842d" }}>
                                              pause
                                            </Icon>
                                            :
                                            <Icon style={{ color: 'black' }}>
                                              play_arrow
                                            </Icon>
                                        :
                                        <Icon style={{ color: 'black' }}>
                                          play_arrow
                                        </Icon>
                                      }
                                    </IconButton>
                                    <img
                                      alt="album"
                                      width={55}
                                      height={55}
                                      src={track.album_art_image === null ? beingGood : track.album_art_image}
                                    />
                                  </Grid>

                                  <Grid item xs={5}>
                                    <ListItemText primary={<b>{track.title.toUpperCase()}</b>} />
                                    <ListItemText primary={track.artist.toUpperCase()} />
                                  </Grid>
                                  <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <span
                                      style={{
                                        position: "relative",
                                        top: 20
                                      }}
                                    >{track.mood.toUpperCase()}</span>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItem>

                        </List>
                      </Grid>
                    )
                  })
                })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default CreatePlayList;
