import React, { Component } from "react";
import {
  Grid,
  withStyles
} from "@material-ui/core";

import "./style.css";
import BODYSIDENAVCOLL from "./Body SideNav Component/bodySideNavCollection";
import BodySideNavCreatePlaylist from "./Body SideNav Component/bodySideNavCreatePlay";
import BODYSIDENAVVIEWPLAY from "./Body SideNav Component/bodySideNavViewPlay";
import BODYSIDENAVEDITPLAY from "./Body SideNav Component/bodySideNavEditPlay";
const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class BodySideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      expanded: "",
      selection: [],
      incrementer: 0,
      selectedToPlay: [],
      deleteDialog: false,
      deletePlaylistID: '',
      setDisabled: false,
      moodFilter: [],
      setPlaylist: false,
      deleteDialogSelected: false
    };
  }

  componentDidMount() {
    if (this.props.sidebarprop.prop !== undefined) {
      this.setState({
        incrementer: this.props.sidebarprop.prop.sideNav.incrementer,
        selectedToPlay: this.props.sidebarprop.prop.sideNav.selectedToPlay,
        deleteDialog: this.props.sidebarprop.prop.sideNav.deleteDialog,
        deletePlaylistID: this.props.sidebarprop.prop.sideNav.deletePlaylistID,
        setDisabled: this.props.sidebarprop.prop.sideNav.setDisabled,
        moodFilter: this.props.sidebarprop.prop.sideNav.moodFilter,
        setPlaylist: this.props.sidebarprop.prop.sideNav.setPlaylist,
        deleteDialogSelected: this.props.sidebarprop.prop.sideNav.deleteDialogSelected
      })
    }
  }
  // Laptop Screen Logic
  componentWillUnmount() {
    if (this.props.sidebarprop.prop !== undefined) {
      this.props.sidebarprop.prop.on_unmount_set_none_for_mobile_screen({
        none1: this.state.none1,
        none2: this.state.none2,
        none3: this.state.none3,
        none4: this.state.none4,
        expand: this.state.expand,
        expanded: this.state.expanded,
        selection: this.state.selection,
        incrementer: this.state.incrementer,
        selectedToPlay: this.state.selectedToPlay,
        deleteDialog: this.state.deleteDialog,
        deletePlaylistID: this.state.deletePlaylistID,
        setDisabled: this.state.setDisabled,
        moodFilter: this.state.moodFilter,
        setPlaylist: this.state.setPlaylist,
        deleteDialogSelected: this.state.deleteDialogSelected
      })
    }
  }
  

  render() {
    // side nav option in big screens
    var appbarData = this.props.sidebarprop.prop;
    var position = this.props.sidebarprop.position;
    if (appbarData === undefined) {
      if (this.props.sidebarprop.createPlay.reset) {
        this.props.sidebarprop.set_reset(false)
      }
    } else {
      if (appbarData.createPlay.reset) {
        appbarData.set_reset(false)
      }
    }

    return (
      <>
        <Grid container>
          {/* create collection side nav */}
          {position === 0 && (
            <BODYSIDENAVCOLL />
          )}
          {/* create play side nav */}
          {position === 1 && (
            <BodySideNavCreatePlaylist
              key={1}
              expanded={this.state.expanded}
              {...this.props}
            />
          )}
          {/* view playlist side nav */}
          {position === 2 && (
            <BODYSIDENAVVIEWPLAY
              sidebarprop={this.props.sidebarprop}
              data={this.props.viewPlay.playlist}
            />
          )}
          {/* edit play side nav */}
          {position === 3 && (
            <BODYSIDENAVEDITPLAY />
          )}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(BodySideNav);