import {
  SET_SCREEN_SAVER_ON
} from "../../constants/react player/reactPlayerConst";
const initial_state = {
  screenSaverOn: false,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SCREEN_SAVER_ON:
      return state = { ...state, screenSaverOn: action.payload }
    default:
      return state;
  }
}
