import React, { Component } from "react";
import { connect } from "react-redux";
import EditPlaylist from "../../components/playlist/edit playlist/editPlaylist"
import { set_position } from "../../actions/sideNav/sideNavAction"
import {set_track as set_t } from "../../actions/playlist/createPlaylistAction";
import {
  delete_track,
  set_track,
  check,
  update_playlist_drag,
  selectedPlaylistID,
  selectedRadio, 
  arange_tracks_up, 
  set_selected_playlist, 
  set_playlist, 
  get_playlist,
  get_tracks_from_playlist,
  update_playlist,
  set_playlist_name,
  set_playlist_name_dialog, 
  show_playlist_data, 
  set_playlist_api
} from "../../actions/playlist/editPlayistAction";

import {
  set_play_prev_next_track,
  set_play_button,
  set_play_progress,
  seek_to,
  set_shuffle_list
} from "../../actions/react player/reactPlayerAction";

export class EditPlaylistCont extends Component {
  render() {
    return (
      <EditPlaylist {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    edit: store.editPlay,
    reactPlayer:store.reactPlayer,
    createPlay: store.createPlay,
    viewPlay: store.viewPlay,
  
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_position: (payload) => {
      dispatch(set_position(payload));
    },
    set_t: (payload) => {
      dispatch(set_t(payload));
    },
    selectedPlaylistID: (payload) => {
      dispatch(selectedPlaylistID(payload));
    },
    selectedRadio: (payload) => {
      dispatch(selectedRadio(payload));
    },
    show_playlist_data: (payload) => {
      dispatch(show_playlist_data(payload));
    },
    arange_tracks_up: (payload) => {
      dispatch(arange_tracks_up(payload));
    },
    set_selected_playlist: (payload) => {
      dispatch(set_selected_playlist(payload));
    },
    set_playlist: (payload) => {
      dispatch(set_playlist(payload));
    },
    set_playlist_api: (payload) => {
      dispatch(set_playlist_api(payload));
    },
    get_playlist: (token) => {
      dispatch(get_playlist(token));
    },
    get_tracks_from_playlist: (token, playlistID) => {
      dispatch(get_tracks_from_playlist(token, playlistID));
    },
    update_playlist: (token,playlistID,active,name,tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength) => {
      dispatch(update_playlist(token,playlistID,active,name,tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength));
    },
    delete_track: (token,playlistID,active,name,tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength) => {
      dispatch(delete_track(token,playlistID,active,name,tracks,viewPlayTracks,selectedToPlay,index,play_id,shuffle,shuffleListLength));
    },
    set_playlist_name: (playlistName) => {
      dispatch(set_playlist_name(playlistName));
    },
    set_playlist_name_dialog: (playlistName,previousName) => {
      dispatch(set_playlist_name_dialog(playlistName,previousName));
    },
    update_playlist_drag:(token,playlistID,active,name,tracks)=> {
      dispatch(update_playlist_drag(token,playlistID,active,name,tracks));
    },
    check:(payload)=> {
      dispatch(check(payload));
    },
    seek_to: (payload) => {
      dispatch(seek_to(payload));
    },
    set_shuffle_list: (payload) => {
      dispatch(set_shuffle_list(payload));
    },
    set_play_prev_next_track: (payload) => {
      dispatch(set_play_prev_next_track(payload));
    },
    set_play_progress: (payload) => {
      dispatch(set_play_progress(payload));
    },
    set_play_button:(payload) => {
      dispatch(set_play_button(payload));
    },
    set_track:(payload) => {
      dispatch(set_track(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlaylistCont);