import React, { Component } from "react";
import { connect } from "react-redux";
import Routes from "../../components/router/router";
import {
  onLogout
} from "../../actions/login/loginActions";
import {set_play_button} from "../../actions/react player/reactPlayerAction";
export class Routes_con extends Component {

  render() {
    return (
      <Routes {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    sideNav:store.sideNav,
    viewPlay:store.viewPlay,
    reactPlayer:store.reactPlayer,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    onLogout: (user_token, uuid) => {
      dispatch(onLogout(user_token, uuid));
    },
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes_con);