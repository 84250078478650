import React, { Component } from "react";
import {
  Icon,
  IconButton,
  Toolbar,
  AppBar,
  Drawer,
  Paper,
} from "@material-ui/core";
import "./style.css";
import { withStyles } from "@material-ui/core";
import SideNav from "../router/sideNav";
import {
  unset_loader
} from "../../actions/loader/loader_action";

const styles = (theme) => ({
  paper: {
    height: "100vh",
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width:250
    }
  },
});
//  appbar for small screen such as mobile
class Appbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuButton: false,
    };
  }

  render() {
    const { classes} = this.props;
    return (
      <>
        <AppBar className="appbar-container" position="static">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.setState({ menuButton: !this.state.menuButton });
              }}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Icon>menu</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
         {/* onclick side drawer component */}
        <Drawer
          className="drawer"
          anchor="left"
          open={this.state.menuButton}
          onClose={() => {
            this.setState({ menuButton: !this.state.menuButton });
          }}
        >
          <Paper
           className={classes.paper}
          >
            <SideNav 
              viewPlay={this.props.viewPlay} 
              prop={this.props} 
              {...this.props} 
              screenSaver={this.props.screenSaver} 
              position={this.props.position} 
            />
          </Paper>
        </Drawer>
      </>
    );
  }
}

export default withStyles(styles)(Appbar);
