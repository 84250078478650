import UNIVERSAL from "../../config/config";
import { SET_RESET_SELECT_BOX, SET_RTP_MOODS, SHOW_MOOD, CLEAR_SHOW_MOOD, SHOW_MOOD_FILTER, SHOW, SHOW_MOOD_ARTIST, SET_FILTER_CREATE_TRACKS_PLAYER, SET_FILTER_PLAYLIST_TRACKS_PLAYER, SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE, SET_NONE } from "../../constants/playlist/playlistConst";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { onLogoutFromDifferentDevice } from "../login/loginActions"
import { set_loader, unset_loader } from "../loader/loader_action";
export function get_moods(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_rtp_moods", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({

      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_rtp_moods(responseJson.result))

        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        dispatch(unset_loader())


      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_rtp_moods(moodsDetail) {
  return {
    type: SET_RTP_MOODS,
    payload: moodsDetail
  }
}

export function set_reset(payload) {
  return {
    type: SET_RESET_SELECT_BOX,
    payload: payload
  }
}

export function show_mood(moodsDetail, moodTracks) {
  return {
    type: SHOW_MOOD,
    payload: { moodsDetail, moodTracks }
  }
}

export function clear_show_mood(moodDetail) {
  return {
    type: CLEAR_SHOW_MOOD,
    payload: moodDetail
  }
}

export function set_show_mood(token, moodsDetail) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_track_moods_artist_decade", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        track_mood: JSON.stringify(moodsDetail)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_snack_bar(true, responseJson.message))
          if (moodsDetail.length < 1) {
            if (localStorage.getItem('playlist') === 'createplay') {
              dispatch(set_tracks(responseJson.result[0].data))
            }
          }
          dispatch(show_mood(moodsDetail, responseJson.result));
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(unset_loader())
          dispatch(show_mood(moodsDetail, responseJson.result));
            dispatch(set_tracks([]))
          dispatch(set_snack_bar(false, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function show_mood_artist(artist) {
  return {
    type: SHOW_MOOD_ARTIST,
    payload: artist
  }
}

export function set_track_shuffle(payload, index, newShuffle) {
  var track = payload.map((m, i) => ({ track: m }))
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE,
    payload: { track: track, index: index, newShuffle: newShuffle },
  }
}

export function set_tracks(payload) {
  var track = [];
  track = payload.map((m, i) => ({ track: m }));
  if (localStorage.getItem('playlist') === 'createplay') {
    return {
      type: SET_FILTER_CREATE_TRACKS_PLAYER,
      payload: { track: track, playIndex: '' },
    }
  } else {
    localStorage.setItem("playlist", 'createplay')
    return {
      type: SET_FILTER_CREATE_TRACKS_PLAYER,
      payload: { track: track, playIndex: 0 },
    }
  }
}

export function set_track(payload) {
  var track = payload.map((m, i) => ({ track: m }))
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_PLAYER,
    payload: track,
  }
}

export function on_unmount_set_none_for_mobile_screen(payload) {
  return {
    type: SET_NONE,
    payload: payload,
  }
}

export function set_show_mood_artist(token, moodsDetail) {
  return dispatch => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_mood_tracks_artist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        track_mood: JSON.stringify(moodsDetail)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(show_mood_artist(responseJson.result));
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(unset_loader());
          dispatch(show_mood_artist([]));
          // dispatch(set_show_mood(token, moodsDetail));
          dispatch(set_snack_bar(false, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function show_mood_filter(moodsDetail, moodTracks) {
  return {
    type: SHOW_MOOD_FILTER,
    payload: { moodsDetail, moodTracks }
  }
}
export function show(moodsDetail) {
  return {
    type: SHOW,
    payload: { moodsDetail }
  }
}
export function set_show_mood_filter(token, moodsDetail) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_track_moods_artist_decade", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        track_mood: JSON.stringify(moodsDetail)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(show_mood(moodsDetail, responseJson.result));
          dispatch(set_snack_bar(true, responseJson.message))
          dispatch(set_tracks(responseJson.result[0].data))
          dispatch(show_mood_filter(moodsDetail, responseJson.result));
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(unset_loader())
          set_snack_bar(false, responseJson.message);
          dispatch(set_tracks([]))
          dispatch(show_mood_filter([], []));


        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function create_playlist(name, playlist, token) {
  var playlistId = playlist.map((m, i) => m._id)
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/create_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        name: name,
        tracks: JSON.stringify(playlistId)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_tracks([]))
          dispatch(set_snack_bar(true, responseJson.message))
          dispatch(show_mood([], []));
          dispatch(set_reset(true));
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(set_snack_bar(false, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}