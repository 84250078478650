import {SET_POSITION} from "../../constants/sideNav/sideNavConst";
export function set_position(payload) {
  return {
    type: SET_POSITION,
    payload: payload,
  }
}






