import React, { Component } from "react";
import { connect } from "react-redux";
import ResetPassword from "../../components/login/resetPassword";
import {
  changePassword,
  verifyChangePassword
} from "../../actions/login/loginActions";

export class ResetPasswordCon extends Component {
  render() {
    return (
      <ResetPassword {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    changePassword: (password, resetcode) => {
      dispatch(changePassword(password, resetcode))
    },
    verifyChangePassword: (resetcode) => {
      dispatch(verifyChangePassword(resetcode))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCon);