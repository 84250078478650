import {
  SET_PLAY_BUTTON,
  SET_PLAY_DURATION,
  SET_PLAY_PROGRESS,
  SET_VOLUME,
  SET_PLAY_PREV_NEXT_TRACK,
  SET_REPEAT,
  SET_SHUFFLE,
  SET_SHUFFLE_LIST,
  SET_SHUFFLE_INCREMENTER,
  SET_ONE_TIME_PLAY,
  SET_CURRENT_TRACK_INDEX,
  SEEK_TO,
  SET_PLAY_PROGRESS_SECONDS,
  SET_PLAY_PAGE,
  SET_REDIRECT,
  SET_LAST_PLAY_ID,
  SET_SCREEN_SAVER_ON,
  SET_ADS,
  SET_MUSIC_INDEX,
  SHOW_ADS,
  SET_ADS_INDEX
} from "../../constants/react player/reactPlayerConst";
import {
  SET_PLAYLIST_TRACKS, SET_FILTER_PLAYLIST_TRACKS_PLAYER, SET_FILTER_PLAYLIST_TRACKS, SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE,
  SET_FILTER_CREATE_TRACKS_PLAYER, SET_FILTER_PLAYLIST_TRACKS_VIEW, RESET_PLAYER, SET_PLAYLIST_TRACKS_AFTER_EDIT, SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR, SET_DEFAULT_NUMBER
} from "../../constants/playlist/playlistConst";
const initial_state = {
  playlistData: [],
  playIndex: 0,
  playButton: false,
  nextButton: false,
  prevButton: false,
  sound: 0,
  duration: 0,
  playedSeconds: 0,
  repeat: false,
  volume: 0.1,
  shuffle: false,
  shuffleList: [],
  shuffleIncrementer: 0,
  oneTimePlay: false,
  currentPlayIndex: 0,
  seekTo: false,
  tracks: [],
  playedProgressSeconds: 0.00,
  currentPage: '',
  lastPage: '',
  lastPlayedId: '',
  redirect: false,
  screenSaverOn: false,
  ads: [],
  ads_track_limit: "",
  musicIndex: 0,
  show_ads: false,
  adsIndex: 0,
  editMode: false
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ONE_TIME_PLAY:
      return state = { ...state, oneTimePlay: action.payload };
    case SET_ADS_INDEX:
      return state = { ...state, adsIndex: action.payload };
    case SET_ADS:
      return state = { ...state, ads: action.payload.ads, ads_track_limit: action.payload.ads_track_limit };
    case SET_MUSIC_INDEX:
      return state = { ...state, musicIndex: action.payload };
    case SHOW_ADS:
      return state = { ...state, show_ads: action.payload };
    case SET_PLAY_PROGRESS_SECONDS:
      return state = { ...state, playedProgressSeconds: action.payload };
    case SEEK_TO:
      return state = { ...state, seekTo: action.payload };
    case SET_CURRENT_TRACK_INDEX:
      return state = { ...state, currentPlayIndex: action.payload };
    case SET_PLAY_PROGRESS:
      return state = { ...state, playedSeconds: action.payload };
    case SET_SHUFFLE_INCREMENTER:
      return state = { ...state, shuffleIncrementer: action.payload };
    case SET_PLAY_BUTTON:
      return state = { ...state, playButton: action.payload };
    case SET_PLAY_DURATION:
      return state = { ...state, duration: action.payload };
    case SET_VOLUME:
      return state = { ...state, volume: action.payload };
    case SET_REPEAT:
      return state = { ...state, repeat: action.payload };
    case SET_SHUFFLE:
      return state = { ...state, shuffle: action.payload };
    case SET_SHUFFLE_LIST:
      return state = { ...state, shuffleList: action.payload };
    case SET_PLAY_PREV_NEXT_TRACK:
      return state = { ...state, playIndex: action.payload };
    case SET_PLAYLIST_TRACKS:
      return (state = {
        ...state,
        tracks: [...state.tracks, ...action.payload],
      });
    case SET_FILTER_PLAYLIST_TRACKS:
      return (state = {
        ...state,
        tracks: action.payload,
      });
    case SET_FILTER_PLAYLIST_TRACKS_VIEW:
      if (state.shuffle === true) {
        var newShuffleArray = [], shuffleLen = action.payload.tracks.length - 1, maxShuffleNum = action.payload.tracks.length - 1, shuffle;
        while (newShuffleArray.length <= shuffleLen) {
          shuffle = Math.round(Math.random() * maxShuffleNum);
          if (newShuffleArray.indexOf(shuffle) === -1) {
            newShuffleArray.push(shuffle);
          }
        }
        if (action.payload.selectPlayLength === 1) {
          return (state = {
            ...state,
            tracks: action.payload.tracks,
            shuffleList: newShuffleArray,
            playIndex: newShuffleArray.indexOf(0)
          });
        } else if (action.payload.selectPlayLength > 1) {
          return (state = {
            ...state,
            tracks: [...action.payload.recentTrack, ...action.payload.tracks],
          });
        }
      } else {
        if (action.payload.selectPlayLength === 1) {
          return (state = {
            ...state,
            tracks: action.payload.tracks,
          });
        } else if (action.payload.selectPlayLength > 1) {
          return (state = {
            ...state,
            tracks: action.payload.tracks,
          });
        }
      }
      break
    case SET_FILTER_PLAYLIST_TRACKS_PLAYER:
      return (state = {
        ...state,
        tracks: action.payload,
      });
    case SET_FILTER_PLAYLIST_TRACKS_PLAYER_SHUFFLE:
      return (state = {
        ...state,
        tracks: action.payload.track,
        playIndex: action.payload.index,
        shuffleList: action.payload.newShuffle
      });
    case SET_FILTER_CREATE_TRACKS_PLAYER:
      if (state.shuffle === true) {
        let newShuffleArray = [], shuffleLen = action.payload.track.length - 1, maxShuffleNum = action.payload.track.length - 1, shuffle;
        while (newShuffleArray.length <= shuffleLen) {
          shuffle = Math.round(Math.random() * maxShuffleNum);
          if (newShuffleArray.indexOf(shuffle) === -1) {
            newShuffleArray.push(shuffle);
          }
        }
        return (state = {
          ...state,
          tracks: action.payload.track,
          shuffleList: newShuffleArray,
          playIndex: 0,
          shuffleIncrementer: 1,
          shuffle: action.payload.track.length === 0 ? false : true
        });
      } else {
        return (state = {
          ...state,
          tracks: action.payload.track,
          playIndex: 0,
          seekTo: true,
        });
      }
    case SET_PLAY_PAGE:
      return state = { ...state, currentPage: action.payload.current, lastPage: action.payload.last };
    case SET_REDIRECT:
      return state = { ...state, redirect: action.payload };
    case SET_LAST_PLAY_ID:
      return state = { ...state, lastPlayedId: action.payload }
    case SET_SCREEN_SAVER_ON:
      return state = { ...state, screenSaverOn: action.payload }
    case SET_DEFAULT_NUMBER:
      return (state = {
        ...state,
        playIndex: 0,
        playButton: false,
        editMode: true
      });
    case SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR:
      if (state.shuffle === true && action.payload.newShuffleArray.length === 0) {
        return (state = {
          ...state,
          tracks: action.payload.tracks,
          playIndex: state.shuffleList.indexOf(action.payload.index),
          playButton: true,
          editMode: false
        });
      } else if (state.shuffle === true && action.payload.newShuffleArray.length !== state.shuffleList.length) {
        return (state = {
          ...state,
          tracks: action.payload.tracks,
          playIndex: action.payload.index,
          playButton: true,
          editMode: false,
          shuffleList:action.payload.newShuffleArray
        });
      } else {
        return (state = {
          ...state,
          tracks: action.payload.tracks,
          playIndex: action.payload.index,
          playButton: true,
          editMode: false
        });
      }
    case RESET_PLAYER:
      return state = {
        ...state, playlistData: [],
        playIndex: 0,
        playButton: false,
        nextButton: false,
        prevButton: false,
        sound: 0,
        duration: 0,
        playedSeconds: 0,
        volume: 0.1,
        repeat: false,
        shuffle: false,
        shuffleList: [],
        shuffleIncrementer: 0,
        oneTimePlay: false,
        currentPlayIndex: 0,
        seekTo: false,
        tracks: [],
        playedProgressSeconds: 0.00,
        currentPage: '',
        lastPage: '',
        lastPlayedId: '',
        redirect: false,
        screenSaverOn: false,
        ads: [],
        ads_track_limit: "",
        musicIndex: 1,
        show_ads: false,
        adsIndex: 0
      };
    case SET_PLAYLIST_TRACKS_AFTER_EDIT:
      if (state.shuffle === true && action.payload.tracks.length < state.shuffleList.length) {
        let newShuffleArray = [], shuffleLen = action.payload.tracks.length - 1, maxShuffleNum = action.payload.tracks.length - 1, shuffle;
        while (newShuffleArray.length <= shuffleLen) {
          shuffle = Math.round(Math.random() * maxShuffleNum);
          if (newShuffleArray.indexOf(shuffle) === -1) {
            newShuffleArray.push(shuffle);
          }
        }
        return (state = {
          ...state,
          tracks: action.payload.tracks,
          shuffleList: newShuffleArray,
          playIndex: state.currentPlayIndex < action.payload.tracks.length ? newShuffleArray.indexOf(state.currentPlayIndex) : newShuffleArray.indexOf(0)
        });
      } else {
        return (state = {
          ...state,
          tracks: action.payload.tracks,
          playIndex: action.payload.playIndex < action.payload.tracks.length ? action.payload.playIndex : action.payload.tracks.length === 0 ? 0 : action.payload.tracks.length - 1
        });
      }
    default:
      return state;
  }
}
