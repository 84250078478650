import UNIVERSAL from "../../config/config";
import {SET_PLAYLIST_TRACKS_AFTER_EDIT,EDIT_SET_PLAYLIST, SET_PLAYLIST, SET_SELECTED_TO_PLAY_PLAYLIST, SET_PLAYLIST_TRACKS, SET_FILTER_PLAYLIST_TRACKS,SET_FILTER_PLAYLIST_TRACKS_VIEW ,SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR,SET_DEFAULT_NUMBER} from "../../constants/playlist/playlistConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { onLogoutFromDifferentDevice } from "../login/loginActions"
import { set_snack_bar } from '../snackbar/snackbar_action'


export function add_playlist(name, track, token) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/create_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        name: 'best',
        tracks: JSON.stringify(track),
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function remove_playlist(name, token) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/delete_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_name: JSON.stringify(['best', 'bests']),
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_playlist(payload) {
  return {
    type: SET_PLAYLIST,
    payload: payload,
  }
}



export function set_selected_to_play_playlist(payload) {
  return {
    type: SET_SELECTED_TO_PLAY_PLAYLIST,
    payload: payload,
  }
}

export function get_playlist(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify(),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_playlist(responseJson.result))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(set_playlist([]))
          dispatch(set_snack_bar(responseJson.status, 'No Playlist Found'))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_tracks_from_playlist_arr(token, playlistID,current_playling_id,current_playlist_id,shuffle,shuffleListLength) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks_arr_after_edit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: JSON.stringify(playlistID),
        current_playling_id:current_playling_id,
        current_playlist_id:current_playlist_id,
        shuffle:shuffle,
        shuffle_list_length:shuffleListLength
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_playlist_tracks_after_edit_arr(responseJson.result,responseJson.index,responseJson.newShuffleArray))
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(false, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function get_tracks_from_playlist_arr_unselected(token, playlist,playIndex) {
  var playlistId = [];
  playlistId = playlist.map(data=>{return data.playlist_id})
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks_arr", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: JSON.stringify(playlistId)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_playlist_tracks_after_edit(responseJson.result,playIndex))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_playlist_tracks_after_edit([],0))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function edit_set_playlist(tracks) {
  return {
    type: EDIT_SET_PLAYLIST,
    payload: tracks,
  }
}
export function edit_get_playlist(token,selectedToPlay,index,play_id,shuffle,shuffleListLength) {
  let playlistID = []
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify(),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          selectedToPlay.some((v,i) =>{
            playlistID = [...playlistID,v.playlist_id]
            responseJson.result.map((val,index)=>{
              if(val.playlist_id === v.playlist_id){
                selectedToPlay[i] = val
              }
              return null
            })
            return null
          }
          )
          dispatch(get_tracks_from_playlist_arr(token,playlistID,index,play_id,shuffle,shuffleListLength))
          dispatch(edit_set_playlist(selectedToPlay))
        }
        else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        // else {
        //   dispatch(set_playlist([]))
        //   dispatch(set_snack_bar(responseJson.status, 'No Playlist Found'))
        // }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_playlist_tracks(tracks) {
  return {
    type: SET_PLAYLIST_TRACKS,
    payload: tracks,
  }
}

export function set_playlist_tracks_after_edit(tracks,playIndex) {
  return {
    type: SET_PLAYLIST_TRACKS_AFTER_EDIT,
    payload: {tracks:tracks,playIndex:playIndex},
  }
}

export function set_playlist_tracks_after_edit_arr(tracks,index,newShuffleArray) {
  return {
    type: SET_PLAYLIST_TRACKS_AFTER_EDIT_ARR,
    payload: {tracks:tracks,index,newShuffleArray},
  }
}

export function set_filter_playlist_tracks(tracks) {
  return {
    type: SET_FILTER_PLAYLIST_TRACKS,
    payload: tracks,
  }
}

export function default_number() {
  return {
    type: SET_DEFAULT_NUMBER,
  }
}

export function set_filter_playlist_tracks_view(tracks,selectPlayLength,track) {
  return {
    type: SET_FILTER_PLAYLIST_TRACKS_VIEW,
    payload: {tracks:tracks,selectPlayLength:selectPlayLength,recentTrack:track},
  }
}

export function get_tracks_from_playlist(token, playlistID) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_playlist_tracks(responseJson.result))
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(set_snack_bar(false, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function get_tracks_from_playlist_shift(token, playlistID,selectPlayLength,track) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_playlist_tracks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: playlistID
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_filter_playlist_tracks_view(responseJson.result,selectPlayLength,track))
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        } else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(false, responseJson.message))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function delete_event(token, playlist_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/delete_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: JSON.stringify([playlist_id])
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(set_snack_bar(true, "Playlist deleted"))
          dispatch(get_playlist(token));

        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(false, "Unable To Delete Playlist"))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function delete_event_selected_to_play(token, playlist_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/delete_playlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token,
      },
      body: JSON.stringify({
        playlist_id: JSON.stringify([playlist_id])
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader())
          dispatch(get_playlist(token))
          dispatch(set_snack_bar(true, "Playlist deleted"))
        } else if (responseJson.message === "USER EMAIL NOT RECOGNIZED") {
          dispatch(onLogoutFromDifferentDevice())
        }
        else {
          dispatch(unset_loader())
          dispatch(set_snack_bar(false, "Unable To Delete Playlist"))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}