import React, { Component } from "react";
import { connect } from "react-redux";
import ViewPlayPlaylist from "../../components/playlist/view play/viewPlaylist"
import { set_position } from "../../actions/sideNav/sideNavAction"
import { edit_get_playlist,get_playlist, set_selected_to_play_playlist, set_filter_playlist_tracks,default_number } from "../../actions/playlist/viewMusciPlaylistAction"
import {show_ads,set_music_index, set_play_prev_next_track, set_play_button, set_play_progress, seek_to, set_shuffle_list, set_play_page, set_last_play_id, set_redirect } from "../../actions/react player/reactPlayerAction"
import { set_track } from "../../actions/playlist/createPlaylistAction";
export class ViewPlayPlaylistCont extends Component {
  render() {
    return (
      <ViewPlayPlaylist {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    viewPlay: store.viewPlay,
    reactPlayer: store.reactPlayer,
    createPlay: store.createPlay,
    edit: store.editPlay,
    screenSaver:store.screenSaver
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_position: (payload) => {
      dispatch(set_position(payload));
    },
    seek_to: (payload) => {
      dispatch(seek_to(payload));
    },
    set_shuffle_list: (payload) => {
      dispatch(set_shuffle_list(payload));
    },
    set_play_prev_next_track: (payload) => {
      dispatch(set_play_prev_next_track(payload));
    },
    set_play_progress: (payload) => {
      dispatch(set_play_progress(payload));
    },
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    },
    get_playlist: (token) => {
      dispatch(get_playlist(token));
    },
    set_selected_to_play_playlist: (payload) => {
      dispatch(set_selected_to_play_playlist(payload));
    },
    set_filter_playlist_tracks: (payload) => {
      dispatch(set_filter_playlist_tracks(payload));
    },
    set_track: (payload) => {
      dispatch(set_track(payload));
    }, set_play_page: (current, last) => {
      dispatch(set_play_page(current, last));
    }, set_last_play_id: (last) => {
      dispatch(set_last_play_id(last));
    }, set_redirect: (last) => {
      dispatch(set_redirect(last));
    }, set_music_index: (data) => {
      dispatch(set_music_index(data));
    },
    show_ads: (data) => {
      dispatch(show_ads(data));
    },
    default_number: () => {
      dispatch(default_number());
    },
    edit_get_playlist:(token,selectedToPlay,index,play_id,shuffle,shuffleListLength)=> {
      dispatch(edit_get_playlist(token,selectedToPlay,index,play_id,shuffle,shuffleListLength));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlayPlaylistCont);