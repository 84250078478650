import React, { Component } from "react";
import { connect } from "react-redux";
import CreatePlaylist from "../../components/playlist/create playlist/createPlaylist"
import { set_position } from "../../actions/sideNav/sideNavAction"
import { 
  show_mood, 
  get_moods, 
  set_show_mood,
  set_track_shuffle, 
  set_track 
} from "../../actions/playlist/createPlaylistAction";
import {show_ads,set_music_index, set_play_prev_next_track, set_play_button, set_play_progress, seek_to, set_shuffle_list,set_last_play_id,set_play_page,set_redirect} from "../../actions/react player/reactPlayerAction"
export class CreatePlaylistCont extends Component {
  render() {
    return (
      <CreatePlaylist {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
    createPlay: store.createPlay,
    reactPlayer: store.reactPlayer,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    set_position: (payload) => {
      dispatch(set_position(payload));
    },
    get_moods: (token) => {
      dispatch(get_moods(token));
    },
    set_show_mood: (token, moodsDetail) => {
      dispatch(set_show_mood(token, moodsDetail));
    },
    set_track: (payload) => {
      dispatch(set_track(payload));
    },
    set_track_shuffle: (payload, index, newShuffle) => {
      dispatch(set_track_shuffle(payload, index, newShuffle));
    },
    seek_to: (payload) => {
      dispatch(seek_to(payload));
    },
    set_shuffle_list: (payload) => {
      dispatch(set_shuffle_list(payload));
    },
    set_play_prev_next_track: (payload) => {
      dispatch(set_play_prev_next_track(payload));
    },
    set_play_progress: (payload) => {
      dispatch(set_play_progress(payload));
    },
    set_play_button: (payload) => {
      dispatch(set_play_button(payload));
    }, set_play_page: (current, last) => {
      dispatch(set_play_page(current, last));
    }, set_last_play_id: (last) => {
      dispatch(set_last_play_id(last));
    }, set_redirect: (last) => {
      dispatch(set_redirect(last));
    },
    set_music_index:(data) => {
      dispatch(set_music_index(data));
    },
    show_ads:(data) => {
      dispatch(show_ads(data));
    },
    show_mood:(moodsDetail, moodTracks) => {
      dispatch(show_mood(moodsDetail, moodTracks));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlaylistCont);