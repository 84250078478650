import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import beingGood from "../../../styles/images/album/bg-placeholder-logo.svg";
// import { SortableContainer, SortableElement } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
import {
  Grid,
  Checkbox,
  ListItem,
  List,
  ListItemAvatar,
  withStyles
} from "@material-ui/core";
import "./table.css";
import { ListItemText } from "@material-ui/core";

const styles = () => ({
  radio: {
    '&$checked': {
      color: 'rgb(244, 144, 69)'
    }
  },
  checked: {}
})
class Tables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: ["A", "B", "C", "D"],
      items: this.props.prop.edit.playlist
    };
  }

  radioButtonHandler = (sortIndex) => {
    var playlist = this.props.prop.edit.playlist
    var selected = playlist[sortIndex].check === undefined || this.props.prop.edit.playlist[sortIndex].check === false ? this.props.prop.edit.playlist[sortIndex] = { ...this.props.prop.edit.playlist[sortIndex], check: true } : this.props.prop.edit.playlist[sortIndex] = { ...this.props.prop.edit.playlist[sortIndex], check: false }
    playlist[sortIndex] = selected
    this.props.prop.set_playlist(playlist)
    this.props.prop.edit.playlist.map(v => v.check).includes(true) === true ? this.props.prop.check(true) : this.props.prop.check(false);
  }
  SortableList = (({ items }) => {
    return (
      <Table >
        <TableBody>
          <TableRow>
            <TableCell colSpan={6}></TableCell>
            <TableCell colSpan={2} align="center" className="mood-cell">
              MOOD(S)
            </TableCell>
            <TableCell colSpan={1} align="center" className="mood-cell">
              RTP
            </TableCell>
            <TableCell colSpan={1} align="center" className="mood-cell">
              DECADE
            </TableCell>
            <TableCell colSpan={1} align="center" className="mood-cell">
              DURATION
            </TableCell>
            <TableCell colSpan={1} align="center" className="mood-cell">
              SELECT
            </TableCell>
          </TableRow>
          {this.props.prop.edit.playlist.map((value, sortIndex) => {
            let showDuration = "0:00";
            let duration = parseInt(value.track.duration);
            let minutes = Math.floor(duration / 60);
            let seconds = duration - (minutes * 60);
            if (seconds.toString().length == 1) {
              seconds = "0" + seconds
            }
            showDuration = minutes + ":" + seconds;

            return (
              <TableRow key={sortIndex}>
                <TableCell className="table-cell-playlist" colSpan={2}>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <span style={{ position: 'relative', fontWeight: 700, fontSize: 15 }}>
                        {sortIndex + 1}</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span>
                      </span>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className="table-cell-playlist" colSpan={4}>
                  <List style={{ display: "inline-block" }}>
                    <ListItem style={{ padding: 0 }}>
                      <ListItemAvatar>
                        <img
                          alt="album"
                          src={value.track.album_art_image === null ? beingGood : value.track.album_art_image}
                          style={{ width: 50, height: 50 }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<b>{value.track.title.toUpperCase()}</b>}
                        secondary={
                          <span style={{ color: "black" }}>{value.track.artist.toUpperCase()}</span>
                        }
                      />
                    </ListItem>
                  </List>
                </TableCell>
                <TableCell
                  className="table-cell-playlist"
                  align="center"
                  colSpan={2}
                >
                  <span className="table-text">{value.rtp.Mood.toUpperCase()}</span>
                </TableCell>
                <TableCell
                  className="table-cell-playlist"
                  align="center"
                  colSpan={1}
                >
                  <span className="table-text">{value.rtp.R},{value.rtp.T},{value.rtp.P}</span>
                </TableCell>
                <TableCell
                  className="table-cell-playlist"
                  align="center"
                  colSpan={1}
                >
                  <span className="table-text">{value.track.year === 0 ? "NA" : value.track.year}</span>
                </TableCell>
                <TableCell
                  className="table-cell-playlist"
                  align="center"
                  colSpan={1}
                >
                  <span className="table-text">{showDuration}</span>
                </TableCell>
                <TableCell
                  className="table-cell-playlist"
                  align="center"
                  colSpan={1}
                >
                  <Checkbox
                    checked={this.props.prop.edit.playlist[sortIndex].check ? true : false}
                    onChange={() => {
                      this.radioButtonHandler(sortIndex)
                    }}
                    classes={{ root: this.props.classes.radio, checked: this.props.classes.checked }}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    );
  });

  render() {
    return (
      <Paper>
        <TableContainer style={{ paddingBottom: 50 }}>
          <this.SortableList />
        </TableContainer>
      </Paper>
    )
  }
}
export default withStyles(styles)(Tables);